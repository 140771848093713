import { Box } from "@atoms/Flex";
import { IText, Text } from "@atoms/Text";
import { Skeleton } from "@molecules/Skeleton";
import { memo } from "react";
import { useTheme } from "styled-components/native";

export interface WidgetProps {
    label: string;
    value: string | number;
    valueProps?: IText;
}

export const WidgetBox = memo(({ label, value, valueProps }: WidgetProps) => {
    const theme = useTheme();
    return (
        <Box
            style={{
                backgroundColor: theme.color.rgba(theme.color.white, 0.05),
                padding: theme.spacing.bigger,
                borderRadius: theme.radius.big,
                width: "100%",
                justifyContent: "space-between",
                height: 72,
            }}
        >
            <Text smaller color={theme.color.rgba(theme.color.white, 0.6)}>
                {label}
            </Text>
            <Skeleton width={100} height={theme.text.big} isLoading={!!!value}>
                <Text big mono semiBold {...valueProps}>
                    {value}
                </Text>
            </Skeleton>
        </Box>
    );
});
