import svgs from "./icons";
import React, { memo, useState } from "react";
import { Pressable, View, ViewStyle } from "react-native";
import SvgIcon from "react-native-svg-icon";
import styled, { useTheme } from "styled-components/native";
import { Circle } from "../Circle";
import { Box } from "@atoms/Flex";

export interface IconInterface {
    name: string;
    size?: number;
    align?: string;
    viewBox?: string;
    viewWidth?: number;
    viewHeight?: number;
    notification?: number;
    width?: number;
    height?: number;
    custom?: boolean;

    rotate?: number;

    // Colors
    color?: string;
    primary?: boolean;
    secondary?: boolean;
    red?: boolean;
    green?: boolean;
    white?: boolean;
    black?: boolean;
    gray?: boolean;

    // Sizes
    tiny?: boolean;
    smallest?: boolean;
    smaller?: boolean;
    small?: boolean;
    medium?: boolean;
    big?: boolean;
    bigger?: boolean;
    biggest?: boolean;
    huge?: boolean;
}

export const Icon = memo(
    ({
        align,
        notification,
        width,
        height,
        rotate,
        ...props
    }: IconInterface) => {
        const theme = useTheme();

        // Color
        let color = theme.color.white;

        if (props.primary) color = theme.color.primary;
        if (props.red) color = theme.color.red;
        if (props.white) color = theme.color.white;
        if (props.black) color = theme.color.black;
        if (props.color) color = props.color;

        // Sizing
        let size = theme.spacing.bigger;
        const viewHeight = props.viewHeight || 24;
        const viewWidth = props.viewWidth || 24;

        if (props.tiny) size = theme.spacing.tiny;
        if (props.smallest) size = theme.spacing.smallest;
        if (props.smaller) size = theme.spacing.smaller;
        if (props.small) size = theme.spacing.small;
        if (props.medium) size = theme.spacing.medium;
        if (props.bigger) size = theme.spacing.bigger;
        if (props.biggest) size = theme.spacing.biggest;
        if (props.huge) size = theme.spacing.huge;
        if (props.size) size = props.size;

        if (notification !== undefined && notification !== 0)
            return (
                <StyledNotification style={{ height: size }}>
                    <View style={{ height: size, width: size }}>
                        <SvgIcon
                            {...props}
                            viewBox={`0 0 ${viewHeight} ${viewWidth}`}
                            fill={color}
                            width={size}
                            height={size}
                            svgs={svgs}
                            focusable={false}
                            style={{ alignSelf: `${align || "auto"}` }}
                        />
                    </View>
                    <StyledNotificationCircle
                        style={{
                            left: size / 2,
                            bottom: size * 1.4,
                        }}
                    >
                        <Circle
                            value={` ${notification} `}
                            primary
                            small={props.small}
                            tiny={props.tiny}
                            noWidth
                        />
                    </StyledNotificationCircle>
                </StyledNotification>
            );

        return (
            <SvgIcon
                {...props}
                viewBox={`0 0 ${viewWidth} ${viewHeight} `}
                fill={color}
                width={width ?? size}
                height={height ?? size}
                svgs={svgs}
                focusable={false}
                style={{
                    alignSelf: `${align || "auto"}`,
                    transform: [{ rotate: `${rotate || 0}deg` }],
                }}
            />
        );
    }
);

const StyledNotification = styled(View)`
    position: relative;
`;

const StyledNotificationCircle = styled(View)`
    position: relative;
`;

export const AnimatedIcon = memo(
    ({
        onPress,
        icon,
        style,
        rotate,
        color,
        isActive,
        size = 14,
    }: {
        onPress: () => void;
        isActive?: boolean;
        icon: string;
        size?: number;
        rotate?: number;
        color?: string;
        style?: ViewStyle;
    }) => {
        const theme = useTheme();
        const [isHovered, setIsHovered] = useState(false);

        const iconColor = color || theme.color.white;

        return (
            <Pressable
                onPress={onPress}
                style={{
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    ...style,
                }}
                // @ts-ignore
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <Box justify="center">
                    <Icon
                        name={icon}
                        color={theme.color.rgba(
                            iconColor,
                            isHovered || isActive ? 1 : 0.4
                        )}
                        rotate={rotate}
                        size={size}
                    />
                </Box>
            </Pressable>
        );
    }
);
