import { BlurView } from "@atoms/BlurView";
import { Box, Flex } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { useNotificationContext } from "@contexts/notification";
import { useWeb3Context } from "@contexts/web3";
import { Collapsible } from "@molecules/Animation";
import { Button, IButton } from "@molecules/Button";
import { CTA_HEIGHT } from "@screens/components/Header";
import { formatPrice } from "@utils";
import { memo, useState } from "react";
import { Pressable } from "react-native";
import { useTheme } from "styled-components/native";
import { useAccount } from "wagmi";

export const GetStarted = memo(() => {
    const theme = useTheme();
    const { userFE, refetchUser, showGetStarted, toggleGetStarted } =
        useAppContext();
    const { address } = useAccount();
    const { faucetContract, provider, currentChain } = useWeb3Context();
    const { notify } = useNotificationContext();
    const { isCTAActive } = useAppContext();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<{
        code: string;
        message: string;
    } | null>(null);

    const getFreeTokens = async () => {
        try {
            setIsLoading(true);

            // 1. Send 0.01 ETH
            await fetch(`/api/user/sendTokens`, {
                method: "POST",
                body: new URLSearchParams({
                    address: userFE?.address as string,
                }),
            }).then((res) => res.json());
            notify({
                title: "Sent 0.05 ETH",
                description: "You should receive your tokens soon",
            });

            // 2. Send 10000 USDC
            const result = await faucetContract.nextRequestTime(
                address as string
            );
            const nextRequestTime = Number(result);

            // Check if user has requested tokens in the last 24 hours
            if (
                nextRequestTime < Math.trunc(new Date().getTime() / 1000) ||
                nextRequestTime === 0
            ) {
                const tx = await faucetContract.requestTokens();
                // @ts-ignore
                await provider.waitForTransaction(tx.hash, 1);
                notify({
                    title: `Sent ${formatPrice(10000, {
                        currency: false,
                        decimals: 0,
                    })} USDC`,
                    description: "You should receive your tokens soon.",
                });
            }

            await refetchUser();
            setIsLoading(false);
        } catch (err) {
            console.warn(err);
            await refetchUser();
            // const error = parseWeb3Error(err as any);
            // setError(error);
            setIsLoading(false);
        }
    };

    const onboardingItems: IAccordion[] = [
        {
            id: "connect-wallet",
            active: true,
            title: "Connect Wallet",
            content: "Connect your wallet to start trading.",
            buttons: [
                {
                    text: "Disconnect",
                    white: false,
                    stroke: true,
                    noBorder: true,
                    onPress: () => {},
                },
            ],
        },
        {
            id: "funds",
            active: false,
            title: "Add funds",
            content: "Get free testnet tokens to begin trading!",
            buttons: [
                {
                    text: "Get Free Tokens",
                    primary: true,
                    white: false,
                    loading: isLoading,
                    onPress: getFreeTokens,
                },
            ],
        },
        // {
        //     id: "start-trading",
        //     active: false,
        //     title: "Start Trading",
        //     content:
        //         "Customize your trading experience by adjusting the UI to your preference",
        //     buttons: [
        //         {
        //             text: "Start Trading",
        //             primary: true,
        //             white: false,
        //             onPress: () => {},
        //         },
        //     ],
        // },
    ];

    if (!showGetStarted || !address || currentChain.type === "mainnet")
        return null;

    return (
        <Box
            style={{
                position: "fixed",
                bottom: theme.spacing.small + (isCTAActive ? CTA_HEIGHT : 0),
                right: theme.spacing.smaller,
                zIndex: 10,
                width: 300,
            }}
        >
            <BlurView
                style={{
                    borderWidth: 2,
                    borderColor: theme.color.primary,
                    borderRadius: theme.radius.big,
                }}
            >
                <Box>
                    <Box>
                        <Flex align="center" justify="space-between">
                            <Box
                                style={{
                                    padding: theme.spacing.bigger,
                                }}
                            >
                                <Text semiBold big>
                                    Start Trading
                                </Text>
                            </Box>

                            <Flex align="center">
                                {/* <Text
                                    smaller
                                    color={theme.color.rgba(
                                        theme.color.white,
                                        0.6
                                    )}
                                >
                                    1 out of {onboardingItems.length} steps
                                </Text> */}
                                <Pressable onPress={toggleGetStarted}>
                                    <Box
                                        style={{
                                            padding: theme.spacing.bigger,
                                        }}
                                    >
                                        <Icon
                                            name="close"
                                            size={12}
                                            color={theme.color.rgba(
                                                theme.color.white,
                                                0.6
                                            )}
                                        />
                                    </Box>
                                </Pressable>
                            </Flex>
                        </Flex>
                        <Box
                            style={{
                                paddingHorizontal: theme.spacing.bigger,
                                paddingBottom: theme.spacing.bigger,
                            }}
                            gap={theme.spacing.bigger}
                        >
                            <Text
                                small
                                lineHeight={theme.text.big}
                                color={theme.color.rgba(theme.color.white, 0.6)}
                            >
                                {onboardingItems[1].content}
                            </Text>
                            {error?.code ? (
                                <Text
                                    small
                                    red
                                    semiBold
                                    lineHeight={theme.text.big}
                                >
                                    {error?.message}
                                </Text>
                            ) : null}

                            <Flex gap={theme.spacing.smaller}>
                                <Button
                                    primary
                                    loading={isLoading}
                                    // stroke
                                    onPress={getFreeTokens}
                                    text={onboardingItems[1].buttons[0].text}
                                    textProps={{
                                        size: theme.text.small,
                                    }}
                                    style={{
                                        flex: 1,
                                    }}
                                />
                            </Flex>
                        </Box>
                        {/* <Accordion items={onboardingItems} current={1} /> */}
                    </Box>
                </Box>
            </BlurView>
        </Box>
    );
});

interface IAccordion {
    active: boolean;
    content: string;
    id: string;
    title: string;
    buttons: IButton[];
}

const Accordion = memo(
    ({ items, current }: { items: IAccordion[]; current: number }) => {
        const theme = useTheme();

        const [currentIndex, setCurrentIndex] = useState(current);

        return (
            <Box
                style={{
                    backgroundColor: theme.color.rgba(theme.color.white, 0.1),
                }}
            >
                {items.map((item, index) => {
                    const isLast = index === items.length - 1;
                    return (
                        <Pressable
                            onPress={() => {
                                setCurrentIndex(currentIndex > -1 ? index : -1);
                            }}
                            key={item.id}
                        >
                            <Collapsible
                                isOpen={currentIndex === index}
                                style={{
                                    borderRadius: 0,
                                    borderBottomWidth: 1,
                                    borderBottomColor: theme.color.rgba(
                                        theme.color.white,
                                        isLast ? 0 : 0.2
                                    ),
                                }}
                                renderHeader={
                                    <Flex
                                        gap={theme.spacing.medium}
                                        align="center"
                                        style={{
                                            padding: theme.spacing.bigger,
                                        }}
                                    >
                                        <Box
                                            style={{
                                                width: 24,
                                                height: 24,
                                                backgroundColor: item.active
                                                    ? theme.color.rgba(
                                                          theme.color.primary,
                                                          1
                                                      )
                                                    : theme.color.rgba(
                                                          theme.color.white,
                                                          0.1
                                                      ),
                                                borderRadius: 24,
                                            }}
                                            align="center"
                                            justify="center"
                                        >
                                            <Icon name="checkmark" size={12} />
                                        </Box>
                                        <Text
                                            small
                                            semiBold
                                            primary={item.active}
                                        >
                                            {item.title}
                                        </Text>
                                    </Flex>
                                }
                            >
                                <Box
                                    style={{
                                        paddingHorizontal: theme.spacing.bigger,
                                        paddingBottom: theme.spacing.bigger,
                                    }}
                                    gap={theme.spacing.bigger}
                                >
                                    <Text
                                        small
                                        lineHeight={theme.text.big}
                                        color={theme.color.rgba(
                                            theme.color.white,
                                            0.6
                                        )}
                                    >
                                        {item.content}
                                    </Text>
                                    <Flex gap={theme.spacing.smaller}>
                                        {item?.buttons?.map((button, index) => {
                                            return (
                                                <Button
                                                    key={index}
                                                    smallest
                                                    white
                                                    // stroke
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                    {...button}
                                                />
                                            );
                                        })}
                                    </Flex>
                                </Box>
                            </Collapsible>
                        </Pressable>
                    );
                })}
            </Box>
        );
    }
);
