import { useStoredState } from "@hooks/useStoredState";
import { Pair } from "gql/graphql";
import { useCallback, useMemo } from "react";

const DEFAULT_FAVORITES = [
    { from: "CL", to: "USD" },
    {
        from: "BTC",
        to: "USD",
    },
    {
        from: "NG",
        to: "USD",
    },
    {
        from: "HSI",
        to: "HKD",
    },
    {
        from: "ZS",
        to: "USD",
    },
];

export function useFavorites(pairs: Map<string, Pair>) {
    const defaultFavorites = useMemo(() => {
        const newFavorites: string[] = [];
        DEFAULT_FAVORITES.map((item) => {
            const pair = pairs.get(`${item.from}`);
            if (pair) newFavorites.push(pair.id);
        });
        return newFavorites;
    }, [pairs]);

    const [favorites, setFavorites] = useStoredState<string[]>(
        defaultFavorites,
        "FAVORITES",
        defaultFavorites
    );

    const toggleFavorite = useCallback(
        (pair: Pair) => {
            let newFavorites = [...favorites];
            if (newFavorites.includes(pair.id))
                newFavorites = newFavorites.filter(
                    (favorite) => favorite !== pair.id
                );
            else newFavorites = [...newFavorites, pair.id];

            setFavorites(newFavorites);
        },
        [favorites, setFavorites]
    );

    return {
        favorites,
        toggleFavorite,
    };
}
