import { Box, Flex } from "@atoms/Flex";
import { useMemo } from "react";
import { Doughnut as DoughnutJS } from "react-chartjs-2";

import { useTheme } from "styled-components/native";

const InfinityLoader = dynamic(
    () => import("@atoms/LoadingIndicator").then((mod) => mod.InfinityLoader),
    {
        ssr: true,
    }
);

import {
    ArcElement,
    CategoryScale,
    ChartData,
    Chart as ChartJS,
    ChartOptions,
    LinearScale,
} from "chart.js";
import { useChart } from "./useChart";
import dynamic from "next/dynamic";

ChartJS.register(CategoryScale, ArcElement, LinearScale);

type Props = {
    isLoading?: boolean;
    labels: ChartData<"doughnut">["labels"];
    datasets: ChartData<"doughnut">["datasets"];
    renderEmpty?: JSX.Element | JSX.Element[];
    options?: ChartOptions<"doughnut">;
};

export const Doughnut = ({
    datasets,
    isLoading,
    renderEmpty,
    labels,
    options,
}: Props) => {
    const { chartRef } = useChart();
    const theme = useTheme();

    const chartOptions: ChartOptions<"doughnut"> = useMemo(
        () => ({
            cutout: "80%",
            spacing: 4,
            datasets: {
                doughnut: {
                    borderRadius: theme.radius.big,
                },
            },
            maintainAspectRatio: false,
            interaction: {
                mode: "index",
                intersect: false,
            },
            layout: {
                padding: {
                    bottom: 0,
                    left: 0,
                    right: 0,
                },
            },
            plugins: {
                legend: {
                    display: false,
                    position: "left",
                    align: "center",
                    labels: {
                        padding: 20,
                        boxHeight: 20,
                        pointStyle: "circle",
                        usePointStyle: true,
                    },
                },
                tooltip: {
                    backgroundColor: theme.color.background,
                },
            },
            scales: {
                yAxes: {
                    display: false,
                },
                xAxes: {
                    display: false,
                },
            },
            ...options,
        }),
        [theme.color.background, theme.radius.big, options]
    );

    return (
        <Flex flex={1}>
            {isLoading ? (
                <Box
                    align="center"
                    justify="center"
                    style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        bottom: 50,
                        top: 0,
                    }}
                >
                    <InfinityLoader />
                </Box>
            ) : datasets.length === 0 ? (
                <Box
                    align="center"
                    justify="center"
                    style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        bottom: 50,
                        top: 0,
                    }}
                >
                    {renderEmpty}
                </Box>
            ) : null}

            <DoughnutJS
                ref={chartRef}
                data={{
                    labels,
                    datasets,
                }}
                options={chartOptions}
            />
        </Flex>
    );
};
