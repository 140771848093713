import { IS_PRODUCTION, IS_STAGING } from "@constants";
import { useNotificationContext } from "@contexts/notification";
import { Button } from "@molecules/Button";
import { useCallback, useEffect, useRef, useState } from "react";

export const NewVersionButton = () => {
    const { notify } = useNotificationContext();

    const [version, setVersion] = useState<string | null>(null);
    const [hasNewVersion, setHasNewVersion] = useState(false);
    const abortController = useRef(new AbortController());

    const getNewVersion = useCallback(async () => {
        try {
            const { deployments } = await fetch("/api/updates", {
                method: "GET",
                signal: abortController.current.signal,
            }).then((res) => res.json());

            const lastDeployment = deployments.find(
                (deployment: any) =>
                    deployment.meta.githubCommitRef ===
                    (IS_STAGING ? "staging" : "main")
            );

            if (
                lastDeployment.state === "READY" &&
                lastDeployment.readySubstate ===
                    (IS_PRODUCTION ? "PROMOTED" : "STAGED")
            ) {
                if (
                    !hasNewVersion &&
                    lastDeployment.uid !== version &&
                    version != null
                ) {
                    setHasNewVersion(true);
                    notify({
                        title: "New version is available",
                        description: "Click here to refresh the page",
                        autoClose: false,
                        onPress: () => {
                            window.location.reload();
                        },
                    });
                    return;
                }

                setVersion(lastDeployment.uid);
            }
        } catch (error) {
            setHasNewVersion(false);
            // @ts-ignore
            console.error(error?.message);
        }
    }, [hasNewVersion, notify, version]);

    const onNewVersion = () => {
        window.location.reload();
    };

    useEffect(() => {
        if (IS_PRODUCTION || IS_STAGING) {
            const interval = setInterval(() => {
                getNewVersion();
            }, 1000 * 60);

            getNewVersion();
            return () => {
                clearInterval(interval);
                abortController.current.abort();
                abortController.current = new AbortController();
            };
        }
    }, [getNewVersion]);

    if (hasNewVersion)
        return <Button primary text="New version" onPress={onNewVersion} />;
    return null;
};
