import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { usePriceContext } from "@contexts/price";
import { useQuery } from "@hooks/useApollo";
import { Select } from "@molecules/Select";
import { Skeleton } from "@molecules/Skeleton";
import { createDataset } from "@organisms/Chart";
import { Doughnut } from "@organisms/Chart/Doughnut";
import { GET_USER_TRADES } from "@screens/Portfolio/components/Overview";
import { GET_USER_GROUP } from "@screens/Portfolio/components/TradeDistribution/UserGroupStatistics";
import { GET_USER } from "@screens/Portfolio/context";
import {
    AssetClass,
    getTradePNL,
    getUserCollateralMetadata,
} from "@screens/Trade/utils";
import { formatLargeNumber, formatPrice } from "@utils";
import { Trade, User } from "gql/graphql";
import { useMemo, useState } from "react";
import { FlatList, ListRenderItem } from "react-native";
import { Link } from "solito/link";
import { useTheme } from "styled-components/native";
import { formatUnits } from "viem";
import { useBlockNumber } from "wagmi";
import { WidgetBox, WidgetProps } from "./Widget";

export const UserGraph = ({
    address,
    isOpen,
}: {
    address: `0x${string}`;
    isOpen: boolean;
}) => {
    const theme = useTheme();
    const { prices } = usePriceContext();

    const { data: blockNumber } = useBlockNumber({
        watch: true,
    });

    const { data: userData } = useQuery(GET_USER, {
        variables: {
            id: address?.toLowerCase(),
        },
        skip: !address && !isOpen,
        pollInterval: 5000,
    });

    const { data: tradesData } = useQuery(GET_USER_TRADES, {
        variables: {
            trader: address?.toLowerCase(),
        },
        pollInterval: 5000,
        skip: !address && !isOpen,
    });

    const options = [
        {
            text: "All",
            value: "all",
        },
        {
            text: "Open",
            value: "open",
        },
        {
            text: "Closed",
            value: "closed",
        },
    ];

    const [selected, setSelected] = useState(options[0]);

    const { data } = useQuery(GET_USER_GROUP, {
        variables: {
            user: address?.toLowerCase(),
        },
        fetchPolicy: "cache-and-network",
        skip: !address && !isOpen,
    });

    const metadata = useMemo(
        () =>
            getUserCollateralMetadata(
                tradesData?.trades,
                data?.userGroupStats,
                selected.value
            ),
        [data?.userGroupStats, selected.value, tradesData?.trades]
    );

    const user: User = useMemo(() => userData?.user, [userData?.user]);

    const pnl = useMemo(() => {
        if (selected.value === "closed")
            return user?.totalPnL ? Number(formatUnits(user?.totalPnL, 6)) : 0;
        return tradesData?.trades?.reduce(
            (acc: number, trade: Trade) => {
                const marketPrice = prices?.get(trade.pair.from);
                const data = getTradePNL(trade, marketPrice, blockNumber);

                return acc + data.netPNL;
            },
            selected.value === "all"
                ? user?.totalPnL
                    ? Number(formatUnits(user?.totalPnL, 6))
                    : 0
                : 0
        );
    }, [blockNumber, prices, selected, tradesData?.trades, user?.totalPnL]);

    const gain = useMemo(() => {
        if (selected.value === "closed")
            return user?.totalProfitTrades
                ? Number(user?.totalProfitTrades)
                : 0;
        return tradesData?.trades?.reduce(
            (acc: number, trade: Trade) => {
                const marketPrice = prices?.get(trade.pair.from);
                const data = getTradePNL(trade, marketPrice, blockNumber);

                if (data.netPNL > 0) return acc + 1;
                return acc;
            },
            selected.value === "all"
                ? user?.totalProfitTrades
                    ? Number(user?.totalProfitTrades)
                    : 0
                : 0
        );
    }, [
        blockNumber,
        prices,
        selected.value,
        tradesData?.trades,
        user?.totalProfitTrades,
    ]);

    const volume = useMemo(() => {
        return user?.totalVolume
            ? Number(formatUnits(user?.totalVolume, 6))
            : 0;
    }, [user?.totalVolume]);

    const loss = useMemo(() => {
        if (selected.value === "closed")
            return user?.totalLossTrades ? Number(user?.totalLossTrades) : 0;
        return tradesData?.trades?.reduce(
            (acc: number, trade: Trade) => {
                const marketPrice = prices?.get(trade.pair.from);
                const data = getTradePNL(trade, marketPrice, blockNumber);

                if (data.netPNL < 0) return Number(acc) + 1;
                return acc;
            },
            selected.value === "all"
                ? Number(user?.totalLossTrades)
                    ? Number(user?.totalLossTrades)
                    : 0
                : 0
        );
    }, [
        blockNumber,
        prices,
        selected.value,
        tradesData?.trades,
        user?.totalLossTrades,
    ]);

    const cumulativeCollateral = useMemo(
        () =>
            metadata.reduce((acc: number, metadata: any) => {
                return acc + metadata.total;
            }, 0),
        [metadata]
    );

    const datasets = useMemo(() => {
        return [
            createDataset(
                "Collateral",
                metadata?.map((i) => i.total),
                theme.color.background,
                {
                    // @ts-ignore
                    type: "doughnut",
                    // @ts-ignore
                    backgroundColor: metadata?.map((i) => i.color),
                    hoverBackgroundColor: metadata?.map((i) => i.color),
                    borderWidth: 0,
                    borderRadius: theme.radius.small,
                }
            ),
        ];
    }, [metadata, theme.color.background, theme.radius.small]);

    const widgets: WidgetProps[] = [
        {
            label: "Total Trades",
            value: gain + loss,
        },
        {
            label: "Total Volume",
            value: volume ? formatLargeNumber(volume) : 0,
        },
        {
            label: "Winning Trades",
            value: gain,
            valueProps: {
                color: gain ? theme.color.green : theme.color.white,
            },
        },
        {
            label: "Losing Trades",
            value: loss,
            valueProps: {
                color: loss ? theme.color.red : theme.color.white,
            },
        },
    ];

    const renderWidgets: ListRenderItem<WidgetProps> = ({ item, index }) => {
        return (
            <Box
                style={[
                    {
                        flex: 1,
                    },
                    index % 2 === 0
                        ? {
                              paddingRight: theme.spacing.tiny,
                          }
                        : {
                              paddingLeft: theme.spacing.tiny,
                          },
                ]}
            >
                <WidgetBox
                    label={item.label}
                    value={item.value}
                    valueProps={item?.valueProps}
                />
            </Box>
        );
    };

    return (
        <Box gap={theme.spacing.bigger}>
            <Flex
                justify="space-between"
                align="center"
                style={{
                    borderColor: theme.color.rgba(theme.color.white, 0.1),
                    zIndex: 10,
                }}
            >
                <Text semiBold>Your balance</Text>

                <Select
                    options={options}
                    selected={selected}
                    onChange={setSelected}
                />
            </Flex>

            {/* <Box flex={1} gap={theme.spacing.biggest}>
                    {metadata.map((item, index) => {
                        return (
                            <Box
                                key={`asset-class-${item.id}`}
                                gap={theme.spacing.tiny}
                            >
                                <Text
                                    smaller
                                    color={rgba(theme.color.white, 0.6)}
                                >
                                    {item.label === AssetClass.Commodity
                                        ? "Commodities"
                                        : item.label}
                                </Text>
                                <Text
                                    biggest
                                    semiBold
                                    color={rgba(
                                        item.total === 0
                                            ? theme.color.white
                                            : item.color,
                                        item.total ? item.total : 0.4
                                    )}
                                >
                                    {item.total
                                        ? formatLargeNumber(item.total)
                                        : "None"}
                                </Text>
                            </Box>
                        );
                    })}
                </Box> */}
            <Box gap={theme.spacing.biggest}>
                <Box
                    justify="center"
                    align="center"
                    style={{
                        height: 160,
                        width: "100%",
                    }}
                >
                    <Box
                        style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 200,
                            height: 50,
                            zIndex: 1,
                            // pointerEvents: "none",
                            transform: [{ translate: "-50%, -50%" }],
                        }}
                    >
                        {metadata.length ? (
                            <Box gap={theme.spacing.small} align="center">
                                <Text
                                    tiny
                                    uppercase
                                    color={theme.color.rgba(
                                        theme.color.white,
                                        0.6
                                    )}
                                >
                                    Total Collateral
                                </Text>
                                <Skeleton
                                    isLoading={!!!cumulativeCollateral}
                                    width={100}
                                    height={16}
                                >
                                    <Text semiBold mono>
                                        {formatPrice(
                                            Number(cumulativeCollateral),
                                            {
                                                currency: true,
                                                decimals: 2,
                                            }
                                        )}
                                    </Text>
                                </Skeleton>

                                <Skeleton
                                    isLoading={!!!pnl}
                                    width={60}
                                    height={16}
                                >
                                    <Text
                                        mono
                                        semiBold
                                        red={pnl < 0}
                                        green={pnl > 0}
                                    >
                                        {formatPrice(pnl, {
                                            currency: true,
                                            decimals: 2,
                                        })}
                                    </Text>
                                </Skeleton>
                                <Text
                                    tiny
                                    uppercase
                                    color={theme.color.rgba(
                                        theme.color.white,
                                        0.6
                                    )}
                                >
                                    PNL
                                </Text>
                            </Box>
                        ) : (
                            <Box flex={1} align="center" justify="center">
                                <Text
                                    smallest
                                    color={theme.color.rgba(
                                        theme.color.white,
                                        0.6
                                    )}
                                >
                                    {`No ${
                                        selected.value === "all"
                                            ? ""
                                            : selected.text
                                    } trades`}
                                </Text>
                            </Box>
                        )}
                    </Box>

                    <Box
                        flex={1}
                        style={{
                            position: "relative",
                            zIndex: 10,
                        }}
                    >
                        <Doughnut
                            labels={metadata?.map((coin, index) =>
                                coin.label === AssetClass.Commodity
                                    ? "Commodities"
                                    : coin.label
                            )}
                            // @ts-ignore
                            datasets={datasets}
                            options={{
                                spacing: 4,
                                cutout: "85%",
                            }}
                        />
                    </Box>
                </Box>

                <Box justify="center" align="center">
                    <AnimatedLink text={"More Details"} href="/portfolio" />
                </Box>

                <FlatList
                    numColumns={2}
                    renderItem={renderWidgets}
                    data={widgets}
                    contentContainerStyle={{
                        rowGap: theme.spacing.smaller,
                    }}
                />
            </Box>
        </Box>
    );
};

export const AnimatedLink = ({
    text,
    href,
}: {
    text: string;
    href: string;
}) => {
    const theme = useTheme();

    const [isHovering, setIsHovering] = useState(false);

    return (
        <Link suppressHydrationWarning href={href} prefetch={false}>
            <Text
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                smallest
                style={{
                    textDecoration: "underline",
                    color: isHovering
                        ? theme.color.primary
                        : theme.color.rgba(theme.color.white, 0.6),
                    transition: "color 0.2s ease-in-out",
                    willChange: "color",
                }}
            >
                {text}
            </Text>
        </Link>
    );
};
