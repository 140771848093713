import { Platform } from "react-native";
import { Media } from "./SharedTheme/media";
import { Radius } from "./SharedTheme/radius";
import { Shadows } from "./SharedTheme/shadows";
import { Spacings } from "./SharedTheme/spacings";
import { Text } from "./SharedTheme/text";
import { Colors } from "./SharedTheme";
import { rgba } from "polished";
import { ThemeInterface } from "types/theme";

export * from "./Devices";
export * from "./SharedTheme";

export const IS_STAGING = process.env.NEXT_PUBLIC_IS_STAGING === "true";
export const IS_PRODUCTION = process.env.NEXT_PUBLIC_IS_PRODUCTION === "true";
export const isWeb = Platform.OS === "web";
export const isMobile = Platform.OS === "ios" || Platform.OS === "android";
export const isIOS = Platform.OS === "ios";
export const isAndroid = Platform.OS === "android";

export const SIDEBAR_WIDTH = 320;

const DefaultTheme = {
    radius: Radius,
    shadow: Shadows,
    spacing: Spacings,
    text: Text,
    media: Media,
};

export const LightTheme: ThemeInterface = {
    ...DefaultTheme,
    color: {
        ...Colors,
        black: "#ffffff",
        white: "#120021",
        rgba: (color: string, opacity: number) => rgba(color, opacity),
    },
};

export const DarkTheme: ThemeInterface = {
    ...DefaultTheme,
    color: {
        ...Colors,
        black: "#070606",
        rgba: (color: string, opacity: number) => rgba(color, opacity),
    },
};

export const PunkTheme: ThemeInterface = {
    ...DefaultTheme,
    color: {
        ...Colors,
        black: "#0E0324",
        rgba: (color: string, opacity: number) => rgba(color, opacity),
    },
};

export const Punk2Theme: ThemeInterface = {
    ...DefaultTheme,
    color: {
        ...Colors,
        black: "#031624",
        rgba: (color: string, opacity: number) => rgba(color, opacity),
    },
};

export const GrayTheme: ThemeInterface = {
    ...DefaultTheme,
    color: {
        ...Colors,
        black: "#141313",
        rgba: (color: string, opacity: number) => rgba(color, opacity),
    },
};
