import { useCallback, useEffect } from "react";

export function useChainalysis({
    address,
    redirect,
}: {
    address?: `0x${string}` | undefined;
    redirect: any;
}) {
    const assessRisk = useCallback(async () => {
        try {
            const data = await fetch(
                `/api/chainalysis/assess?address=${address}`
            ).then((response) => response.json());
            return data;
        } catch (err) {
            console.warn(err);
            return null;
        }
    }, [address]);

    const registerUser = useCallback(async () => {
        try {
            const data = await fetch(
                `/api/chainalysis/register?address=${address}`
            ).then((response) => response.json());
            return data;
        } catch (err) {
            console.warn(err);
            return null;
        }
    }, [address]);

    const restrictHighRishUser = useCallback((userData: any) => {
        if (
            userData &&
            userData.risk &&
            (userData.risk.toLowerCase() === "severe" ||
                userData.risk.toLowerCase() === "high")
        ) {
            redirect();
            return;
        }
    }, [redirect]);

    const init = useCallback(async () => {
        try {
            const data = await assessRisk();
            restrictHighRishUser(data);

            if (
                data &&
                data.message &&
                data.message.includes("Entity not found")
            ) {
                const registerResponse = await registerUser();

                if (registerResponse && registerResponse.address) {
                    const reAssessUser = await assessRisk();
                    restrictHighRishUser(reAssessUser);
                }
            }
        } catch (err) {
            console.warn(err);
        }
    }, [assessRisk, registerUser, restrictHighRishUser]);

    useEffect(() => {
        if (!address) return;
        init();
    }, [address, init]);

    return null;
}
