import { Box, Flex } from "@atoms/Flex";
import { LinearGradient } from "expo-linear-gradient";
import { memo } from "react";
import { useTheme } from "styled-components/native";

export const ProportionBar = memo(
    ({
        value,
        min,
        max,
        line,
        gradient,
    }: {
        value: number;
        min: number;
        max: number;
        line?: boolean;
        gradient: string[];
    }) => {
        const theme = useTheme();
        const width = value > max ? 100 : ((value - min) / (max - min)) * 100;
        const style = line
            ? {
                  backgroundColor: theme.color.white,
                  left: `${width}%`,
                  height: 12,
                  width: 6,
                  marginTop: -3,
                  borderRadius: theme.radius.small,
                  zIndex: 100,
              }
            : {
                  backgroundColor: theme.color.rgba(theme.color.white, 0.4),
                  height: "100%",
                  width: `${width}%`,
                  borderTopLeftRadius: 4,
                  borderBottomLeftRadius: 4,
                  borderRadius: theme.radius.small,
                  zIndex: 100,
              };

        return (
            <Flex
                red
                style={{
                    height: 5,
                    width: "100%",
                    borderRadius: theme.radius.small,
                    backgroundColor: theme.color.rgba(theme.color.white, 0.2),
                }}
            >
                <LinearGradient
                    colors={gradient}
                    start={{ x: 1, y: 0 }}
                    style={{
                        borderRadius: theme.radius.small,
                        width: "100%",
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                    }}
                />

                <Box style={style} />
            </Flex>
        );
    }
);
