import { useWeb3Context } from "@contexts/web3";
import { LpNft, LpShare, Pair, Vault } from "gql/graphql";

// @ts-ignore
import { OstiumVault__factory } from "@ostium/smart-contracts/dist/typechain/factories/src/OstiumVault__factory";

import { OstiumVault } from "@ostium/smart-contracts/dist/typechain/src/OstiumVault";

import { useAppContext } from "@contexts/app";
import { gql, useQuery } from "@hooks/useApollo";
import { ITab } from "@screens/Trade/components/Form/TabBar";
import { BigNumber } from "@utils";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { formatUnits } from "viem";
import { useAccount } from "wagmi";
import { DAY_IN_SECONDS } from "./utils";

export enum VaultTab {
    DEPOSIT = "DEPOSIT",
    WITHDRAW = "WITHDRAW",
    UNLOCK = "UNLOCK",
}

export const VaultContext = createContext(
    {} as {
        currentTab: ITab;
        vault?: Vault;
        vaultContract: OstiumVault;
        lockedDeposits: LpNft[];
        totalLocked: number | null;
        totalDeposited: number | null;
        collateralizationRatio: number;
        isLoadingVault: boolean;
        totalDepositedAssets: number;
        totalLockedAssets: number;

        epochNumber: number;
        epochLength: number;
        timelock: number;
        timelockEndDate: Date;
        startDate: Date;
        endDate: Date;
        withdrawEnabledEndDate: number;
        withdrawalEnabledLength: number;
        onChangeTab: (tab: ITab) => void;
    }
);

export function useSharePrice() {
    const [price, setPrice] = useState(Number(formatUnits(BigInt("0"), 18)));

    const { loading } = useQuery(GET_SHARE_PRICE, {
        pollInterval: 1000,
        onCompleted: (data) => {
            setPrice(Number(formatUnits(data?.vault?.sharePrice, 18)));
        },
    });

    return { price, loading };
}

const useVault = () => {
    const { currentChain, provider, signer, isOtherNetwork } = useWeb3Context();
    const { address } = useAccount();
    const { initial } = useAppContext();

    const [collateralizationRatio, setCollateralizationRatio] = useState(0);
    const [vault, setVault] = useState(initial.vault);

    const { data, loading: isLoadingVault } = useQuery(GET_DATA, {
        variables: {
            id: address?.toLowerCase(),
        },
        pollInterval: 5000,
        onCompleted(data) {
            setVault(data.vault);
        },
    });

    const lockedDeposits: LpNft[] = useMemo(() => {
        return data?.lpNFTs || [];
    }, [data]);

    const shares: LpShare = useMemo(() => {
        return data?.lpShares?.[0] || null;
    }, [data?.lpShares]);

    const [currentTab, setCurrentTab] = useState<ITab>({
        id: "deposit",
        text: "Deposit",
    });

    const epochNumber = useMemo(
        () => Number(vault?.currentEpoch) || 0,
        [vault?.currentEpoch]
    );
    const startDate = useMemo(
        () =>
            Number(vault?.currentEpochStart)
                ? new Date(vault?.currentEpochStart * 1000)
                : new Date(),
        [vault?.currentEpochStart]
    );
    const endDate = useMemo(
        () =>
            Number(vault?.currentEpochEnd)
                ? new Date(vault?.currentEpochEnd * 1000)
                : new Date(),
        [vault?.currentEpochEnd]
    );

    const epochLength = useMemo(() => {
        return (
            Number(vault?.currentEpochEnd) - Number(vault?.currentEpochStart)
        );
    }, [vault?.currentEpochEnd, vault?.currentEpochStart]);

    const withdrawalEnabledLength = epochLength / 3;
    const withdrawEnabledEndDate = useMemo(() => {
        return new Date().setTime(
            Number(vault?.currentEpochStart) + withdrawalEnabledLength
        );
    }, [vault?.currentEpochStart, withdrawalEnabledLength]);

    const timelock = useMemo(() => {
        if (collateralizationRatio >= 120) return 1;
        if (collateralizationRatio >= 110) return 2;
        return 3;
    }, [collateralizationRatio]);

    const timelockEndDate = useMemo(() => {
        const duration = timelock * epochLength;

        if (!Number(vault?.currentEpochStart))
            return new Date(new Date().getTime() + duration * 1000);

        return new Date((Number(vault?.currentEpochStart) + duration) * 1000);
    }, [epochLength, timelock, vault?.currentEpochStart]);

    const totalLocked = useMemo(
        () =>
            lockedDeposits
                ? Number(
                      formatUnits(
                          lockedDeposits
                              .reduce((acc, item) => {
                                  if (item.isUnlocked) return acc;
                                  return acc.add(item.shares);
                              }, BigNumber.from("0"))
                              .toBigInt(),
                          6
                      )
                  )
                : 0,
        [lockedDeposits]
    );

    const totalLockedAssets = useMemo(
        () =>
            lockedDeposits
                ? Number(
                      formatUnits(
                          lockedDeposits
                              .reduce((acc, item) => {
                                  if (item.isUnlocked) return acc;
                                  return acc.add(item.assetsDeposited);
                              }, BigNumber.from("0"))
                              .toBigInt(),
                          6
                      )
                  )
                : 0,
        [lockedDeposits]
    );

    const totalDepositedAssets = useMemo(
        () => (shares?.assets ? Number(formatUnits(shares?.assets, 6)) : 0),
        [shares?.assets]
    );

    const totalDeposited = useMemo(
        () => (shares?.shares ? Number(formatUnits(shares?.shares, 6)) : 0),
        [shares?.shares]
    );

    //@ts-ignore
    const vaultContract: OstiumVault = useMemo(() => {
        if (currentChain.contracts.vault)
            return OstiumVault__factory?.connect(
                currentChain.contracts.vault,
                address ? signer : provider
            );
        return null;
    }, [currentChain.contracts.vault, address, signer, provider]);

    const getCollateralizationRatio = useCallback(async () => {
        try {
            const result = await vaultContract?.collateralizationP();
            setCollateralizationRatio(Number(formatUnits(BigInt(result), 2)));
        } catch (err) {
            console.warn(err);
        }
    }, [vaultContract]);

    useEffect(() => {
        if (vaultContract && !isOtherNetwork) getCollateralizationRatio();
    }, [
        address,
        vaultContract,
        currentTab,
        isOtherNetwork,
        getCollateralizationRatio,
    ]);

    const onChangeTab = useCallback(
        (tab: ITab) => {
            setCurrentTab(tab);
        },
        [setCurrentTab]
    );

    return {
        currentTab,
        vaultContract,
        vault,
        totalLocked,
        totalDeposited,
        lockedDeposits,
        collateralizationRatio,
        timelock,
        timelockEndDate,
        epochNumber,
        startDate,
        endDate,
        isLoadingVault,
        totalDepositedAssets,
        totalLockedAssets,
        epochLength,
        withdrawEnabledEndDate,
        withdrawalEnabledLength,
        onChangeTab,
    };
};

export const VaultProvider = ({ children }: { children: JSX.Element }) => {
    const value = useVault();
    return (
        <VaultContext.Provider value={value}>{children}</VaultContext.Provider>
    );
};

export const useVaultContext = () => useContext(VaultContext);

// Old, to be removed
export type StakeType = {
    pair: Pair;
    amount: number;
    hide?: boolean;
};

const GET_SHARE_PRICE = gql`
    query getSharePrice($id: Bytes) @api(name: subgraph) {
        vault(id: "usdc-vault") {
            sharePrice
        }
    }
`;

const GET_DATA = gql`
    query getShares($id: Bytes) @api(name: subgraph) {
        vault(id: "usdc-vault") {
            id
            currentEpoch
            currentEpochStart
            currentEpochEnd
            assets
            shares
            totalLockedUsers

            sharePrice
            accPnlPerTokenUsed
            currentMaxSupply
            rewardsPerToken
            maxDiscountP
            maxDiscountThresholdP
        }
        lpShares(first: 1000, where: { user: $id }) {
            id
            shares
            assets
        }
        lpNFTs(
            first: 1000
            where: { user: $id }
            orderBy: atTime
            orderDirection: desc
        ) {
            id
            assetsDeposited
            assetsDiscount
            atTime
            isUnlocked
            lockDuration
            shares
        }
    }
`;
