// import { AlchemyProvider } from "@alchemy/aa-alchemy";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useAppContext } from "./app";
import { useWalletClient } from "wagmi";
import { createWalletClient, custom } from "viem";
// import {
//     SmartAccountSigner,
//     WalletClientSigner,
//     getDefaultEntryPointAddress,
// } from "@alchemy/aa-core";
// import {
//     LightSmartContractAccount,
//     getDefaultLightAccountFactoryAddress,
// } from "@alchemy/aa-accounts";

// import {
//     BiconomySmartAccountV2,
//     DEFAULT_ENTRYPOINT_ADDRESS,
// } from "@biconomy/account";
// import {
//     ECDSAOwnershipValidationModule,
//     DEFAULT_ECDSA_OWNERSHIP_MODULE,
// } from "@biconomy/modules";
// import { ChainId } from "@biconomy/core-types";
// import { IBundler, Bundler } from "@biconomy/bundler";
// import { IPaymaster, BiconomyPaymaster } from "@biconomy/paymaster";
import { useWeb3Context } from "./web3";

interface ISmartWallet {
    address?: string;
    connect: () => void;
    disconnect: () => void;
    smartAccount?: null;
    // smartAccount?: AlchemyProvider | null;
    // smartAccount: BiconomySmartAccountV2 | null;
    // bundler: IBundler;
    // paymaster: IPaymaster;
}
const SmartWalletContext = createContext({} as ISmartWallet);

const useSmartWallet = () => {
    const { settings, updateSetting } = useAppContext();
    const { data } = useWalletClient();

    const [address, setAddress] = useState<string>();
    const { signer } = useWeb3Context();
    // const [smartAccount, setSmartAccount] =
    //     useState<BiconomySmartAccountV2 | null>(null);
    const [smartAccount, setSmartAccount] = useState<null>(null);

    // const bundler: IBundler | null = useMemo(() => {
    //     if (data)
    //         return new Bundler({
    //             bundlerUrl: `https://bundler.biconomy.io/api/v2/${data.chain.id}/nJPK7B3ru.dd7f7861-190d-41bd-af80-6877f74b8f44`,
    //             chainId: data.chain.id,
    //             // entryPointAddress: DEFAULT_ENTRYPOINT_ADDRESS,
    //         });
    //     return null;
    // }, [data]);

    // const paymaster: IPaymaster | null = useMemo(() => {
    //     if (data)
    //         return new BiconomyPaymaster({
    //             paymasterUrl: `https://paymaster.biconomy.io/api/v1/${data.chain.id}/93E8jKG1Y.9ae7e395-ca19-4774-812c-2070c54ee743`,
    //         });
    //     return null;
    // }, [data]);

    // const connect = useCallback(async () => {
    //     if (!data || !bundler || !paymaster) return;
    //     const ownerShipModule = await ECDSAOwnershipValidationModule.create({
    //         // @ts-ignore
    //         signer,
    //         moduleAddress: DEFAULT_ECDSA_OWNERSHIP_MODULE,
    //     });

    //     let biconomySmartAccount = await BiconomySmartAccountV2.create({
    //         chainId: ChainId.ARBITRUM_SEPOLIA,
    //         bundler,
    //         paymaster,
    //         entryPointAddress: DEFAULT_ENTRYPOINT_ADDRESS,
    //         defaultValidationModule: ownerShipModule,
    //         activeValidationModule: ownerShipModule,
    //     });
    //     setSmartAccount(biconomySmartAccount);
    //     console.log({ biconomySmartAccount });
    //     const saAddress = await biconomySmartAccount.getAccountAddress();
    //     setAddress(saAddress);
    // }, [bundler, data, paymaster, signer]);

    const connect = useCallback(async () => {
        if (data) {
            const address = data.account.address;
            const client = createWalletClient({
                account: address,
                chain: data?.chain,
                transport: custom(data),
            });

            // const signer: SmartAccountSigner = new WalletClientSigner(
            //     // @ts-ignore
            //     client,
            //     "json-rpc"
            // );

            // const connectedProvider = new AlchemyProvider({
            //     chain: data.chain,
            //     apiKey: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY || "",
            // })
            //     .connect((provider) => {
            //         const result = new LightSmartContractAccount({
            //             chain: provider.chain,
            //             owner: signer,
            //             rpcClient: provider,
            //             entryPointAddress: getDefaultEntryPointAddress(
            //                 provider.chain
            //             ),
            //             factoryAddress: getDefaultLightAccountFactoryAddress(
            //                 provider.chain
            //             ),
            //         });
            //         console.warn(result);
            //         return result;
            //     })
            //     .withAlchemyGasManager({
            //         policyId: "8edb3e2b-3833-42d6-8574-1324d5d8514b",
            //     });

            // const smartWalletAddress = await connectedProvider.getAddress();
            // setAddress(smartWalletAddress);
            // setSmartAccount(connectedProvider);
            // updateSetting({
            //     isSmartAccount: true,
            // });
            // return connectedProvider;
        }
    }, [data]);

    const disconnect = useCallback(async () => {
        // smartAccount?.disconnect();
        setAddress(undefined);
        setSmartAccount(null);
        updateSetting({
            isSmartAccount: false,
        });

        // return disconnectedProvider;
    }, [updateSetting]);

    // useEffect(() => {
    //     // if (settings?.isSmartAccount && !smartAccount?.isConnected()) {
    //     //     connect();
    //     // }
    // }, [settings?.isSmartAccount, connect, smartAccount]);

    return {
        // bundler,
        // paymaster,
        address,
        smartAccount,
        connect,
        disconnect,
    };
};

export interface ISmartWalletProvider {
    children: JSX.Element | JSX.Element[];
}

const SmartWalletProvider = ({ children }: ISmartWalletProvider) => {
    const value = useSmartWallet();

    return (
        <SmartWalletContext.Provider value={value}>
            {children}
        </SmartWalletContext.Provider>
    );
};

const useSmartWalletContext = () => useContext(SmartWalletContext);

export { SmartWalletContext, useSmartWalletContext, SmartWalletProvider };
