import { Box, Flex } from "@atoms/Flex";
import { useTheme } from "styled-components/native";

import { useAppContext } from "@contexts/app";
import { Button } from "@molecules/Button";
import { useTutorialContext } from "@organisms/Tutorial/context";
import { Markets } from "./Markets";
import { StatusButton } from "./StatusButton";
import { useAccount } from "wagmi";
import { useWeb3Context } from "@contexts/web3";
import { GetStarted } from "@screens/Trade/components/GetStarted";
import { FaucetButton } from "../Header/Buttons/FaucetButton";

export const TradeFooter = () => {
    const theme = useTheme();
    const { address } = useAccount();
    const { settings, toggleGetStarted, userFE, showGetStarted } =
        useAppContext();

    const { currentChain } = useWeb3Context();
    const { toggleActive } = useTutorialContext();

    const openTutorial = () => {
        toggleActive();
    };

    return (
        <>
            <Box
                style={{
                    position: "relative",
                    borderTopWidth: settings.theme.boxed ? 0 : 1,
                    borderColor: theme.color.rgba(theme.color.white, 0.1),
                    paddingLeft: settings.theme.boxed
                        ? 0
                        : theme.spacing.smaller,
                    paddingRight: settings.theme.boxed
                        ? 0
                        : theme.spacing.smaller,
                }}
            >
                <Flex justify="space-between" align="center">
                    <Flex align="center" gap={theme.spacing.smaller} flex={1}>
                        <StatusButton />
                        <Markets />
                    </Flex>
                    <Flex gap={theme.spacing.smaller}>
                        {/* <Button
                            primary
                            stroke
                            noBorder
                            tiny
                            onPress={openTutorial}
                            text={"Tutorial"}
                        />*/}

                        {!address || currentChain?.type === "mainnet" ? null : (
                            <>
                                <FaucetButton isSmall={false} />
                            </>
                        )}
                        {userFE?.ftueDone ||
                        !address ||
                        currentChain?.type === "mainnet" ? null : (
                            <>
                                <Button
                                    primary
                                    stroke
                                    noBorder
                                    tiny
                                    onPress={toggleGetStarted}
                                    text={
                                        showGetStarted ? "Close" : "Get Started"
                                    }
                                />
                                <GetStarted />
                            </>
                        )}
                    </Flex>
                </Flex>
            </Box>
        </>
    );
};
