import { memo } from "react";
import { View, ViewProps, ViewStyle } from "react-native";
import styled, { css } from "styled-components/native";
import { ThemeInterface } from "types/theme";

// @ts-ignore
interface IBox extends ViewProps {
    direction?: ViewStyle["flexDirection"];
    align?: ViewStyle["alignItems"];
    justify?: ViewStyle["justifyContent"];
    flex?: ViewStyle["flex"];
    style?: Omit<ViewStyle, "position"> & {
        position?: "relative" | "absolute" | "fixed";
    };
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    color?: string;
    red?: boolean;
    black?: boolean;
    gap?: number;
}

export const Box = memo(styled(View)<IBox>`
    ${({ gap }: { gap: number }) => {
        if (gap)
            return css`
                gap: ${gap}px;
            `;
    }}
    ${({ flex }: { flex: number }) => {
        if (flex)
            return css`
                flex: ${flex};
            `;
    }}
    ${({ direction }: { direction: string }) => {
        if (direction)
            return css`
                flex-direction: ${direction};
            `;
    }}
  ${({ justify }: { justify: string }) => {
        if (justify)
            return css`
                justify-content: ${justify};
            `;
    }}

  ${({ align }: { align: string }) => {
        if (align)
            return css`
                align-items: ${align};
            `;
    }}

  ${({
        theme,
        color,
        black,
        red,
    }: {
        theme: ThemeInterface;
        color: string;
        black: boolean;
        red: boolean;
    }) => {
        if (color) {
            return css`
                background: ${color};
            `;
        }

        if (black) {
            return css`
                background: ${theme.color.black};
            `;
        }
        if (red) {
            return css`
                background: ${theme.color.red};
            `;
        }
    }}
`);

export const Flex = memo(styled(Box)`
    flex-direction: ${({ direction }: { direction: string }) =>
        direction ?? "row"};
`);
