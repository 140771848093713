import { Box, Flex } from "@atoms/Flex";
import { IText, Text } from "@atoms/Text";
import { useState } from "react";
import { ViewStyle } from "react-native";
import { useTheme } from "styled-components/native";

export const Pill = ({
    text,
    white,
    green,
    red,
    primary,
    yellow,
    style,
    stroke,
    big,
    isActive = true,
    uppercase = true,
    textProps,
    extra,
    borderRadius,
    extraProps,
}: {
    text?: string;
    isActive?: boolean;
    white?: boolean;
    green?: boolean;
    uppercase?: boolean;
    red?: boolean;
    yellow?: boolean;
    primary?: boolean;
    big?: boolean;
    stroke?: boolean;
    extra?: string;
    borderRadius?: number;
    style?: ViewStyle;
    textProps?: IText;
    extraProps?: IText;
}) => {
    const theme = useTheme();
    const color = theme.color.rgba(
        primary
            ? theme.color.primary
            : red
            ? theme.color.red
            : green
            ? theme.color.green
            : yellow
            ? theme.color.yellow
            : theme.color.white,
        primary || green || red || yellow ? 1 : 0.3
    );

    const [isHovering, setIsHovering] = useState(false);

    return (
        <Flex
            align="center"
            style={{
                backgroundColor: theme.color.rgba(theme.color.white, 0.2),
                borderRadius: borderRadius
                    ? borderRadius
                    : big
                    ? theme.radius.medium
                    : theme.radius.small,
            }}
        >
            <Box
                style={{
                    borderRadius: borderRadius
                        ? borderRadius
                        : big
                        ? theme.radius.medium
                        : theme.radius.small,
                    padding: big ? theme.spacing.smaller : theme.spacing.tiny,
                    paddingTop: big ? 6 : 2,
                    paddingBottom: big ? 6 : 2,

                    backgroundColor: stroke ? undefined : color,
                    borderTopRightRadius: extra
                        ? 0
                        : borderRadius
                        ? borderRadius
                        : big
                        ? theme.radius.medium
                        : theme.radius.small,
                    borderBottomRightRadius: extra
                        ? 0
                        : borderRadius
                        ? borderRadius
                        : big
                        ? theme.radius.medium
                        : theme.radius.small,
                    borderWidth: stroke ? 1 : 0,
                    borderColor: stroke ? color : undefined,
                    opacity: isHovering && !isActive ? 0.8 : isActive ? 1 : 0.4,
                    ...style,
                }}
                align="center"
                justify="center"
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                <Text
                    selectable={false}
                    tiny={!big}
                    smallest={big}
                    semiBold
                    black={yellow}
                    uppercase={uppercase}
                    style={{ paddingLeft: big ? 3 : 2 }}
                    {...textProps}
                >
                    {text}
                </Text>
            </Box>

            {extra ? (
                <Box
                    style={{
                        paddingHorizontal: big
                            ? theme.spacing.medium
                            : theme.spacing.smallest,
                    }}
                >
                    <Text smallest {...extraProps}>
                        {extra}
                    </Text>
                </Box>
            ) : null}
        </Flex>
    );
};
