import { useQuery, gql } from "@hooks/useApollo";
import { Pair, PriceHistorical } from "gql/graphql";
import { useEffect, useMemo, useRef, useState } from "react";

export interface IPerformance {
    price24hAgo: number;
    highest: number;
    lowest: number;
    historical: PriceHistorical[];
}

export function usePerformance(
    pairs: Pair[] | null,
    chainId: number,
    initialPerformances?: IPerformance[]
) {
    const initial = useMemo(() => {
        const newPerformance = new Map<
            string,
            {
                price24hAgo: number;
                highest: number;
                lowest: number;
                historical: PriceHistorical[];
            }
        >();

        initialPerformances?.map((result: any) => {
            newPerformance.set(result.from, {
                price24hAgo: result.price24hAgo,
                highest: result.highest,
                lowest: result.lowest,
                historical: result.historical,
            });
        });

        return newPerformance;
    }, [initialPerformances]);

    const performances = useRef<Map<string, IPerformance> | null>(initial);

    // TODO: Switch to this implementation
    // const [isLoading, setIsLoading] = useState(true);
    // useEffect(() => {
    //     const init = async () => {
    //         try {
    //             setIsLoading(true);
    //             if (!pairs?.length) return null;

    //             const params = new URLSearchParams({
    //                 pairs: pairs?.map((pair) => pair.feed).join(","),
    //             });

    //             const data = await fetch(
    //                 `/api/historical/all?${params.toString()}`
    //             ).then((response) => response.json());

    //             const newPerformances = new Map<
    //                 string,
    //                 {
    //                     price24hAgo: number;
    //                     highest: number;
    //                     lowest: number;
    //                     historical: PriceHistorical[];
    //                 }
    //             >();

    //             data.map((result: any) => {
    //                 newPerformances.set(result.from, {
    //                     price24hAgo: result.price24hAgo,
    //                     highest: result.highest,
    //                     lowest: result.lowest,
    //                     historical: result.historical,
    //                 });
    //             });
    //             console.warn(data);
    //             performances.current = newPerformances;
    //             setIsLoading(false);
    //         } catch (err) {
    //             console.warn(err);
    //             setIsLoading(false);
    //         }
    //     };

    //     const interval = setInterval(() => {
    //         init();
    //     }, 10000);

    //     return () => {
    //         clearInterval(interval);
    //         performances.current = null;
    //     };
    // }, [pairs]);

    const { loading } = useQuery(GET_PERFORMANCES, {
        variables: {
            chainId,
        },
        skip: pairs == null,
        pollInterval: 10000,
        onCompleted: (data) => {
            const newPerformances = new Map<
                string,
                {
                    price24hAgo: number;
                    highest: number;
                    lowest: number;
                    historical: PriceHistorical[];
                }
            >();

            data.performances.map((result: any) => {
                newPerformances.set(result.from, {
                    price24hAgo: result.price24hAgo,
                    highest: result.highest,
                    lowest: result.lowest,
                    historical: result.historical,
                });
            });

            performances.current = newPerformances;
        },
    });

    return { performances: performances.current, loading };
}

const GET_PERFORMANCES = gql`
    query performances($chainId: Float!) {
        performances(chainId: $chainId) {
            id
            from
            to
            price24hAgo
            highest
            lowest
            historical {
                time
                open
                high
                low
                close
            }
        }
    }
`;
