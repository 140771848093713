import { Box } from "@atoms/Flex";
import { memo } from "react";
import { Pressable } from "react-native";
import Animated, {
    useAnimatedStyle,
    withTiming,
} from "react-native-reanimated";
import { useTheme } from "styled-components/native";

const CIRCLE_SIZE = 16;
const WRAPPER_SIZE = 40;

type ToggleProps = {
    text?: string;
    isActive?: boolean;
    onPress: (isActive?: boolean) => void;
};

export const Toggle = memo(({ isActive, onPress }: ToggleProps) => {
    const theme = useTheme();
    const circleAnimation = useAnimatedStyle(() => {
        return {
            height: CIRCLE_SIZE,
            width: CIRCLE_SIZE,
            transform: [
                {
                    translateX: withTiming(
                        isActive ? WRAPPER_SIZE - CIRCLE_SIZE - 3.5 : 0,
                        {
                            duration: 150,
                        }
                    ),
                },
            ],
        };
    }, [isActive]);

    const backgroundAnimation = useAnimatedStyle(() => {
        return {
            height: CIRCLE_SIZE + 4,
            width: WRAPPER_SIZE,
            borderRadius: CIRCLE_SIZE,
            padding: 2,
            backgroundColor: withTiming(
                isActive ? theme.color.primary : theme.color.black,
                {
                    duration: 200,
                }
            ),
        };
    }, [isActive, theme.color.black, theme.color.primary]);

    return (
        <Pressable
            style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                position: "relative",
            }}
            onPress={() => onPress(!isActive)}
        >
            <Animated.View style={backgroundAnimation}>
                <Animated.View style={circleAnimation}>
                    <Box
                        flex={1}
                        style={{
                            borderRadius: 100,
                            backgroundColor: theme.color.white,
                        }}
                    />
                </Animated.View>
            </Animated.View>
        </Pressable>
    );
});
