import { useMemo, useRef, useState } from "react";

export function useChart() {
    const [tooltipData, setTooltipData] = useState<{} | null>({});
    const formatLabel = useMemo(() => (item: any) => item, []);
    const chartRef = useRef();

    const formatLabelColor = useMemo(
        () => (item: { dataset: { color: any } }) => {
            return item.dataset.color;
        },
        []
    );

    const formatTitle = useMemo(
        () => (item: { label: any }[]) => {
            return `${item[0].label}`;
        },
        []
    );

    const formatTicks = useMemo(
        () => (value: any) => {
            return new Intl.NumberFormat("en-US", {
                notation: "compact",
                currency: "USD",
                maximumFractionDigits: 1,
            })
                .format(Number(value))
                .toString();
        },
        []
    );

    const customTooltip = (tooltipModel: {
        tooltip: {
            opacity: number;
            x: any;
            y: any;
            title: any[];
            dataPoints: any;
        };
    }) => {
        const chart = chartRef.current;

        if (tooltipModel.tooltip.opacity == 0) {
            setTooltipData(null);
            console.log("Hide tooltip");
            return;
        }

        // @ts-ignore
        const canvas = chart?.canvas;
        if (canvas) {
            const position = canvas.getBoundingClientRect();

            const left = tooltipModel.tooltip.x;
            const top = tooltipModel.tooltip.y;
            setTooltipData({
                left,
                top,
                title: tooltipModel.tooltip.title[0],
                data: tooltipModel.tooltip.dataPoints,
            });
        }
    };

    return {
        chartRef,
        tooltipData,
        customTooltip,
        formatTicks,
        formatTitle,
        formatLabel,
        formatLabelColor,
    };
}
