import { Box } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { SIDEBAR_WIDTH } from "@constants";
import { useAppContext } from "@contexts/app";
import dynamic from "next/dynamic";

const ToastContainer = dynamic(
    () => import("react-toastify").then((mod) => mod.ToastContainer),
    {
        ssr: true,
    }
);

import { useTheme } from "styled-components/native";

export const Toasts = () => {
    const theme = useTheme();
    const { settings } = useAppContext();

    return (
        <ToastContainer
            closeButton={
                <Box
                    justify="center"
                    align="center"
                    style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        padding: theme.spacing.bigger + 2,
                    }}
                >
                    <Icon name="close" size={10} />
                </Box>
            }
            position={settings.notifications.position}
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            progressStyle={{
                height: 4,
                bottom: 0,
                backgroundColor: theme.color.primary,
            }}
            bodyStyle={{
                padding: 0,
                margin: 0,
            }}
            toastStyle={{
                top: 0,
                left: 0,
                margin: 0,
                padding: 0,
                background: "transparent",
                width: SIDEBAR_WIDTH,
                marginBottom: [
                    "bottom-left",
                    "bottom-right",
                    "bottom-center",
                ].includes(settings.notifications.position)
                    ? 0
                    : theme.spacing.big,
                marginTop: [
                    "bottom-left",
                    "bottom-right",
                    "bottom-center",
                ].includes(settings.notifications.position)
                    ? theme.spacing.big
                    : 0,
            }}
        />
    );
};
