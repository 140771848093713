import { useCallback } from 'react';
import { useAccount } from 'wagmi';
import { useAppContext } from '@contexts/app';
import { useNotificationContext } from '@contexts/notification';
import { useWeb3Context } from '@contexts/web3';

import { EVENT_NAMES } from 'constants/events';
import useAnalytics from './useAnalytics';

const EOA = "0xE2aB1440e4C724005cc312FBDADa9BaAC335ba61";
const EMPTY_ADDRESS = "0x0000000000000000000000000000000000000000";

export const useDelegation = () => {
    const { address } = useAccount();
    const { settings, updateSetting } = useAppContext();
    const { tradingContract } = useWeb3Context();
    const { notify } = useNotificationContext();
    const { trackEvent } = useAnalytics();

    const trackDelegationEvents = useCallback((eventName: string, data: any) => trackEvent(eventName, data),
        [trackEvent]
    );

    const setDelegationEnabled = useCallback(
        (enabled: boolean) => updateSetting({ ...settings, delegation: enabled }),
        [settings, updateSetting]
    );

    const handleDelegationError = useCallback(
        (message: string, title = "Delegation Error") => {
            notify({ title, type: "Error", description: message });
            return false;
        },
        [notify]
    );

    const checkDelegation = useCallback(async () => {
        if (!settings.delegation || !address || !tradingContract) return null;

        try {
            const delegation = await tradingContract.delegations(address);
            if (delegation !== EMPTY_ADDRESS && delegation === EOA) {
                setDelegationEnabled(true);
                return delegation;
            }
        } catch {
            notify({
                title: "No delegations",
                autoClose: 3000,
                type: "Info",
                description: "You have no delegations.",
            });
        }
        return null;
    }, [address, notify, setDelegationEnabled, settings.delegation, tradingContract]);

    const setDelegation = useCallback(async (otherData?: any) => {
        trackDelegationEvents(EVENT_NAMES.CHECK_DELEGATION, otherData);

        const currentDelegation = await checkDelegation();
        if (currentDelegation === EOA) {
            trackDelegationEvents(EVENT_NAMES.HAS_DELEGATION, otherData);
            // notify({
            //     title: "Delegated Access",
            //     type: "Info",
            //     description: "You have already delegated access.",
            // });
            setDelegationEnabled(true);
            return true;
        }

        notify({
            title: "Delegate Access",
            type: "Info",
            description: "You will do this once in Metamask to allow trades to be placed.",
        });

        try {
            await tradingContract?.setDelegate(EOA);
            trackDelegationEvents(EVENT_NAMES.SET_DELEGATION_SUCCESS, otherData);

            notify({
                title: "Delegated Access",
                type: "Success",
                description: "You have delegated access.",
            });
            setDelegationEnabled(true);
            return true;
        } catch (err) {
            console.error("Error setting delegate:", err);
            trackDelegationEvents(EVENT_NAMES.SET_DELEGATION_FAIL, otherData);
            setDelegationEnabled(false);
            return handleDelegationError("Failed to set delegation. Please try again.");
        }
    }, [trackDelegationEvents, checkDelegation, notify, tradingContract, setDelegationEnabled, handleDelegationError]);

    const removeDelegation = useCallback(async () => {
        try {
            const currentDelegation = await checkDelegation();

            if (currentDelegation === EOA || currentDelegation) {
                await tradingContract?.removeDelegate();
                notify({
                    title: "Delegation Removed",
                    type: "Info",
                    description: "You have no delegations.",
                });
                setDelegationEnabled(false);
                return true;
            }
        } catch (err) {
            console.error("Error removing delegate:", err);
            setDelegationEnabled(true);
            return handleDelegationError("Failed to remove delegation.");
        }

        notify({
            title: "No delegations",
            autoClose: 3000,
            type: "Info",
            description: "You have no delegations.",
        });
        setDelegationEnabled(false);
        return false;
    }, [notify, checkDelegation, tradingContract, setDelegationEnabled, handleDelegationError]);

    return {
        toggleEnabled: settings?.delegation,
        setDelegation,
        checkDelegation,
        removeDelegation,
        setDelegationEnabled,
    };
};
