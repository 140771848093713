import { useWeb3Context } from "@contexts/web3";
import USDCSC from "@ostium/smart-contracts/dist/artifacts/src/mocks/MockUSDC.sol/MockUSDC.json";
import { Contract } from "ethers";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { decodeFunctionData, formatUnits, parseUnits } from "viem";
import { useAccount, useBalance } from "wagmi";

export function useAllowance(storageAddress?: `0x${string}`) {
    const { signer, provider, currentChain } = useWeb3Context();
    const { address, chainId } = useAccount();
    const [allowance, setAllowance] = useState(0);

    // const {
    //     data: dataAllowance,
    //     isPending,
    //     error,
    //     isLoading,
    // } = useReadContract({
    //     ...wagmiConfig,
    //     functionName: "allowance",
    //     args: [address],
    // });

    const { refetch } = useBalance({
        address,
        chainId,
    });

    const tokenContract = useMemo(() => {
        // return getContract({
        //     address: currentChain?.contracts.token as `0x${string}`,
        //     abi: USDCSC.abi,
        //     client: {
        //         public: publicClient,
        //         wallet: walletClient,
        //     },
        // });

        return new Contract(
            currentChain?.contracts.token as string,
            USDCSC.abi,
            signer
        );
    }, [currentChain?.contracts.token, signer]);

    const getAllowance = useCallback(async () => {
        try {
            if (!tokenContract || !address) return;

            // const result = tokenContract.read.allowance([address]);
            // console.warn("@@@", result);
            const result = await tokenContract?.allowance(
                address,
                storageAddress
            );
            return Number(formatUnits(result, 6));
        } catch (err) {
            console.warn(err);
        }
    }, [address, storageAddress, tokenContract]);

    const updateAllowance = useCallback(async () => {
        try {
            const newAllowance = await getAllowance();
            if (newAllowance != null) {
                setAllowance(newAllowance);
            }
            return newAllowance;
        } catch (err) {
            console.warn(err);
        }
    }, [getAllowance]);

    const previousBalanceRef = useRef(0);

    useEffect(() => {
        const init = async () => {
            const { data } = await refetch();
            if (
                data?.value &&
                Number(formatUnits(data.value, data.decimals)) !=
                previousBalanceRef.current
            ) {
                await updateAllowance();
                previousBalanceRef.current = Number(
                    formatUnits(data.value, data.decimals)
                );
            }
        };

        if (address) {
            init();
        }
    }, [address, updateAllowance, refetch]);

    const raiseAllowance = useCallback(
        async (amount: number) => {
            try {
                const newAllowance = await getAllowance();

                if (newAllowance != null) {
                    const tx = await tokenContract.approve(
                        storageAddress,
                        parseUnits(amount?.toString(), 6)
                    );

                    await provider.waitForTransaction(tx.hash);

                    const { args } = decodeFunctionData({
                        abi: USDCSC.abi,
                        data: tx.data,
                    });

                    const userSetAllowance = Number(
                        formatUnits(args?.[1] as bigint, 6)
                    );

                    if (userSetAllowance) {
                        setAllowance(userSetAllowance);
                        return { userSetAllowance, hash: tx.hash };
                    }
                }
                return {
                    userSetAllowance: null,
                    hash: "",
                };
            } catch (err) {
                console.warn(err);
                return {
                    userSetAllowance: null,
                    hash: "",
                };
            }
        },
        [getAllowance, tokenContract, storageAddress, provider]
    );

    const checkAllowance = useCallback(
        async (collateral: number) => {
            try {
                const newAllowance = await getAllowance();

                if (newAllowance != null) {
                    if (collateral <= newAllowance) {
                        return {
                            userSetAllowance: null,
                            hash: "",
                        };
                    }

                    const tx = await tokenContract.approve(
                        storageAddress,
                        parseUnits(collateral?.toString(), 6)
                    );

                    await provider.waitForTransaction(tx.hash);

                    const { args } = decodeFunctionData({
                        abi: USDCSC.abi,
                        data: tx.data,
                    });

                    const userSetAllowance = Number(
                        formatUnits(args?.[1] as bigint, 6)
                    );

                    if (userSetAllowance) {
                        setAllowance(userSetAllowance);
                        return { userSetAllowance, hash: tx.hash };
                    }
                }
                return {
                    userSetAllowance: null,
                    hash: "",
                };
            } catch (err) {
                console.warn(err);
                return {
                    userSetAllowance: null,
                    hash: "",
                };
            }
        },
        [getAllowance, tokenContract, storageAddress, provider]
    );

    return {
        allowance,
        updateAllowance,
        getAllowance,
        checkAllowance,
        raiseAllowance
    };
}
