"use client";
import SkeletonLoader from "expo-skeleton-loader";

import { memo } from "react";
import { ViewStyle } from "react-native";
import { useTheme } from "styled-components/native";

export const Skeleton = memo(
    ({
        width = 80,
        height = 12,
        style,
        isLoading,
        image,
        children,
        secondary,
        borderRadius,
    }: {
        width?: number;
        height?: number;
        isLoading: boolean;
        style?: ViewStyle;
        image?: boolean;
        children?: React.ReactNode;
        borderRadius?: number;
        secondary?: {
            width: number;
            height?: number;
        };
    }) => {
        const theme = useTheme();
        if (!isLoading) return children;

        return (
            <SkeletonLoader
                boneColor={theme.color.rgba(theme.color.white, 0.1)}
                highlightColor={theme.color.rgba(theme.color.white, 1)}
                duration={200}
            >
                <SkeletonLoader.Container
                    style={[
                        {
                            flexDirection: "row",
                            alignItems: "center",
                            gap: theme.spacing.smallest,
                        },
                        style,
                    ]}
                >
                    {image ? (
                        <SkeletonLoader.Item
                            style={{
                                width: 28,
                                height: 28,
                                borderRadius: 100,
                            }}
                        />
                    ) : (
                        <></>
                    )}
                    <SkeletonLoader.Container
                        style={[
                            {
                                flexDirection: "column",
                                gap: theme.spacing.smallest,
                            },
                            style,
                        ]}
                    >
                        <SkeletonLoader.Item
                            style={{
                                width,
                                height,
                                borderRadius:
                                    borderRadius ?? theme.radius.small,
                            }}
                        />
                        {secondary ? (
                            <SkeletonLoader.Item
                                style={{
                                    width: secondary?.width || width,
                                    height: secondary?.height || height,
                                    borderRadius:
                                        borderRadius ?? theme.radius.small,
                                }}
                            />
                        ) : (
                            <></>
                        )}
                    </SkeletonLoader.Container>
                </SkeletonLoader.Container>
            </SkeletonLoader>
        );
    }
);
