export const Spacings = {
    tiny: 4,
    smallest: 6,
    smaller: 8,
    small: 10,
    medium: 12,
    big: 14,
    bigger: 16,
    biggest: 20,
    huge: 32,
    massive: 40,
};
