import { Avatar } from "@atoms/Avatar";
import { BlurView } from "@atoms/BlurView";
import { Box, Flex } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { Image } from "@atoms/Image";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { AVAILABLE_CHAINS, useWeb3Context } from "@contexts/web3";
import { useDimensions } from "@hooks/useDimensions";
import { useMedia } from "@hooks/useMedia";
import { useOutsideAlerter } from "@hooks/useOutsidePress";
import { Button } from "@molecules/Button";
import { Navigation, NavigationMobile } from "@organisms/Header/Navigation";
import { NetworkSwitcher } from "@organisms/Header/NetworkSwitcher";
import Modal from "@organisms/Modal";
import { VaultProvider } from "@screens/Vault/context";
import {
    CTA_HEIGHT,
    HEADER_HEIGHT,
    MobileMenuButton,
    NAVIGATION_LINKS,
} from "@screens/components/Header";
import { Balance } from "@screens/components/Header/User";
import { EditUsername } from "@screens/components/Header/User/EditUsername";
import { formatAddress } from "@utils";
import { useRouting } from "expo-next-react-navigation";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { Pressable } from "react-native";
import { Link } from "solito/link";
import { useTheme } from "styled-components/native";
import { useAccount, useDisconnect } from "wagmi";

export const ConferenceLayout = ({
    children,
}: {
    children: JSX.Element | JSX.Element[];
}) => {
    const media = useMedia();
    const theme = useTheme();

    return (
        <>
            <Header />

            <Box
                style={
                    media.isMobile
                        ? {
                              width: "100%",
                              paddingTop: HEADER_HEIGHT,
                          }
                        : {
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              minHeight: "100vh",
                          }
                }
            >
                <VaultProvider>
                    <Box
                        style={{
                            width: "100%",
                            paddingBottom: media.isMobile
                                ? theme.spacing.huge * 2.5
                                : undefined,
                        }}
                    >
                        {children}
                    </Box>
                </VaultProvider>
            </Box>
        </>
    );
};

export const SOCIAL_LINKS = [
    {
        text: "Docs",
        external: true,
        url: "https://ostium-labs.gitbook.io/ostium-docs/",
    },
    {
        text: "Twitter",
        icon: "twitter",
        external: true,
        url: "https://twitter.com/ostiumlabs",
    },
    {
        text: "Discord",
        icon: "discord",
        external: true,
        url: "https://discord.gg/44hxSkfy9a",
    },
    {
        text: "Blog",
        icon: "medium",
        external: true,
        url: "https://www.ostium.io/blog",
    },
];

export const Header = memo(({ simple }: { simple?: boolean }) => {
    const media = useMedia();
    const theme = useTheme();

    return (
        <Box
            style={{
                position: "fixed",
                left: 0,
                right: 0,
                zIndex: 1,
                top: 0,
            }}
            align="center"
        >
            <Box
                style={{
                    width: "100%",
                    zIndex: 10,
                }}
            >
                <BlurView
                    // intensity={100}
                    style={{
                        zIndex: 100,
                    }}
                    // black
                >
                    {/* <AppAlert /> */}

                    <Flex
                        align="center"
                        justify="space-between"
                        style={{
                            paddingHorizontal: media.isMobile
                                ? 0
                                : theme.spacing.big,
                            height: HEADER_HEIGHT,
                        }}
                    >
                        <Flex flex={1} style={{ height: "100%" }}>
                            <Link
                                href="/"
                                suppressHydrationWarning
                                prefetch={false}
                            >
                                <Box
                                    style={{
                                        position: "relative",
                                        height: "100%",
                                        paddingRight: theme.spacing.big,
                                        paddingLeft: media.isMobile
                                            ? theme.spacing.bigger
                                            : 0,
                                    }}
                                    justify="center"
                                >
                                    {media.isMobile ? (
                                        <Image
                                            alt="Ostium Logo"
                                            source="/assets/logo-square.svg"
                                            width={24}
                                            height={24}
                                            priority
                                            selectable={false}
                                        />
                                    ) : (
                                        <Image
                                            alt="Ostium Logo"
                                            source={"/assets/logo.svg"}
                                            width={123}
                                            height={24}
                                            selectable={false}
                                            priority
                                        />
                                    )}
                                </Box>
                            </Link>
                            {media.isMobile ? null : (
                                <Navigation items={NAVIGATION_LINKS} />
                            )}
                        </Flex>

                        <Flex
                            style={{
                                gap: theme.spacing.smaller,
                            }}
                        >
                            <UserMenu />

                            {/* <NetworkSwitcher /> */}
                            {media.isMobile ? (
                                <Box
                                    style={{
                                        height: "100%",
                                        marginRight: theme.spacing.smaller,
                                    }}
                                >
                                    <MobileMenuButton
                                        items={NAVIGATION_LINKS}
                                    />
                                </Box>
                            ) : null}
                        </Flex>
                    </Flex>
                </BlurView>
            </Box>
        </Box>
    );
});

const UserMenu = () => {
    const theme = useTheme();
    const media = useMedia();
    const { address } = useAccount();
    const { toggleConnectModal } = useAppContext();
    const { disconnect } = useDisconnect();

    const [isHovering, setIsHovering] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isUsernameModalVisible, setIsUsernameModalVisible] = useState(false);
    const containerRef = useRef(null);

    useOutsideAlerter(containerRef, () => {
        if (isOpen) setIsOpen(false);
    });

    const toggleUsernameModal = useCallback(() => {
        setIsUsernameModalVisible((prev) => !prev);
    }, []);

    const toggleOpen = useCallback(() => {
        setIsOpen((prev) => !prev);
    }, []);

    const onDisconnect = useCallback(() => {
        disconnect();
        toggleOpen();
    }, [disconnect, toggleOpen]);

    const formattedAddress = formatAddress(address);

    const options = useMemo(() => {
        return [
            {
                id: "edit-username",
                text: "Edit Username",
                // icon: "edit",
                onPress: () => {
                    toggleUsernameModal();
                },
            },
            // {
            //     id: "disconnect",
            //     text: "Disconnect",
            //     onPress: onDisconnect,
            // },
        ];
    }, [toggleUsernameModal]);

    if (!address)
        return (
            <Button
                small
                text="Connect Wallet"
                primary
                onPress={toggleConnectModal}
            />
        );

    return (
        <>
            <Box ref={containerRef}>
                <Pressable onPress={toggleOpen}>
                    <Box
                        align="center"
                        justify="center"
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                        style={{
                            height: 32,
                            borderRadius: theme.radius.medium,
                            paddingHorizontal: theme.spacing.small,
                            backgroundColor: theme.color.rgba(
                                theme.color.white,
                                isOpen || isHovering ? 0.2 : 0.1
                            ),
                        }}
                    >
                        <Flex
                            align="center"
                            style={{ width: "100%" }}
                            gap={theme.spacing.small}
                        >
                            <Avatar size={18} string={address} />
                            <Text smaller bold primary selectable={false}>
                                {formattedAddress}
                            </Text>
                            {media.isMobile ? null : <Balance />}
                        </Flex>
                    </Box>
                </Pressable>

                {isOpen ? (
                    <Box
                        style={{
                            position: "absolute",
                            top: "100%",
                            marginTop: -theme.spacing.smallest,
                            left: 0,
                            right: 0,
                            backgroundColor: theme.color.black,
                        }}
                    >
                        <Box
                            style={{
                                backgroundColor: theme.color.rgba(
                                    theme.color.white,
                                    0.2
                                ),

                                borderBottomLeftRadius: theme.radius.big,
                                borderBottomRightRadius: theme.radius.big,
                            }}
                        >
                            <Box
                                style={{
                                    paddingVertical: theme.spacing.smaller,
                                }}
                            >
                                {options.map((option, index) => {
                                    return (
                                        <OptionItem
                                            key={option.id}
                                            option={option}
                                        />
                                    );
                                })}
                            </Box>

                            <Box
                                style={{
                                    padding: theme.spacing.big,
                                    paddingTop: 0,
                                }}
                            >
                                <Button
                                    iconProps={{
                                        name: "disconnect",
                                    }}
                                    small
                                    primary
                                    stroke
                                    noBorder
                                    onPress={onDisconnect}
                                    style={
                                        media.isMobile
                                            ? { width: 32, padding: 0 }
                                            : {}
                                    }
                                    text={
                                        media.isMobile
                                            ? undefined
                                            : "Disconnect"
                                    }
                                />
                            </Box>
                        </Box>
                    </Box>
                ) : null}
            </Box>
            <Modal
                title={"Edit Username"}
                isVisible={isUsernameModalVisible}
                onClose={toggleUsernameModal}
            >
                <EditUsername />
            </Modal>
        </>
    );
};

const OptionItem = ({
    option,
}: {
    option: { id: string; text: string; onPress: () => void; icon?: string };
}) => {
    const theme = useTheme();
    return (
        <Pressable
            onPress={option.onPress}
            style={{
                flexDirection: "row",
                padding: theme.spacing.big,
                paddingVertical: theme.spacing.smaller,
                gap: theme.spacing.smaller,
                alignItems: "center",
            }}
        >
            {option?.icon ? <Icon name={option.icon} /> : null}
            <Text smaller semiBold selectable={false}>
                {option.text}
            </Text>
        </Pressable>
    );
};
