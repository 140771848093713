import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { Button } from "@molecules/Button";
import { CurrencyInputRef, Input, InputRef } from "@molecules/Input";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Pressable } from "react-native";
import { useTheme } from "styled-components/native";
import { useAccount } from "wagmi";

import { generateUsername } from "unique-username-generator";
export const EditUsername = memo(() => {
    const theme = useTheme();
    const { address } = useAccount();
    const { userFE, refetchUser } = useAppContext();
    const [username, setUsername] = useState("");

    const isValid = useMemo(() => {
        if (!username || username.length < 3) return true;
        const res =
            /^(?!.*[ ]{2})(?!.*[.]{2})(?!.*[_]{2})(?!.*[-]{2})[a-zA-Z0-9_ .-]{3,25}$/.exec(
                username
            );
        return res;
    }, [username]);

    const onSubmit = useCallback(async () => {
        try {
            if (!address) return;
            const params = new URLSearchParams({
                address,
                username,
            });
            await fetch(`/api/user/update-username`, {
                method: "POST",
                body: params,
            });
            refetchUser();
        } catch (err) {
            console.log(err);
        }
    }, [address, refetchUser, username]);

    const inputRef = useRef<InputRef>();

    const onRemove = useCallback(async () => {
        try {
            if (!address) return;
            const params = new URLSearchParams({
                address: address,
            });
            await fetch(`/api/user/delete-username`, {
                method: "POST",
                body: params,
            });
            await refetchUser();
            setUsername("");
            inputRef.current?.input?.focus();
        } catch (err) {
            console.log(err);
        }
    }, [address, refetchUser]);

    const onRegenerate = useCallback(async () => {
        try {
            if (!address) return;
            const generatedUsername = generateUsername(" ", undefined, 25);
            const params = new URLSearchParams({
                username: generatedUsername,
            });
            const result = await fetch(
                `/api/user/available-username?${params}`
            ).then((res) => res.json());
            if (result) {
                inputRef.current?.input?.focus();
                setUsername(generatedUsername);
            }
        } catch (err) {
            console.log(err);
        }
    }, [address]);

    useEffect(() => {
        if (userFE?.username) setUsername(userFE.username);
    }, [onRegenerate, userFE?.username]);

    const buttonProps = useMemo(() => {
        if (userFE?.username === username) {
            return {
                text: "Update",
                stroke: true,
                noBorder: true,
                disabled: true,
            };
        }
        if (!username?.length)
            return {
                text: "Update",
                stroke: true,
                noBorder: true,
                disabled: true,
            };
        if (username.length < 3)
            return {
                text: "Update",
                primary: true,
                disabled: true,
            };

        return {
            text: "Update",
            primary: true,
            disabled: !username,
            onPress: onSubmit,
        };
    }, [onSubmit, userFE?.username, username]);

    return (
        <Box
            style={{
                padding: theme.spacing.bigger,
                paddingTop: 0,
            }}
            gap={theme.spacing.big}
        >
            {/* @ts-ignore */}
            <Input
                type="text"
                ref={inputRef}
                value={username}
                error={!isValid ? "Invalid username" : undefined}
                // @ts-ignore
                placeholder={userFE?.username}
                placeholderTextColor={theme.color.rgba(theme.color.white, 0.7)}
                onChangeText={setUsername}
                renderRight={
                    <Flex gap={theme.spacing.smaller}>
                        {userFE?.username ? (
                            <Pressable onPress={onRemove}>
                                <Text smaller semiBold primary>
                                    Remove
                                </Text>
                            </Pressable>
                        ) : (
                            <Pressable onPress={onRegenerate}>
                                <Text smaller semiBold primary>
                                    Generate
                                </Text>
                            </Pressable>
                        )}
                    </Flex>
                }
            />
            <Text
                smaller
                lineHeight={theme.text.big}
                color={theme.color.rgba(theme.color.white, 0.7)}
            >
                Username must be between 3 and 25 characters long and can only
                contain lowercase letters, numbers, underscores, and periods.
            </Text>
            <Button big {...buttonProps} />
        </Box>
    );
});
