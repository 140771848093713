"use client";
import { Box, Flex } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { Lottie } from "@atoms/Lottie/Lottie";
import { Text } from "@atoms/Text";
import { useOutsideAlerter } from "@hooks/useOutsidePress";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FlatList, ListRenderItem, Pressable, ViewStyle } from "react-native";
import Animated, {
    Easing,
    useAnimatedStyle,
    useSharedValue,
    withTiming,
} from "react-native-reanimated";
import { useTheme } from "styled-components/native";
import LOTTIE_SPINNER from "../../../../../assets/animations/infinity-white.json";
import { BlurView } from "@atoms/BlurView";

export interface ISelect {
    selected: SelectOptionType;
    options: SelectOptionType[];
    loading?: boolean;
    noArrow?: boolean;
    disabled?: boolean;
    style?: ViewStyle;
    small?: boolean;
    big?: boolean;
    prefix?: string;
    position?: "top" | "bottom";
    onChange?: (option: SelectOptionType) => void;
}

export type SelectOptionType = {
    text: string;
    value: string;
    red?: boolean;
    onPress?: (item: SelectOptionType) => void;
};

export const Select = memo(
    ({
        selected,
        options,
        noArrow,
        style,
        loading,
        disabled,
        small,
        big,
        prefix,
        position = "bottom",
        onChange,
    }: ISelect) => {
        const theme = useTheme();

        const wrapperRef = useRef(null);

        const [isOpen, setIsOpen] = useState(false);
        const [isHovered, setIsHovered] = useState(false);

        useEffect(() => {
            if (loading) setIsOpen(false);
        }, [loading]);

        const onSelect = useCallback(
            (option: SelectOptionType) => {
                onClose();
                onChange && onChange(option);
            },
            [onChange]
        );

        const onToggle = () => {
            if (!disabled) setIsOpen(!isOpen);
        };

        const onClose = () => {
            setIsOpen(false);
        };

        useOutsideAlerter(wrapperRef, onClose);

        const renderItem: ListRenderItem<SelectOptionType> = useMemo(
            () =>
                ({ item, index }) => {
                    return (
                        <SelectOption
                            key={`select-option-${item.value}-${index}`}
                            onPress={() =>
                                item?.onPress?.(item) || onSelect(item)
                            }
                            text={item.text}
                            isActive={item.value === selected?.value}
                            small={small}
                        />
                    );
                },
            [onSelect, selected?.value, small]
        );

        const optionsStyle = useMemo(() => {
            const radius = small ? theme.radius.small : theme.radius.medium;
            let style = {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: radius,
                borderBottomRightRadius: radius,
            };
            if (position === "top") {
                style = {
                    borderTopLeftRadius: radius,
                    borderTopRightRadius: radius,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                };
            }

            return style;
        }, [position, theme.radius.small, small, theme.radius.medium]);

        const buttonStyle = useMemo(() => {
            if (isOpen) {
                if (position === "top") {
                    return {
                        borderRadius: small
                            ? theme.radius.small
                            : theme.radius.medium,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                    };
                }
                return {
                    borderRadius: small
                        ? theme.radius.small
                        : theme.radius.medium,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                };
            }
            return {
                borderRadius: small ? theme.radius.small : theme.radius.medium,
                borderBottomLeftRadius: small
                    ? theme.radius.small
                    : theme.radius.medium,
                borderBottomRightRadius: small
                    ? theme.radius.small
                    : theme.radius.medium,
            };
        }, [isOpen, position, small, theme.radius.medium, theme.radius.small]);

        return (
            <Pressable onPress={onToggle}>
                <Box
                    ref={wrapperRef}
                    style={{
                        position: "relative",
                    }}
                >
                    <Box
                        style={{
                            position: "absolute",
                            top: position === "bottom" ? "100%" : "auto",
                            bottom: position === "top" ? "100%" : "auto",
                            minWidth: "100%",
                            right: 0,
                            opacity: isOpen ? 1 : 0,
                            marginTop: 0,
                            zIndex: 10,
                            pointerEvents: isOpen ? "auto" : "none",
                        }}
                    >
                        <BlurView intensity={24}>
                            <Box
                                style={{
                                    ...optionsStyle,
                                    backgroundColor: theme.color.rgba(
                                        theme.color.white,
                                        0.2
                                    ),
                                }}
                            >
                                <FlatList
                                    style={{
                                        ...optionsStyle,
                                        borderColor: theme.color.rgba(
                                            theme.color.white,
                                            0.2
                                        ),
                                    }}
                                    data={options}
                                    renderItem={renderItem}
                                />
                            </Box>
                        </BlurView>
                    </Box>
                    <Box
                        style={{
                            ...buttonStyle,
                        }}
                    >
                        <Flex
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            align="center"
                            justify="space-between"
                            style={{
                                ...buttonStyle,
                                maxHeight: big ? 28 : 24,
                                // borderWidth: 1,
                                borderColor: theme.color.rgba(
                                    theme.color.white,
                                    0.2
                                ),
                                backgroundColor: theme.color.rgba(
                                    theme.color.white,
                                    isHovered || isOpen ? 0.2 : 0.1
                                ),
                                borderBottomColor: theme.color.rgba(
                                    theme.color.white,
                                    isOpen ? 0 : 0.2
                                ),
                                opacity: disabled ? 0.4 : 1,
                                position: "relative",
                                paddingHorizontal: big
                                    ? theme.spacing.smaller
                                    : small
                                    ? theme.spacing.smallest
                                    : theme.spacing.smaller,
                                paddingVertical: big
                                    ? theme.spacing.smaller
                                    : small
                                    ? theme.spacing.tiny
                                    : theme.spacing.smallest + 1,

                                minWidth: small ? 40 : 70,
                                gap: theme.spacing.tiny / 2,
                                ...style,
                            }}
                        >
                            {loading ? (
                                <Lottie
                                    source={LOTTIE_SPINNER}
                                    width={50}
                                    height={30}
                                />
                            ) : (
                                <>
                                    <Text
                                        smallest
                                        semiBold
                                        selectable={false}
                                        style={{
                                            paddingRight: noArrow
                                                ? 0
                                                : theme.spacing.tiny,
                                        }}
                                    >
                                        {prefix
                                            ? `${prefix}: ${selected?.text}`
                                            : selected?.text}
                                    </Text>
                                    {noArrow ? null : (
                                        <Icon
                                            name="caret"
                                            size={8}
                                            color={theme.color.rgba(
                                                theme.color.white,
                                                0.4
                                            )}
                                            rotate={isOpen ? -90 : 90}
                                        />
                                    )}
                                </>
                            )}
                        </Flex>
                    </Box>
                </Box>
            </Pressable>
        );
    }
);

interface ISelectOption {
    text: string;
    isActive?: boolean;
    small?: boolean;
    onPress: () => void;
}

const SelectOption = memo(
    ({ text, onPress, isActive, small }: ISelectOption) => {
        const theme = useTheme();

        const [isHovered, setIsHovered] = useState(false);
        const opacity = useSharedValue(isActive ? 1 : 0.7);
        const hoverStyle = useAnimatedStyle(() => {
            const config = {
                duration: 150,
                easing: Easing.bezier(0.25, 0.1, 0.25, 1),
            };

            return {
                opacity: withTiming(opacity.value, config),
            };
        }, [opacity.value]);

        return (
            <Pressable
                onPress={onPress}
                // @ts-ignore
                onMouseEnter={() => {
                    setIsHovered(true);
                    opacity.value = 1;
                }}
                onMouseLeave={() => {
                    setIsHovered(false);
                    opacity.value = 0.7;
                }}
            >
                <Animated.View
                    style={[
                        {
                            paddingHorizontal: small
                                ? theme.spacing.smallest
                                : theme.spacing.smaller,
                            paddingVertical: small
                                ? theme.spacing.tiny
                                : theme.spacing.smallest,
                            backgroundColor: theme.color.rgba(
                                theme.color.white,
                                isHovered ? 0.1 : 0
                            ),
                        },
                        hoverStyle,
                    ]}
                >
                    <Text
                        numberOfLines={1}
                        selectable={false}
                        smallest
                        color={theme.color.rgba(theme.color.white, 1)}
                    >
                        {text}
                    </Text>
                </Animated.View>
            </Pressable>
        );
    }
);
