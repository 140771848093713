import { Box, Flex } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { usePrevious } from "@hooks/usePrevious";
import { memo, useEffect, useRef, useState } from "react";
import { LayoutChangeEvent, Pressable, ViewStyle } from "react-native";
import Animated, {
    Easing,
    interpolateColor,
    useAnimatedStyle,
    useDerivedValue,
    useSharedValue,
    withTiming,
} from "react-native-reanimated";
import { useTheme } from "styled-components/native";

export const Collapsible = memo(
    ({
        children,
        renderHeader,
        style,
        headerStyle,
        contentStyle,
        fullBackground = true,
        noBackground,
        noIcon,
        iconLeft,
        iconInverted,
        isOpen,
    }: {
        isOpen?: boolean;
        children: JSX.Element | JSX.Element[];
        renderHeader: JSX.Element;
        fullBackground?: boolean;
        noBackground?: boolean;
        style?: ViewStyle;
        contentStyle?: ViewStyle;
        headerStyle?: ViewStyle;
        noIcon?: boolean;
        iconLeft?: boolean;
        iconInverted?: boolean;
    }) => {
        const theme = useTheme();
        const [isActive, setIsActive] = useState(isOpen || false);
        const [isHovered, setIsHovered] = useState(false);

        const bottomRef = useRef<{ offsetHeight: number }>(null);
        const topRef = useRef<{ offsetHeight: number }>(null);

        const heightAnimation = useSharedValue(50);
        const [height, setHeight] = useState<number | null>(null);
        const opacityAnimation = useSharedValue(0);

        const backgroundAnimation = useDerivedValue(() => {
            return withTiming(isActive || isHovered ? 1 : 1, {
                duration: 100,
                easing: Easing.bezier(0.25, 0.1, 0.25, 1),
            });
        }, [isActive, isHovered]);

        const heightAnimationStyle = useAnimatedStyle(() => {
            return {
                height: withTiming(heightAnimation.value, {
                    duration: 150,
                    easing: Easing.bezier(0.25, 0.1, 0.25, 1),
                }),
            };
        }, [isActive, children]);

        const backgroundAnimationStyle = useAnimatedStyle(() => {
            if (noBackground) return {};
            return {
                backgroundColor: interpolateColor(
                    backgroundAnimation.value,
                    [0, 1],
                    [theme.color.black, theme.color.rgba(theme.color.black, 0)]
                ),
            };
        }, [isActive, theme.color.black, theme.color.background]);

        const opacityAnimationStyle = useAnimatedStyle(() => {
            return {
                opacity: withTiming(opacityAnimation.value, {
                    duration: 150,
                    easing: Easing.bezier(0.25, 0.1, 0.25, 1),
                }),
            };
        }, [isActive]);

        const previousBottomHeight = usePrevious(
            bottomRef?.current?.offsetHeight
        );

        useEffect(() => {
            if (
                previousBottomHeight &&
                bottomRef?.current?.offsetHeight !== previousBottomHeight
            ) {
                heightAnimation.value =
                    bottomRef?.current?.offsetHeight! +
                    topRef?.current?.offsetHeight!;
                return;
            }
            if (isActive) {
                heightAnimation.value =
                    bottomRef?.current?.offsetHeight! +
                    topRef?.current?.offsetHeight!;
                opacityAnimation.value = 1;
            } else {
                heightAnimation.value = topRef?.current?.offsetHeight!;
                opacityAnimation.value = 0;
            }
        }, [isActive, previousBottomHeight, heightAnimation, opacityAnimation]);

        const toggleActive = () => {
            setIsActive(!isActive);
        };

        return (
            <Box
                style={{
                    overflow: isActive ? "visible" : "hidden",
                }}
            >
                <Animated.View
                    style={[
                        heightAnimationStyle,
                        fullBackground ? backgroundAnimationStyle : undefined,
                        style,
                    ]}
                >
                    <Pressable
                        // @ts-ignore
                        ref={topRef}
                        onPress={toggleActive}
                        style={{
                            position: "relative",
                            zIndex: 10,
                        }}
                    >
                        <Animated.View
                            style={[
                                backgroundAnimationStyle,
                                headerStyle,
                                {
                                    borderBottomLeftRadius: isActive
                                        ? 0
                                        : headerStyle?.borderBottomLeftRadius ||
                                          headerStyle?.borderRadius ||
                                          0,
                                    borderBottomRightRadius: isActive
                                        ? 0
                                        : headerStyle?.borderBottomLeftRadius ||
                                          headerStyle?.borderRadius ||
                                          0,
                                },
                            ]}
                        >
                            <Flex
                                align="center"
                                gap={10}
                                direction={iconLeft ? "row-reverse" : "row"}
                            >
                                <Box
                                    flex={1}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                >
                                    {renderHeader}
                                </Box>
                                {noIcon ? null : (
                                    <Box
                                        style={{
                                            paddingRight: theme.spacing.big,
                                        }}
                                        justify="center"
                                    >
                                        <Icon
                                            name="caret"
                                            color={theme.color.rgba(
                                                theme.color.white,
                                                0.4
                                            )}
                                            size={10}
                                            rotate={
                                                iconInverted
                                                    ? isActive
                                                        ? 90
                                                        : -90
                                                    : isActive
                                                    ? -90
                                                    : 90
                                            }
                                        />
                                    </Box>
                                )}
                            </Flex>
                        </Animated.View>
                    </Pressable>
                </Animated.View>

                <Animated.View
                    style={[
                        opacityAnimationStyle,
                        {
                            top: topRef?.current?.offsetHeight,
                            position: "absolute",
                            zIndex: 11,
                            left: 0,
                            right: 0,
                            pointerEvents: isActive ? "auto" : "none",
                        },
                        contentStyle,
                    ]}
                >
                    {/* @ts-ignore */}
                    <Box ref={bottomRef}>{children}</Box>
                </Animated.View>
            </Box>
        );
    }
);
