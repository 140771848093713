import { useQuery, gql } from "@hooks/useApollo";
import { LpNft, LpShare, Pair, User, Vault } from "gql/graphql";
import { createContext, useContext, useMemo } from "react";

import { useAppContext } from "@contexts/app";
import { useWeb3Context } from "@contexts/web3";
import { IPerformance, usePerformance } from "@screens/Trade/usePerformance";
import { formatUnits } from "viem";

import { BigNumber } from "@utils";

const PortfolioContext = createContext(
    {} as {
        pairs: Map<string, Pair>;
        performances: Map<string, IPerformance> | null;
        user: User;
    }
);

function usePortfolio({ address }: { address?: `0x${string}` }) {
    const { pairs, initial } = useAppContext();
    const { currentChain } = useWeb3Context();

    const { data: userData } = useQuery(GET_USER, {
        variables: {
            id: address?.toLowerCase(),
        },
        skip: !address,
    });

    const { performances } = usePerformance(
        [...pairs.values()],
        currentChain.id,
        initial?.historicals
    );

    const user: User = useMemo(() => userData?.user, [userData?.user]);

    return {
        pairs,
        user,
        performances,
    };
}

export function useVault(address?: `0x${string}`) {
    const { data: vaultData, loading: isLoadingVault } = useQuery(GET_VAULT, {
        pollInterval: 5000,
        skip: !address,
        variables: {
            trader: address?.toLowerCase(),
        },
    });

    const vault: Vault = useMemo(() => vaultData?.vault, [vaultData?.vault]);
    const shares: LpShare = useMemo(
        () => vaultData?.lpShares?.[0] || null,
        [vaultData?.lpShares]
    );
    const deposited = useMemo(
        () => (shares?.shares ? Number(formatUnits(shares?.shares, 6)) : 0),
        [shares]
    );
    const lockedDeposits: LpNft[] = useMemo(
        () => vaultData?.lpNFTs || [],
        [vaultData]
    );
    const locked = useMemo(
        () =>
            Number(
                formatUnits(
                    lockedDeposits
                        .reduce((acc, item) => {
                            if (item.isUnlocked) return acc;
                            return acc.add(item.shares);
                        }, BigNumber.from("0"))
                        .toBigInt(),
                    6
                )
            ),
        [lockedDeposits]
    );
    return {
        vault,
        shares,
        deposited,
        locked,
        lockedDeposits,
        isLoadingVault,
    };
}

const PortfolioProvider = ({
    children,
    address,
}: {
    address?: `0x${string}`;
    children: JSX.Element | JSX.Element[];
}) => {
    const value = usePortfolio({ address });
    return (
        <PortfolioContext.Provider value={value}>
            {children}
        </PortfolioContext.Provider>
    );
};

const usePortfolioContext = () => useContext(PortfolioContext);

export { PortfolioProvider, usePortfolioContext };

const GET_VAULT = gql`
    query getUserData($trader: Bytes) @api(name: subgraph) {
        vault(id: "usdc-vault") {
            id
            currentEpoch
            currentEpochStart
            currentEpochEnd
            assets
            shares
        }
        lpShares(where: { user: $trader }) {
            id
            shares
            assets
        }
        lpNFTs(
            where: { user: $trader }
            orderBy: atTime
            orderDirection: desc
        ) {
            id
            assetsDeposited
            assetsDiscount
            atTime
            isUnlocked
            lockDuration
            shares
        }
    }
`;

export const GET_USER = gql`
    query getUserPortfolio($id: ID!) @api(name: subgraph) {
        user(id: $id) {
            totalVolume
            totalOpenTrades
            totalTrades
            totalMarketOrders
            totalOpenVolume
            totalClosedVolume
            totalOpenLimitOrders
            totalCancelledOrders
            totalProfitTrades
            totalLossTrades
            totalPnL
            totalLIQOrders
            totalTPOrders
            totalSLOrders
            totalClosedCollateral
        }
    }
`;
