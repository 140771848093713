import {
    ChangeEventHandler,
    FocusEventHandler,
    KeyboardEventHandler,
    forwardRef,
    memo,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultMaskOptions: CurrencyInputOptions = {
    prefix: "",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: true,
    decimalSymbol: ".",
    decimalLimit: 6,
    integerLimit: null,
    allowNegative: false,
    allowLeadingZeroes: false,
};

export type CurrencyInputOptions = {
    prefix?: string;
    suffix?: string;
    allowDecimal?: boolean;
    decimalLimit?: number;
    integerLimit?: number | null;
    includeThousandsSeparator?: boolean;
    thousandsSeparatorSymbol?: string;
    decimalSymbol?: string;
    allowLeadingZeroes?: boolean;
    allowNegative?: boolean;
};

export interface CurrencyInputRef {
    input?: HTMLElement;
    reset: () => void;
}

export interface CurrencyInputProps {
    value: string | number;
    max?: number;
    min?: number;
    limitReset?: boolean;
    defaultValue?: string | number;
    onChange?: (value: number | null) => void;
    onBlur: (value: number) => void;
    onFocus: () => void;
    maskOptions?: CurrencyInputOptions;
}

export const CurrencyInput = memo(
    forwardRef<CurrencyInputRef, CurrencyInputProps>(
        (
            {
                maskOptions,
                max,
                min,
                value,
                limitReset,
                onFocus,
                onBlur,
                onChange,
                defaultValue,
                ...inputProps
            },
            ref
        ) => {
            const currencyMask = createCurrencyMask({
                ...defaultMaskOptions,
                ...maskOptions,
                max,
                min,
            });

            const inputRef = useRef<MaskedInput>();

            useImperativeHandle(ref, () => ({
                input: inputRef?.current?.inputElement,
                reset: () => {
                    if (inputRef.current?.inputElement)
                        // @ts-ignore
                        inputRef.current.inputElement.value = "";
                },
            }));

            const [isFocused, setIsFocused] = useState(false);

            const onChangeInput = (
                event: ChangeEventHandler<HTMLInputElement>
            ) => {
                // @ts-ignore
                const newValue = event.target.value;
                const normalizedCurrency = Number(
                    newValue.replace(/[^0-9.-]+/g, "")
                );
                if (onChange) onChange(normalizedCurrency);
            };

            const onFocusEvent = () => {
                setIsFocused(true);
                if (onFocus) onFocus();
            };
            const onBlurEvent = (
                event: FocusEventHandler<HTMLInputElement>
            ) => {
                setIsFocused(false);
                // @ts-ignore
                const newValue = event.target.value;
                const normalizedCurrency = Number(
                    newValue.replace(/[^0-9.-]+/g, "")
                );

                // TODO: Check this out
                // if (value === normalizedCurrency) return;

                onBlur?.(normalizedCurrency);

                if (normalizedCurrency === 0) {
                    onChange?.(normalizedCurrency);
                    return;
                }

                if (!isFocused) setIsFocused(true);

                if (max != null && normalizedCurrency >= max) {
                    onChange?.(limitReset ? null : max);
                    return;
                }

                if (min != null && normalizedCurrency <= min) {
                    onChange?.(limitReset ? null : min);
                    return;
                }

                if (newValue.length < 18) onChange?.(normalizedCurrency);
            };

            const onKeyPress = (
                event: KeyboardEventHandler<HTMLInputElement>
            ) => {
                // @ts-ignore
                if (Number(event.target.value) > max) {
                    // @ts-ignore
                    event.preventDefault();
                }
            };

            return (
                // @ts-ignore
                <MaskedInput
                    ref={inputRef}
                    mask={currencyMask}
                    onChange={onChangeInput}
                    value={isFocused || value === 0 ? undefined : value || ""}
                    onFocus={onFocusEvent}
                    onBlur={onBlurEvent}
                    onKeyDown={onKeyPress}
                    max={max}
                    min={min}
                    mode="numeric"
                    defaultValue={defaultValue}
                    {...inputProps}
                />
            );
        }
    )
);

export const createCurrencyMask = (opts = {}) => {
    const numberMask = createNumberMask({
        ...opts,
    });

    return (rawValue: any) => {
        return numberMask(rawValue);
    };
};
