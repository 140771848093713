import { gql } from "@apollo/client/core";
import { useMutation } from "@apollo/client/react/hooks";
import { Box } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import useAnalytics from "@hooks/useAnalytics";
import { Button } from "@molecules/Button";
import Modal from "@organisms/Modal";
import { TRADE_EVENT_NAMES } from "constants/events";
import { useState } from "react";
import { ScrollView } from "react-native";
import { Link } from "solito/link";
import { useTheme } from "styled-components/native";
import { useAccount, useSignMessage } from "wagmi";

const MESSAGE = `Terms and Conditions 1. Introduction\n\nThese Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Ostium accessible at https://ostium.app.\n\nThese Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.\n\nMinors or people below 18 years old are not allowed to use this Website.\n\nIntellectual Property Rights\n\nOther than the content you own, under these Terms, Ostium and/or its licensors own all the intellectual property rights and materials contained in this Website.\n\nYou are granted limited license only for purposes of viewing the material contained on this Website.\n\nRestrictions\n\nYou are specifically restricted from all of the following:\n\npublishing any Website material in any other media;\nselling, sublicensing and/or otherwise commercializing any Website material;\npublicly performing and/or showing any Website material;\nusing this Website in any way that is or may be damaging to this Website;\nusing this Website in any way that impacts user access to this Website;\nusing this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;\nengaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;\nusing this Website to engage in any advertising or marketing.`;

export const AcceptTermsModal = () => {
    const theme = useTheme();
    const { signMessage } = useSignMessage();
    const { address } = useAccount();
    const { trackEvent } = useAnalytics()
    const { refetchUser, showTermsModal, toggleTermsModal } = useAppContext();
    const [updateUserTerms] = useMutation(USER_ACCEPT_TERMS);

    const onAcceptTerms = async () => {
        try {
            trackEvent(TRADE_EVENT_NAMES.ACC_TERMS, { address })
            signMessage(
                {
                    message: MESSAGE,
                    account: address,
                },
                {
                    async onSettled(signedMessage) {
                        try {
                            const params = new URLSearchParams({
                                address: address as string,
                                message: MESSAGE as string,
                                signedMessage: signedMessage as string,
                            });

                            await fetch(`/api/user/accept-terms`, {
                                method: "POST",
                                body: params,
                            });
                            trackEvent(TRADE_EVENT_NAMES.ACC_TERMS_SUCCESS, { address })
                            await refetchUser();
                            toggleTermsModal();
                        } catch (err) {
                            console.warn(err);
                        }
                    },
                }
            );
        } catch (err) {
            console.warn(err);
        }
    };

    return (
        <Modal
            title="Terms and Conditions"
            isVisible={showTermsModal}
            onClose={toggleTermsModal}
        >
            <Box
                style={{
                    padding: theme.spacing.bigger,
                    paddingTop: 0,
                }}
            >
                <Box
                    style={{
                        // borderTopWidth: 1,
                        // borderColor: theme.color.rgba(
                        //     theme.color.white,
                        //     0.15
                        // ),
                        paddingTop: theme.spacing.biggest,
                    }}
                    gap={theme.spacing.bigger}
                >
                    <Text
                        bold
                        biggest
                        primary
                        style={{ lineHeight: theme.text.medium }}
                    >
                        {`Welcome to Ostium`}
                    </Text>
                    <Text
                        smaller
                        semiBold
                        style={{ lineHeight: theme.text.medium }}
                    >
                        {`Ostium is not offered to persons or entities who reside in, are citizens of, are incorporated in, or have a registered office in the United States of America.`}
                    </Text>

                    <ScrollView
                        style={{
                            height: 200,
                            backgroundColor: theme.color.rgba(
                                theme.color.white,
                                0.05
                            ),
                            width: "100%",
                            borderRadius: theme.radius.big,
                        }}
                        contentContainerStyle={{
                            padding: theme.spacing.big,
                        }}
                    >
                        <Text
                            smaller
                            lineHeight={theme.text.medium}
                            color={theme.color.rgba(theme.color.white, 0.6)}
                        >
                            {MESSAGE}
                        </Text>
                    </ScrollView>

                    <Link href="/terms-and-conditions" suppressHydrationWarning>
                        <Text smaller bold>
                            By using Ostium, I agree to the{" "}
                            <Text bold smaller primary>
                                Terms and Conditions
                            </Text>
                            .
                        </Text>
                    </Link>
                    <Button
                        text="Accept Terms"
                        primary
                        onPress={onAcceptTerms}
                    />
                </Box>
            </Box>
        </Modal>
    );
};

const USER_ACCEPT_TERMS = gql`
    mutation UserAcceptTerms(
        $address: String!
        $message: String!
        $signedMessage: String!
    ) @api(name: main) {
        acceptTerms(
            address: $address
            message: $message
            signedMessage: $signedMessage
        ) {
            signedMessageTimestamp
        }
    }
`;
