import { Box } from "@atoms/Flex";

import { BlurView } from "@atoms/BlurView";
import { AnimatedText, Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { useMedia } from "@hooks/useMedia";
import { useRouting } from "expo-next-react-navigation";

import { CTA_HEIGHT, HEADER_HEIGHT } from "@screens/components/Header";
import { useMemo, useState } from "react";
import { FlatList, ListRenderItem, Pressable } from "react-native";
import {
    Easing,
    interpolateColor,
    useAnimatedStyle,
    useDerivedValue,
    withTiming,
} from "react-native-reanimated";
import { useTheme } from "styled-components/native";
import { useAccount } from "wagmi";
import { PortfolioProvider } from "./context";

export const PORTFOLIO_SIDEBAR_WIDTH = 150;

export const PortfolioLayout = ({
    children,
    address,
    sidebar = true,
}: {
    children: JSX.Element;
    address?: `0x${string}`;
    sidebar?: boolean;
}) => {
    const { settings } = useAppContext();
    const theme = useTheme();
    const media = useMedia();

    const { address: myAddress } = useAccount();
    const selectedAddress = address?.toLowerCase() ?? myAddress;
    const isMine = myAddress?.toLowerCase() === address?.toLowerCase();
    const { isCTAActive } = useAppContext();
    return (
        <PortfolioProvider address={selectedAddress as `0x${string}`}>
            <Box
                style={{
                    paddingTop: media.isMobile
                        ? HEADER_HEIGHT * 2 +
                          (isCTAActive ? CTA_HEIGHT : 0) +
                          theme.spacing.biggest
                        : HEADER_HEIGHT + (isCTAActive ? CTA_HEIGHT : 0),
                    flex: 1,
                }}
            >
                <Box
                    direction={media.isMobile ? "column" : "row"}
                    style={{
                        flex: 1,
                    }}
                >
                    {settings.theme.boxed ? null : (
                        <Box
                            style={{
                                position: "fixed",
                                left: 0,
                                right: 0,
                                top:
                                    HEADER_HEIGHT +
                                    (isCTAActive ? CTA_HEIGHT : 0),
                                height: 1,
                                backgroundColor: theme.color.rgba(
                                    theme.color.white,
                                    0.1
                                ),
                            }}
                        />
                    )}

                    {sidebar ? <Sidebar /> : null}

                    <Box
                        style={{
                            flex: 1,
                            paddingLeft: sidebar
                                ? media.isMobile
                                    ? settings.theme.boxed
                                        ? theme.spacing.smaller
                                        : 0
                                    : PORTFOLIO_SIDEBAR_WIDTH
                                : theme.spacing.smaller,
                            paddingRight: settings.theme.boxed
                                ? theme.spacing.smaller
                                : 0,
                            paddingBottom: settings.theme.boxed
                                ? theme.spacing.smaller
                                : 0,
                        }}
                    >
                        {children}
                    </Box>
                </Box>
            </Box>
        </PortfolioProvider>
    );
};

interface ISidebarMenuItem {
    id: string;
    text: string;
    url: string;
    default?: boolean;
}

const Sidebar = () => {
    const theme = useTheme();
    const media = useMedia();
    const { isCTAActive } = useAppContext();
    const renderItem: ListRenderItem<ISidebarMenuItem> = useMemo(
        () =>
            ({ item, index }) => {
                return <SidebarItem item={item} />;
            },
        []
    );

    const data = useMemo(
        () => [
            {
                id: "overview",
                text: "Overview",
                url: "/",
                default: true,
            },
            {
                id: "positions",
                text: "Positions",
                url: "positions",
            },
            // {
            //     id: "pool",
            //     text: "Liquidity Pool",
            //     url: "pool",
            // },
            // {
            //     id: "markets",
            //     text: "Markets",
            //     url: "markets",
            // },
        ],
        []
    );

    return (
        <Box
            intensity={20}
            style={[
                media.isMobile
                    ? {
                          left: 0,
                          right: 0,
                      }
                    : {
                          bottom: 0,
                      },
                ,
                {
                    // @ts-ignore
                    position: "fixed",
                    zIndex: 1000,
                    top: HEADER_HEIGHT + (isCTAActive ? CTA_HEIGHT : 0),
                },
            ]}
        >
            <BlurView
                style={{
                    backgroundColor: theme.color.rgba(theme.color.black, 0.4),
                }}
            >
                <Box
                    style={{
                        width: "100%",
                    }}
                >
                    <Box
                        style={{
                            padding: theme.spacing.big,
                        }}
                    >
                        <Text
                            smallest
                            uppercase
                            color={theme.color.rgba(theme.color.white, 0.6)}
                        >
                            Portfolio
                        </Text>
                    </Box>
                    <FlatList
                        data={data}
                        horizontal={media.isMobile}
                        renderItem={renderItem}
                    />
                </Box>
            </BlurView>
        </Box>
    );
};

const SidebarItem = ({ item }: { item: ISidebarMenuItem }) => {
    const { navigate, pathname } = useRouting();

    const theme = useTheme();
    const [isHovering, setIsHovering] = useState(false);

    const isActive =
        pathname === `/portfolio/${item.url}` ||
        (pathname === "/portfolio" && item.default === true);

    const backgroundAnimation = useDerivedValue(() => {
        return withTiming(isActive ? 2 : isHovering ? 1 : 0, {
            duration: 150,
            easing: Easing.bezier(0.25, 0.1, 0.25, 1),
        });
    }, [isActive, isHovering]);

    const textColorStyle = useAnimatedStyle(() => {
        return {
            color: interpolateColor(
                backgroundAnimation.value,
                [0, 1, 2],
                [
                    theme.color.rgba(theme.color.white, 0.4),
                    theme.color.rgba(theme.color.white, 0.8),
                    theme.color.white,
                ]
            ),
        };
    }, [isActive, isHovering, backgroundAnimation, theme.color.white]);

    const onOpen = () => {
        navigate({
            routeName: item.id,
            web: {
                path: `/portfolio/${item.url}`,
            },
        });
    };

    return (
        <Pressable onPress={onOpen}>
            <Box
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                style={{
                    padding: theme.spacing.big,
                    paddingVertical: theme.spacing.medium,
                }}
            >
                {/* @ts-ignore */}
                <AnimatedText semiBold smallest style={textColorStyle}>
                    {item.text}
                </AnimatedText>
            </Box>
        </Pressable>
    );
};
