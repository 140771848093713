import { Box } from "@atoms/Flex";
import { useAppContext } from "@contexts/app";
import { CTA_HEIGHT, HEADER_HEIGHT } from "@screens/components/Header";

export const TradeLayout = ({ children }: { children: JSX.Element }) => {
    const { isCTAActive } = useAppContext();
    return (
        <Box
            style={{
                flex: 1,
            }}
        >
            <Box
                style={[
                    {
                        width: "100%",
                        alignItems: "center",
                        flex: 1,
                        paddingTop:
                            HEADER_HEIGHT + (isCTAActive ? CTA_HEIGHT : 0),
                    },
                ]}
            >
                <Box
                    style={{
                        width: "100%",
                        flex: 1,
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    );
};
