import { Platform } from "react-native";

const isMobile = Platform.OS === "ios" || Platform.OS === "android";

export const Text = {
    tiny: 10,
    smallest: 11,
    smaller: 12,
    small: 13,
    medium: 15,
    big: 16,
    bigger: 20,
    biggest: 24,
    huge: 32,
    regularMono: isMobile ? "IBMPlexMono_400Regular" : "IBM Plex Mono",
    semiBoldMono: isMobile ? "IBMPlexMono_600SemiBold" : "IBM Plex Mono",
    boldMono: isMobile ? "IBMPlexMono_700Bold" : "IBM Plex Mono",
    regular: isMobile ? "IBMPlexSans_400Regular" : "IBM Plex Sans",
    semiBold: isMobile ? "IBMPlexSans_600SemiBold" : "IBM Plex Sans",
    bold: isMobile ? "IBMPlexSans_700Bold" : "IBM Plex Sans",
    semiBoldPP: isMobile ? "PP Mori" : "PP Mori",
};
