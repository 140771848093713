import { useAppContext } from "@contexts/app";
import { memo } from "react";
import { ViewStyle } from "react-native";
import { useTheme } from "styled-components/native";
import ErrorBoundary from "./ErrorBoundary";
import { Box } from "./Flex";

export const BoxContainer = memo(
    ({
        children,
        flex,
        style,
    }: {
        flex?: number;
        style?: ViewStyle;
        children: JSX.Element | JSX.Element[];
    }) => {
        const theme = useTheme();
        const { settings } = useAppContext();

        const innerStyle = settings?.theme?.boxed
            ? {
                  backgroundColor: theme.color.rgba(theme.color.white, 0.05),
                  borderRadius: theme.radius.big,
              }
            : {
                  borderColor: theme.color.rgba(theme.color.white, 0.05),
              };

        return (
            <Box
                style={[
                    // { borderColor: theme.color.rgba(theme.color.white, 0.07), borderWidth: 1 },
                    innerStyle,
                    style,
                ]}
                flex={flex}
            >
                <ErrorBoundary>{children}</ErrorBoundary>
            </Box>
        );
    }
);
