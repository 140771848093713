import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useState } from "react";
import { State, WagmiProvider as WWagmiProvider } from "wagmi";

import { wagmiConfig } from "./wagmiConfig";

export const WagmiProvider = ({
    children,
    initialState,
}: {
    children: React.ReactNode;
    initialState?: State;
}) => {
    const [queryClient] = useState(() => new QueryClient());

    return (
        <WWagmiProvider config={wagmiConfig} initialState={initialState}>
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
        </WWagmiProvider>
    );
};
