export const Media = {
	isDesktopOrLaptop: false,
	isBigScreen: false,
	isMobile: false,
	isMobileTiny: false,
	isMobileSmallest: false,
	isTablet: false,
	isTabletOrMobile: false,
	isTabletOrMobileDevice: false,
	isPortrait: false,
	isRetina: false,
	isMobileWeb: false,
}
