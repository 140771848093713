import { Box } from "@atoms/Flex";
import { Lottie } from "@atoms/Lottie/Lottie";
import { LottieWeb } from "@atoms/Lottie/LottieWeb";
import { memo, useRef } from "react";
import LOTTIE_SPINNER from "../../../../../assets/animations/infinity.json";

export const InfinityLoader = memo(
    ({
        width,
        height,
        small,
    }: {
        width?: number;
        height?: number;
        small?: boolean;
    }) => {
        const lottieRef = useRef<LottieWeb>(null);

        const loaderWidth = width || (small ? 80 : 120);
        const loaderHeight = height || (small ? 40 : 60);

        return (
            <Box
                style={{
                    height,
                }}
            >
                <Lottie
                    ref={lottieRef}
                    source={LOTTIE_SPINNER}
                    width={loaderWidth}
                    height={loaderHeight}
                />
            </Box>
        );
    }
);
