import { GetFundingRate } from "@ostium/formulae/src";
import { Pair } from "gql/graphql";
import { useMemo } from "react";
import { formatUnits } from "viem";

export function usePairStatistics({
    pair,
    price,
    blockNumber,
}: {
    pair?: Pair;
    price: number | null;
    blockNumber?: bigint;
}) {
    const oi = useMemo(() => {
        if (pair?.shortOI && pair?.longOI && pair?.maxOI && price) {
            const max = Number(formatUnits(pair?.maxOI, 6));

            const oiLong = Number(formatUnits(pair?.longOI, 18)) * price;
            const oiShort = Number(formatUnits(pair?.shortOI, 18)) * price;

            return {
                short: {
                    max: oiShort > max ? oiShort : max,
                    current: oiShort <= 0 ? 0 : oiShort,
                },
                long: {
                    max: oiLong > max ? oiLong : max,
                    current: oiLong <= 0 ? 0 : oiLong,
                },
            };
        }
        return {
            short: {
                max: 0,
                current: 0,
            },
            long: {
                max: 0,
                current: 0,
            },
        };
    }, [pair?.longOI, pair?.maxOI, pair?.shortOI, price]);

    const rollover = useMemo(() => {
        if (pair?.rolloverFeePerBlock)
            return Number(formatUnits(pair?.rolloverFeePerBlock, 18));
        return 0;
    }, [pair?.rolloverFeePerBlock]);

    const fundingRate = useMemo(() => {
        if (pair?.longOI && blockNumber) {
            const result = GetFundingRate(
                pair?.accFundingLong,
                pair?.accFundingShort,
                pair?.lastFundingRate,
                pair?.lastFundingVelocity,
                pair?.maxFundingFeePerBlock,
                pair?.lastFundingBlock,
                blockNumber as unknown as string,
                pair?.longOI,
                pair?.shortOI
            );

            const latest = Number(
                formatUnits(BigInt(result.latestFundingRate), 18)
            );

            if (latest > 0) {
                const shortOI = Number(formatUnits(pair.shortOI, 18));

                return {
                    long: -latest,
                    short:
                        shortOI > 0
                            ? (latest * Number(formatUnits(pair.longOI, 18))) /
                              shortOI
                            : 0,
                };
            } else {
                const longOI = Number(formatUnits(pair.longOI, 18));

                return {
                    short: latest,
                    long:
                        longOI > 0
                            ? (-latest *
                                  Number(formatUnits(pair.shortOI, 18))) /
                              longOI
                            : 0,
                };
            }
        }
        return {
            long: 0,
            short: 0,
        };
    }, [
        pair?.longOI,
        pair?.accFundingLong,
        pair?.accFundingShort,
        pair?.lastFundingRate,
        pair?.lastFundingVelocity,
        pair?.maxFundingFeePerBlock,
        pair?.lastFundingBlock,
        pair?.shortOI,
        blockNumber,
    ]);

    return {
        oi,
        rollover,
        fundingRate,
    };
}
