export const EVENT_NAMES = {
    BET_PAGE_OPEN: "strategies.page.open",
    PLACE_BET_SUCCESS: "strategies.place.bet.success",
    PRESET_CLICK: "strategies.preset.click",
    MARKET_CHANGE: "strategies.market.change",
    PAIR_CHANGE: "strategies.pair.change",
    CHECK_DELEGATION: "strategies.check.delegation",
    HAS_DELEGATION: "strategies.delegation.found",
    SET_DELEGATION_SUCCESS: "strategies.set.delegation.success",
    SET_DELEGATION_FAIL: "strategies.set.delegation.fail",
    PLACE_BET_CLICK: "strategies.place.bet.click",
    PLACE_BET_FAIL: "strategies.place.bet.fail",
    BET_SHARE_CLICK: "strategies.share.click",
    BET_SHARE_TWITTER: "strategies.share.twitter",
    BET_SHARE_TELEGRAM: "strategies.share.telegram",
};

export const TRADE_EVENT_NAMES = {
    CHANGE_PAIR: "trade.change.pair", //
    CHANGE_OT: "trade.change.order.type", //
    CHANGE_CLL: "trade.change.collateral", //
    CHANGE_LEVERAGE: "trade.change.leverage", //
    ACC_TERMS: "trade.accept.terms.click",
    ACC_TERMS_SUCCESS: "trade.accept.terms.success",
    APPROVE_CLICK: "trade.approve.click", //
    APPROVE_SUCCESS: "trade.approve.success", //
    TRADE_CLICK: "trade.confirm.click", //
    TRADE_SUCCESS: "trade.confirm.success", //
    CHANGE_TP: "trade.change.tp",
    CHANGE_SL: "trade.change.sl",
};

export const HOME_EVENT_NAMES = {
    TRADE_CLICK: "home.trade.click", //
    DEPOSIT_CLICK: "home.deposit.click", //
    REFERRAL_CLICK: "home.referral.click", //
    DISCORD_CLICK: "home.dropdown.discord.click", //
    TWITTER_CLICK: "home.dropdown.twitter.click", //
    RESEARCH_CLICK: "home.dropdown.research.click", //
    DOCS_CLICK: "home.dropdown.docs.click", //
    HOME_CLICK: "home.home.click", //
};

export const VAULT_EVENT_NAMES = {
    DP_AMT_CHNG: "vault.deposit.amount.change", //
    DP_LCK_CHNG: "vault.deposit.lock.change", //
    DP_CLICK: "vault.deposit.click", //
    DP_SUCCESS: "vault.deposit.success", //
    WITHDRAW_AMT_CHNG: "vault.withdraw.amount.change", //
    WITHDRAW_CLICK: "vault.withdraw.click", //
    WITHDRAW_SUCCESS: "vault.withdraw.success", //
    UNLOCK_CLICK: "vault.unlock.click", //
    UNLOCK_SUCCESS: "vault.unlock.success", //
};
