import { useCallback, useEffect, useState } from "react";
import { useLocalStorage } from "./useLocalStorage";

export function useStoredState<T>(
    value: T,
    key: string,
    fallback?: T
): [state: T, setState: (value: T) => void] {
    const { getKey, saveKey } = useLocalStorage();
    const [state, saveState] = useState<T>(value || (fallback as T));

    const setState = useCallback(
        (value: T) => {
            saveState(value);
            saveKey(key, value);
        },
        [saveKey, key, saveState]
    );

    useEffect(() => {
        const init = async () => {
            const storedState = await getKey(key);
            if (storedState != null) setState(storedState);
        };

        init();
    }, [getKey, key, saveKey, setState, value]);

    return [state, setState];
}
