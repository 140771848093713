import { Box } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { useMedia } from "@hooks/useMedia";
import { LinearGradient } from "expo-linear-gradient";
import { Children, memo, useRef, useState } from "react";
import {
    LayoutChangeEvent,
    Pressable,
    ScrollView,
    ScrollViewProps,
} from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { useTheme } from "styled-components/native";

export const HorizontalScrollView = memo(
    ({
        children,
        contentContainerStyle,
        minWidth = 100,
        style,
    }: {
        children: JSX.Element[];
        minWidth: number;
        contentContainerStyle?: ScrollViewProps["contentContainerStyle"];
        style?: ScrollViewProps["style"];
    }) => {
        const media = useMedia();
        const scrollViewRef = useRef<ScrollView>(null);

        const [currentIndex, setCurrentIndex] = useState(0);

        const [wrapperWidth, setWrapperWidth] = useState(0);
        const [contentWidth, setContentWidth] = useState(0);

        const [showArrows, setShowArrows] = useState(false);

        const scrollToItem = (newIndex: number) => {
            if (newIndex === -1) return;
            if (newIndex === Children.count(children) + 1) return;

            setCurrentIndex(newIndex);

            if (scrollViewRef.current) {
                scrollViewRef.current.scrollTo({
                    x: newIndex * minWidth,
                    animated: true,
                });
            }
        };

        const onPrev = () => {
            if (media.isMobile) {
                scrollToItem(currentIndex - 1);
                return;
            }

            scrollViewRef?.current?.scrollTo({
                x: 0,
                animated: true,
            });
            setCurrentIndex(0);
        };

        const onNext = () => {
            if (media.isMobile) {
                scrollToItem(currentIndex + 1);
                return;
            }

            scrollViewRef?.current?.scrollTo({
                x: (children.length - 2) * minWidth,
                animated: true,
            });
            setCurrentIndex(children.length - 1);
        };

        const handleScrollContentLayout = (e: LayoutChangeEvent) => {
            const { width } = e.nativeEvent.layout;
            setWrapperWidth(width);

            toggleArrows();
        };

        const toggleArrows = () => {
            if (contentWidth > wrapperWidth) {
                if (!showArrows) setShowArrows(true);
                return;
            }
            if (showArrows) setShowArrows(false);
        };

        return (
            <Box onLayout={handleScrollContentLayout}>
                <GestureHandlerRootView style={{ flex: 1 }}>
                    <ScrollView
                        ref={scrollViewRef}
                        snapToOffsets={
                            Children.map(children, (_, i) => {
                                return i * minWidth;
                            }) || []
                        }
                        onContentSizeChange={(width, height) => {
                            setContentWidth(width);
                            toggleArrows();
                        }}
                        contentContainerStyle={[contentContainerStyle]}
                        style={[
                            {
                                overflow: showArrows ? "scroll" : "visible",
                            },
                            style,
                        ]}
                        horizontal
                        decelerationRate="fast"
                        pagingEnabled
                        scrollEnabled={false}
                        showsHorizontalScrollIndicator={false}
                    >
                        {Children.map(children, (child, index) => {
                            return <Box>{child}</Box>;
                        })}
                    </ScrollView>

                    {showArrows ? (
                        <>
                            {currentIndex !== 0 ? (
                                <HorizontalScrollViewArrow onPress={onPrev} />
                            ) : null}
                            {currentIndex !== children.length - 1 ? (
                                <HorizontalScrollViewArrow
                                    right
                                    onPress={onNext}
                                />
                            ) : null}
                        </>
                    ) : null}
                </GestureHandlerRootView>
            </Box>
        );
    }
);

const HorizontalScrollViewArrow = memo(
    ({ onPress, right }: { onPress: () => void; right?: boolean }) => {
        const theme = useTheme();
        return (
            <Box
                style={{
                    position: "absolute",
                    top: 0,
                    right: right ? 0 : undefined,
                    left: right ? undefined : 0,
                    bottom: 0,
                    zIndex: 10,
                }}
                align="center"
                justify="center"
            >
                <LinearGradient
                    locations={[0, 1]}
                    start={right ? { x: 1, y: 1 } : { x: 0, y: 1 }}
                    colors={[
                        theme.color.rgba(theme.color.black, 1),
                        "transparent",
                    ]}
                    style={{
                        position: "absolute",
                        right: right ? 0 : undefined,
                        left: right ? undefined : 0,
                        top: 0,
                        bottom: 0,
                        width: 50,
                        borderRadius: theme.radius.big,
                        pointerEvents: "none",
                    }}
                />
                <Pressable
                    onPress={onPress}
                    style={{
                        padding: theme.spacing.small,
                        height: "100%",
                        justifyContent: "center",
                    }}
                >
                    <Icon name="caret" size={12} rotate={right ? 0 : -180} />
                </Pressable>
            </Box>
        );
    }
);
