import { ApolloError, gql, useSubscription } from "@hooks/useApollo";
import { Pair } from "gql/graphql";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAppContext } from "./app";

const PriceContext = createContext(
    {} as {
        prices?: Map<
            string,
            {
                mid: number;
                bid: number;
                ask: number;
                isMarketOpen: boolean;
                timestampSeconds: number;
            }
        >;
        price?: {
            mid: number;
            bid: number;
            ask: number;
            isMarketOpen: boolean;
            timestampSeconds: number;
        } | null;
        error?: ApolloError;
    }
);

function usePrice({ pair }: { pair?: Pair }) {
    const { initial } = useAppContext();

    const [prices, setPrices] = useState(
        new Map<
            string,
            {
                mid: number;
                bid: number;
                ask: number;
                isMarketOpen: boolean;
                timestampSeconds: number;
            }
        >(new Map())
    );

    useEffect(() => {
        setPrices(
            new Map(
                initial?.prices?.map((p) => [
                    p.from,
                    {
                        mid: p.mid,
                        bid: p.bid,
                        ask: p.ask,
                        isMarketOpen: p.isMarketOpen,
                        timestampSeconds: p.timestampSeconds,
                    },
                ])
            )
        );
    }, [initial?.prices]);

    const { error } = useSubscription(SUBSCRIPTION_PRICES, {
        onData: ({ data }) => {
            if (data) {
                const newPrice = data.data.price as any;
                if (newPrice?.mid) {
                    setPrices((prev) => {
                        const newPrices = new Map(prev);
                        newPrices.set(newPrice?.from, {
                            mid: newPrice?.mid,
                            bid: newPrice?.bid,
                            ask: newPrice?.ask,
                            isMarketOpen: newPrice?.isMarketOpen,
                            timestampSeconds: newPrice?.timestampSeconds,
                        });
                        return newPrices;
                    });
                }
            }
        },
    });

    const price = useMemo(() => {
        if (pair) return prices.get(pair?.from);
        return {
            mid: 0,
            bid: 0,
            ask: 0,
            isMarketOpen: false,
            timestampSeconds: new Date().getTime(),
        };
    }, [pair, prices]);

    // const pricesRef = useRef(
    //     new Map<
    //         string,
    //         {
    //             mid: number;
    //             bid: number;
    //             ask: number;
    //             isMarketOpen: boolean;
    //             timestampSeconds: number;
    //         }
    //     >(
    //         initial?.prices?.map((p) => [
    //             p.from,
    //             {
    //                 mid: p.mid,
    //                 bid: p.bid,
    //                 ask: p.ask,
    //                 isMarketOpen: p.isMarketOpen,
    //                 timestampSeconds: p.timestampSeconds,
    //             },
    //         ])
    //     )
    // );

    // const priceRef = useRef<{
    //     mid: number;
    //     bid: number;
    //     ask: number;
    //     isMarketOpen: boolean;
    //     timestampSeconds: number;
    // } | null>({
    //     mid: initial?.prices?.find((p) => p.from === pair?.from)?.mid || 0,
    //     bid: initial?.prices?.find((p) => p.from === pair?.from)?.bid || 0,
    //     ask: initial?.prices?.find((p) => p.from === pair?.from)?.ask || 0,
    //     isMarketOpen:
    //         initial?.prices?.find((p) => p.from === pair?.from)?.isMarketOpen ||
    //         false,
    //     timestampSeconds: new Date().getTime(),
    // });

    // const price = useMemo(() => {
    //     if (!!!pair) return priceRef.current;

    //     if (data?.price?.isMarketOpen) {
    //         if (
    //             data?.price?.mid &&
    //             data?.price?.from === pair?.from &&
    //             data?.price.mid !== priceRef?.current?.mid
    //         ) {
    //             priceRef.current = {
    //                 mid: data?.price?.mid,
    //                 bid: data?.price?.bid,
    //                 ask: data?.price?.ask,
    //                 isMarketOpen: data?.price?.isMarketOpen,
    //                 timestampSeconds: data?.price?.timestampSeconds,
    //             };
    //         }
    //     } else {
    //         const previous = priceRef.current;

    //         if (previous) {
    //             priceRef.current = {
    //                 mid: previous?.mid,
    //                 bid: previous?.bid,
    //                 ask: previous?.ask,
    //                 isMarketOpen: false,
    //                 timestampSeconds: previous.timestampSeconds,
    //             };
    //         }
    //     }

    //     return priceRef.current;
    // }, [data, pair]);

    // const prices = useMemo(() => {
    //     if (data) {
    //         if (data?.price?.isMarketOpen) {
    //             if (data?.price?.mid) {
    //                 pricesRef.current.set(data?.price?.from, {
    //                     mid: data?.price?.mid,
    //                     bid: data?.price?.bid,
    //                     ask: data?.price?.ask,
    //                     isMarketOpen: data?.price?.isMarketOpen,
    //                     timestampSeconds: data?.price?.timestampSeconds,
    //                 });
    //             }
    //         } else {
    //             const previous = pricesRef.current.get(data?.price?.from);

    //             if (previous) {
    //                 pricesRef.current.set(data?.price?.from, {
    //                     mid: previous?.mid,
    //                     bid: previous?.bid,
    //                     ask: previous?.ask,
    //                     isMarketOpen: false,
    //                     timestampSeconds: previous.timestampSeconds,
    //                 });
    //             }
    //         }
    //     }
    //     return pricesRef.current;
    // }, [data]);

    useEffect(() => {
        // Cleanup
        return () => {
            setPrices(new Map());
            // setPrice({
            //     mid: 0,
            //     bid: 0,
            //     ask: 0,
            //     isMarketOpen: false,
            //     timestampSeconds: new Date().getTime(),
            // });
            // pricesRef.current = new Map();
            // priceRef.current = {
            //     mid: 0,
            //     bid: 0,
            //     ask: 0,
            //     isMarketOpen: false,
            //     timestampSeconds: new Date().getTime(),
            // };
        };
    }, []);

    return { price, prices, error };
}

const PriceProvider = ({
    children,
    pair,
}: {
    children: JSX.Element | null;
    pair?: Pair;
}) => {
    const value = usePrice({ pair });

    return (
        <PriceContext.Provider value={value}>{children}</PriceContext.Provider>
    );
};

const SUBSCRIPTION_PRICES = gql`
    subscription PriceSubscription {
        price {
            from
            to
            isMarketOpen
            mid
            bid
            ask
            timestampSeconds
        }
    }
`;
const usePriceContext = () => useContext(PriceContext);

export { PriceProvider, usePriceContext };
