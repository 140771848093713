import { Flex } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { Text } from "@atoms/Text";
import { Pressable, ViewStyle } from "react-native";
import { useTheme } from "styled-components/native";
import { memo, useState } from "react";
import {
    BIG_MAX_WIDTH,
    BIG_MIN_WIDTH,
    MAX_WIDTH,
    MIN_WIDTH,
} from "@screens/Trade/components/List/utils";
import { useMedia } from "@hooks/useMedia";
import { Sort } from "@screens/Trade/components/List/Template/context";

export const COLUMN_HEADER_HEIGHT = 30;

export interface IListColumn {
    value: number | string;
    text: string;
    hasSort?: boolean;
    hasHide?: boolean;
    style?: ViewStyle;
}

interface IListColumns {
    options: IListColumn[];
    style?: ViewStyle;
    sort?: Sort;
    currentTab: string;
    disabled?: boolean;
    onChange?: (option: IListColumn) => void;
}

export const ListColumns = memo(
    ({
        options,
        style,
        sort,
        currentTab,
        disabled,
        onChange,
    }: IListColumns) => {
        const theme = useTheme();

        return (
            <Flex
                style={{
                    height: COLUMN_HEADER_HEIGHT,
                    width: "100%",
                    // borderBottomWidth: 1,
                    // borderColor: theme.color.rgba(theme.color.white, 0.1),
                    ...style,
                }}
            >
                {options?.map((item, index) => {
                    const isFirst = index === 0;
                    const isLast = index === options.length - 1;
                    return (
                        <ListHeaderOptionItem
                            isFirst={isFirst}
                            isLast={isLast}
                            key={`list-header-option-${item?.value}-${index}`}
                            currentTab={currentTab}
                            item={item}
                            sort={sort}
                            disabled={disabled}
                            onPress={() =>
                                item?.hasSort && !disabled && onChange?.(item)
                            }
                        />
                    );
                })}
            </Flex>
        );
    }
);

const ListHeaderOptionItem = memo(
    ({
        item,
        isFirst,
        isLast,
        onPress,
        disabled,
        sort,
        currentTab,
    }: {
        item: IListColumn;
        isFirst: boolean;
        isLast: boolean;
        onPress: () => void;
        disabled?: boolean;
        sort?: Sort;
        currentTab: string;
    }) => {
        const [isHovered, setIsHovered] = useState(false);

        const theme = useTheme();
        const media = useMedia();

        const isSortable =
            (isHovered && item.hasSort) ||
            (sort?.by === item?.value && currentTab === sort?.tab && !disabled);

        return (
            <Pressable
                onPress={onPress}
                style={{
                    // @ts-ignore
                    cursor: disabled ? "text" : undefined,
                    flex: item?.style?.width && !isLast ? undefined : 1,
                    minWidth: item?.style?.width
                        ? undefined
                        : item?.style?.minWidth || MIN_WIDTH,
                    maxWidth:
                        item?.style?.width || isLast
                            ? undefined
                            : item?.style?.maxWidth || MAX_WIDTH,
                    paddingLeft: isFirst ? theme.spacing.bigger : 0,
                    paddingRight: isLast ? theme.spacing.bigger : 0,
                    alignItems: isLast ? "flex-end" : "flex-start",
                    ...item?.style,
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <Flex align="center" flex={1} gap={theme.spacing.tiny}>
                    <Text
                        smallest
                        color={theme.color.rgba(
                            theme.color.white,
                            isSortable ? 0.7 : 0.4
                        )}
                        selectable={false}
                    >
                        {item?.text}
                    </Text>
                    {sort?.by === item?.value &&
                    currentTab === sort?.tab &&
                    !disabled ? (
                        <Icon
                            name="caret-filled"
                            rotate={sort?.direction === "desc" ? 90 : -90}
                            color={theme.color.rgba(theme.color.white, 0.7)}
                            size={7}
                        />
                    ) : null}
                </Flex>
            </Pressable>
        );
    }
);
