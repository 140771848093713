import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { Tooltip } from "@molecules/Tooltip";
import { RefObject, useState } from "react";
import { Pressable } from "react-native";
import { useTheme } from "styled-components/native";
import { useTutorialContext } from "./context";
import { Icon } from "@atoms/Icon";

export interface IStep {
    id: string;
    ref: RefObject<Element>;
    title: string;
    content: string;
}

export const Step = ({ index, step }: { index: number; step: IStep }) => {
    const {
        onPrevious,
        onNext,
        goToStep,
        toggleActive,
        steps,
        currentStepIndex,
    } = useTutorialContext();
    const theme = useTheme();

    const isActive = index === currentStepIndex;
    const maxIndex = steps.size;

    const left =
        (step.ref?.current?.getBoundingClientRect().left || 0) + window.scrollX;
    const top =
        (step.ref?.current?.getBoundingClientRect().top || 0) + window.scrollY;

    const onClose = () => {
        toggleActive();
    };

    const onOpen = () => {
        goToStep(index);
    };

    return (
        <Box
            style={{
                position: "absolute",
                top,
                left,
                zIndex: 100,
            }}
        >
            <Tooltip
                minWidth={260}
                position="bottom-left"
                isActive={isActive}
                renderContent={
                    <Box
                        style={{
                            margin: -theme.spacing.big,
                        }}
                    >
                        <Flex align="center" justify="space-between">
                            <Box
                                style={{
                                    paddingLeft: theme.spacing.big,
                                }}
                            >
                                <Text semiBold small>
                                    {step?.title}
                                </Text>
                            </Box>
                            <Pressable onPress={onClose}>
                                <Box
                                    style={{
                                        padding: theme.spacing.big,
                                    }}
                                >
                                    <Icon size={12} name="close" />
                                </Box>
                            </Pressable>
                        </Flex>
                        <Box
                            style={{
                                padding: theme.spacing.big,
                                paddingTop: theme.spacing.smallest,
                                paddingBottom: theme.spacing.bigger,
                            }}
                        >
                            <Text
                                small
                                color={theme.color.rgba(theme.color.white, 0.6)}
                            >
                                {step?.content}
                            </Text>
                        </Box>
                        <Flex
                            align="center"
                            justify="space-between"
                            style={{
                                backgroundColor: theme.color.rgba(
                                    theme.color.white,
                                    0.05
                                ),
                            }}
                        >
                            <Pressable
                                disabled={currentStepIndex === 0}
                                onPress={onPrevious}
                            >
                                <Box
                                    style={{
                                        padding: theme.spacing.big,
                                    }}
                                >
                                    <Icon
                                        size={12}
                                        color={
                                            currentStepIndex === 0
                                                ? theme.color.rgba(
                                                      theme.color.white,
                                                      0.4
                                                  )
                                                : undefined
                                        }
                                        name="arrow"
                                        rotate={-90}
                                    />
                                </Box>
                            </Pressable>
                            <Box>
                                <Text smaller semiBold>
                                    Step {currentStepIndex + 1}/{maxIndex}
                                </Text>
                            </Box>
                            <Pressable
                                disabled={currentStepIndex === steps.size - 1}
                                onPress={onNext}
                            >
                                <Box
                                    style={{
                                        padding: theme.spacing.big,
                                    }}
                                >
                                    <Icon
                                        size={12}
                                        color={
                                            currentStepIndex === steps.size - 1
                                                ? theme.color.rgba(
                                                      theme.color.white,
                                                      0.4
                                                  )
                                                : undefined
                                        }
                                        name="arrow"
                                        rotate={90}
                                    />
                                </Box>
                            </Pressable>
                        </Flex>
                    </Box>
                }
            >
                <Pressable onPress={onOpen}>
                    <Box
                        style={{
                            backgroundColor: theme.color.primary,
                            width: 8,
                            height: 8,
                            borderRadius: 100,
                        }}
                        align="center"
                        justify="center"
                    >
                        : theme.color.rgba(
                        <Box
                            style={{
                                backgroundColor: theme.color.rgba(
                                    theme.color.primary,
                                    0.2
                                ),
                                width: 14,
                                height: 14,
                                borderRadius: 100,
                            }}
                        />
                    </Box>
                </Pressable>
            </Tooltip>
        </Box>
    );
};
