import { Box, Flex } from "@atoms/Flex";
import { AnimatedIcon } from "@atoms/Icon";
import { Text } from "@atoms/Text";
import { Tooltip } from "@molecules/Tooltip";
import { Pill } from "@screens/Trade/components/Pill";
import { formatDate } from "@utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Linking, Pressable } from "react-native";
import { useTheme } from "styled-components/native";

enum Status {
    UP = "UP",
    HASISSUES = "HASISSUES",
    UNDERMAINTENANCE = "UNDERMAINTENANCE",
}

enum IncidentStatus {
    INVESTIGATING = "INVESTIGATING",
    IDENTIFIED = "IDENTIFIED",
    MONITORING = "MONITORING",
    RESOLVED = "RESOLVED",
}

enum Impact {
    OPERATIONAL = "OPERATIONAL",
    PARTIALOUTAGE = "PARTIALOUTAGE",
    MINOROUTAGE = "MINOROUTAGE",
    MAJOROUTAGE = "MAJOROUTAGE",
    DEGRADEDPERFORMANCE = "DEGRADEDPERFORMANCE",
    UNDERMAINTENANCE = "UNDERMAINTENANCE",
}

type Incident = {
    name: string;
    started: string;
    status: IncidentStatus;
    impact: Impact;
    url: string;
};

enum MaintenanceStatus {
    NOTSTARTEDYET = "NOTSTARTEDYET",
    INPROGRESS = "INPROGRESS",
    COMPLETED = "COMPLETED",
}

type Maintenance = {
    name: string;
    start: string;
    status: MaintenanceStatus;
    duration: string;
    url: string;
};
export const StatusButton = () => {
    const theme = useTheme();

    const [status, setStatus] = useState<Status | null>(Status.UP);
    const [incidents, setIncidents] = useState<Incident[] | null>(null);
    const [maintenances, setMaintenances] = useState<Maintenance[] | null>(
        null
    );

    const initialize = useCallback(async () => {
        try {
            const { activeIncidents, activeMaintenances, page } = await fetch(
                "https://ostium.instatus.com/summary.json"
            ).then((res) => res.json());

            setStatus(page.status);
            setIncidents(activeIncidents);
            setMaintenances(activeMaintenances);
        } catch (err) {
            console.warn(err);
        }
    }, []);

    useEffect(() => {
        initialize();

        const interval = setInterval(() => {
            initialize();
        }, 10000);
        return () => clearInterval(interval);
    }, [initialize]);

    const statusProps = useMemo(() => {
        if (incidents?.length || maintenances?.length) {
            return {
                color: theme.color.yellow,
                text: "Offline",
                description: "Some services are experiencing issues",
            };
        }
        switch (status) {
            case Status.UP:
                return {
                    color: theme.color.green,
                    text: "Online",
                    description: "All services are operational",
                };
            case Status.HASISSUES:
                return {
                    color: theme.color.yellow,
                    text: "Offline",
                    description: "Some services are experiencing issues",
                };
            case Status.UNDERMAINTENANCE:
                return {
                    color: theme.color.red,
                    text: "Under maintenance",
                    description: "Some services are under maintenance",
                };
            default:
                return {
                    text: "Unknown status",
                    color: theme.color.rgba(theme.color.white, 0.7),
                    description: "Unknown status",
                };
        }
    }, [incidents?.length, maintenances?.length, status, theme.color]);

    const onExternal = () => {
        Linking.openURL("https://ostium.instatus.com/");
    };

    const renderTooltipContent = useMemo(() => {
        return (
            <Box
                style={{
                    marginHorizontal: -theme.spacing.big,
                    marginBottom: -theme.spacing.big,
                }}
            >
                <Flex
                    justify="space-between"
                    style={{
                        marginTop: -theme.spacing.big,
                        padding: theme.spacing.small,
                        backgroundColor: theme.color.rgba(
                            theme.color.white,
                            0.1
                        ),
                    }}
                >
                    <Box>
                        <Text
                            smaller
                            color={theme.color.rgba(theme.color.white, 0.7)}
                        >
                            {statusProps.description}
                        </Text>
                    </Box>
                    <Box>
                        <AnimatedIcon icon="external" onPress={onExternal} />
                    </Box>
                </Flex>
                {incidents?.length ? (
                    <Box gap={theme.spacing.smallest}>
                        {incidents.map((incident) => (
                            <IncidentItem
                                item={incident}
                                key={`maintenance-${incident.name}`}
                            />
                        ))}
                    </Box>
                ) : null}
                {maintenances?.length ? (
                    <Box gap={theme.spacing.smallest}>
                        {maintenances.map((maintenance) => (
                            <MaintenanceItem
                                item={maintenance}
                                key={`maintenance-${maintenance.name}`}
                            />
                        ))}
                    </Box>
                ) : null}
            </Box>
        );
    }, [
        incidents,
        maintenances,
        statusProps.description,
        theme.color,
        theme.spacing.big,
        theme.spacing.small,
        theme.spacing.smallest,
    ]);

    return (
        <Pressable onPress={onExternal}>
            <Tooltip
                renderContent={renderTooltipContent}
                style={{
                    paddingVertical: theme.spacing.smaller,
                }}
                minWidth
            >
                <Flex align="center" gap={theme.spacing.smallest}>
                    <Flex
                        style={{
                            backgroundColor: theme.color.rgba(
                                theme.color.green,
                                0.2
                            ),
                            height: 24,
                            paddingHorizontal: theme.spacing.smaller,
                            borderRadius: theme.radius.medium,
                            justifyContent: "center",
                            alignItems: "center",
                            gap: theme.spacing.smallest,
                        }}
                    >
                        <Box
                            align="center"
                            justify="center"
                            style={{
                                borderRadius: 100,
                                width: 8,
                                height: 8,
                            }}
                            color={statusProps.color}
                        />
                        <Text smallest green semiBold>
                            {statusProps.text}
                        </Text>
                    </Flex>
                </Flex>
            </Tooltip>
        </Pressable>
    );
};

const MaintenanceItem = ({ item }: { item: Maintenance }) => {
    const theme = useTheme();

    const [isHovered, setIsHovered] = useState(false);

    const duration = new Date(
        new Date(item.start).setHours(
            new Date(item.start).getHours() + Number(item.duration)
        )
    );

    const statusColor = useMemo(() => {
        switch (item.status) {
            case MaintenanceStatus.NOTSTARTEDYET:
                return theme.color.yellow;
            case MaintenanceStatus.INPROGRESS:
                return theme.color.red;
            case MaintenanceStatus.COMPLETED:
                return theme.color.green;
            default:
                return theme.color.white;
        }
    }, [
        item.status,
        theme.color.green,
        theme.color.red,
        theme.color.white,
        theme.color.yellow,
    ]);

    return (
        <Pressable onPress={() => Linking.openURL(item.url)}>
            <Box
                gap={theme.spacing.small}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{
                    backgroundColor: theme.color.rgba(
                        theme.color.white,
                        isHovered ? 0.1 : 0
                    ),
                    padding: theme.spacing.small,
                    borderTopWidth: 1,
                    borderColor: theme.color.rgba(theme.color.white, 0.1),
                }}
            >
                <Flex justify="space-between">
                    <Box gap={theme.spacing.small} flex={1}>
                        <Text
                            smaller
                            bold
                            style={{
                                lineHeight: theme.text.medium,
                            }}
                        >
                            {item.name}
                        </Text>
                        <Text tiny color={statusColor} bold>
                            {item.status}
                        </Text>
                    </Box>
                    <Box>
                        <Pill text="Maintenance" />
                    </Box>
                </Flex>
                <Box gap={theme.spacing.tiny}>
                    <Text tiny color={theme.color.rgba(theme.color.white, 0.7)}>
                        Starts: {formatDate(item.start)}
                    </Text>
                    <Text tiny color={theme.color.rgba(theme.color.white, 0.7)}>
                        Ends: {formatDate(duration)}
                    </Text>
                </Box>
            </Box>
        </Pressable>
    );
};

const IncidentItem = ({ item }: { item: Incident }) => {
    const theme = useTheme();

    const [isHovered, setIsHovered] = useState(false);

    const statusColor = useMemo(() => {
        switch (item.status) {
            case IncidentStatus.INVESTIGATING:
                return theme.color.yellow;
            case IncidentStatus.IDENTIFIED:
                return theme.color.red;
            case IncidentStatus.MONITORING:
                return theme.color.green;
            case IncidentStatus.RESOLVED:
                return theme.color.green;
            default:
                return theme.color.white;
        }
    }, [
        item.status,
        theme.color.green,
        theme.color.red,
        theme.color.white,
        theme.color.yellow,
    ]);

    const impactColor = useMemo(() => {
        switch (item.impact) {
            case Impact.OPERATIONAL:
                return theme.color.green;
            case Impact.PARTIALOUTAGE:
                return theme.color.yellow;
            case Impact.MINOROUTAGE:
                return theme.color.yellow;
            case Impact.MAJOROUTAGE:
                return theme.color.red;
            case Impact.DEGRADEDPERFORMANCE:
                return theme.color.yellow;
            case Impact.UNDERMAINTENANCE:
                return theme.color.yellow;
            default:
                return theme.color.white;
        }
    }, [
        item.impact,
        theme.color.green,
        theme.color.red,
        theme.color.white,
        theme.color.yellow,
    ]);

    return (
        <Pressable onPress={() => Linking.openURL(item.url)}>
            <Box
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                gap={theme.spacing.small}
                style={{
                    padding: theme.spacing.small,
                    borderTopWidth: 1,
                    borderColor: theme.color.rgba(theme.color.white, 0.1),
                    backgroundColor: theme.color.rgba(
                        theme.color.white,
                        isHovered ? 0.1 : 0
                    ),
                }}
            >
                <Flex justify="space-between">
                    <Box gap={theme.spacing.small} flex={1}>
                        <Text smaller bold lineHeight={theme.text.medium}>
                            {item.name}
                        </Text>
                        <Text tiny color={statusColor} bold>
                            <Text tiny color={impactColor} bold>
                                {item.impact}
                            </Text>
                            <Text tiny white>
                                {` - `}
                            </Text>
                            {item.status}
                        </Text>
                    </Box>
                    <Box>
                        <Pill text="Incident" />
                    </Box>
                </Flex>
                <Box gap={theme.spacing.tiny}>
                    <Text tiny color={theme.color.rgba(theme.color.white, 0.7)}>
                        Started: {formatDate(item.started)}
                    </Text>
                </Box>
            </Box>
        </Pressable>
    );
};
