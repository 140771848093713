import React, { useContext, useEffect } from "react";
import mixpanel from "mixpanel-browser";

export default function useAnalytics() {
    const trackEvent = (eventName: string, data = {}) => {
        mixpanel.track(eventName, data);
    };

    const identifyUser = (walletAddress: string) => {
        mixpanel.identify(walletAddress);
        mixpanel.people.set({ $name: walletAddress });
    };

    return { trackEvent, identifyUser };
}
