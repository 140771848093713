import { ChartDataset, ChartOptions, ScriptableContext } from "chart.js";
import dynamic from "next/dynamic";
import { rgba } from "polished";

export const Chart = dynamic(() => import("./Chart"), {
    ssr: false,
});

export const createDataset = (
    label: string,
    data: number[],
    color: string,
    options?: ChartOptions
) => {
    return {
        data,
        label,
        type: "line",
        borderWidth: 1.5,
        pointBorderWidth: 0,
        pointBackgroundColor: color,
        pointRadius: data.map((point) => (point === 0 ? 0 : 3)),
        borderColor: color,
        pointHoverBackgroundColor: color,
        pointHoverRadius: 5,
        hoverBackgroundColor: color,
        fill: true,

        backgroundColor: (context: ScriptableContext<"line">) => {
            const ctx = context.chart.ctx;
            const gradient = ctx.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, rgba(color, 0.4));
            gradient.addColorStop(1, rgba(0, 0, 0, 0));
            return gradient;
        },
        ...options,
    } as ChartDataset<"line">;
};
