import AsyncStorage from "@react-native-async-storage/async-storage";
import { useCallback } from "react";

export const useLocalStorage = () => {
    const saveKey = useCallback(async (key: string, item: any) => {
        try {
            await AsyncStorage.setItem(key, JSON.stringify(item));
        } catch (e) {
            // saving error
        }
    }, []);

    const getKey = useCallback(async (key: string) => {
        try {
            const value = await AsyncStorage.getItem(key);
            // value previously stored
            if (value !== null) return JSON.parse(value);
        } catch (e) {
            // error reading value
        }
        return null;
    }, []);

    const deleteKey = useCallback(async (key: string) => {
        try {
            await AsyncStorage.removeItem(key);
        } catch (e) {
            // error deleting value
        }
    }, []);

    const wipeApplicationData = () => {
        AsyncStorage.clear();
    };

    return { saveKey, getKey, deleteKey, wipeApplicationData };
};
