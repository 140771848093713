import { Box } from "@atoms/Flex";
import { useAppContext } from "@contexts/app";
import { CTA_HEIGHT, HEADER_HEIGHT } from "@screens/components/Header";

export const StaticLayout = ({
    children,
}: {
    children: JSX.Element | JSX.Element[];
}) => {
    const { isCTAActive } = useAppContext();
    return (
        <Box
            style={{
                alignItems: "center",
                paddingTop: HEADER_HEIGHT + (isCTAActive ? CTA_HEIGHT : 0),
            }}
        >
            <Box
                style={{
                    width: "100%",
                    // maxWidth: 980,
                }}
            >
                {children}
            </Box>
        </Box>
    );
};
