import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { SelectOptionType } from "@molecules/Select";
import {
    CurrentTotalProfitP,
    CurrentTotalProfitRaw,
    CurrentTradeProfitP,
    CurrentTradeProfitRaw,
    GetCurrentRolloverFee,
    GetFundingRate,
    GetOpeningFee,
    GetPriceImpact,
    GetTradeFundingFee,
    GetTradeLiquidationPrice,
    GetTradeRolloverFee,
    PRECISION_12,
    PRECISION_18,
    PRECISION_2,
} from "@ostium/formulae/src";
import { BigNumber, formatPrice } from "@utils";
import { Pair, Trade, UserGroupStat } from "gql/graphql";
import { useTheme } from "styled-components/native";
import { formatUnits, parseUnits } from "viem";
import { IMark } from "../../theme/components/molecules/Slider";

export enum AssetClass {
    Crypto = "Crypto",
    Commodity = "Commodity",
    Forex = "Forex",
}

export enum Operation {
    All = "All",
    Cancelled = "Cancelled",
    Open = "Open",
    Close = "Close",
    Liquidation = "Liquidation",
    TakeProfit = "TakeProfit",
    StopLoss = "StopLoss",
}

export enum Timeframe {
    Hourly = "Hourly",
    Daily = "Daily",
    Weekly = "Weekly",
    Monthly = "Monthly",
    Yearly = "Yearly",
    All = "All",
}

export enum PositionTypes {
    Long = "Long",
    Short = "Short",
}

export enum PositionOrderTypes {
    Market = "Market",
    Limit = "Limit",
    Stop = "Stop",
}

type RangeType = {
    time: "1H" | "1D" | "1W" | "1M";
    text: string;
};

export const POSITION_TYPES: PositionTypes[] = [
    PositionTypes.Long,
    PositionTypes.Short,
];

export const ORDER_TYPES: PositionOrderTypes[] = [
    PositionOrderTypes.Market,
    PositionOrderTypes.Limit,
    PositionOrderTypes.Stop,
];

export const RANGES: RangeType[] = [
    {
        time: "1H",
        text: "1h",
    },
    {
        time: "1D",
        text: "1d",
    },
    {
        time: "1M",
        text: "1mo",
    },
];

export const CANDLE_RANGES: RangeType[] = [
    {
        time: "1H",
        text: "5s",
    },
    {
        time: "1D",
        text: "1m",
    },
    {
        time: "1M",
        text: "1h",
    },
];

export const TIMEFRAME = [
    {
        text: "Last hour",
        value: Timeframe.Hourly,
    },
    {
        text: "Last day",
        value: Timeframe.Daily,
    },
    {
        text: "Last week",
        value: Timeframe.Weekly,
    },
    {
        text: "Alltime",
        value: Timeframe.All,
    },
];

export type TimeframeType = {
    id: Timeframe;
    text: string;
    long: string;
    period: number;
};

export const TIMEFRAMES: SelectOptionType[] = [
    {
        value: Timeframe.Hourly,
        text: "1 hour",
        // period: 60,
    },
    {
        value: Timeframe.Daily,
        text: "1 day",
        // period: 24,
    },
    {
        value: Timeframe.Weekly,
        text: "1 week",
        // period: 7,
    },
    {
        value: Timeframe.Monthly,
        text: "1 month",
        // period: 31,
    },
    {
        value: Timeframe.All,
        text: "All time",
        // period: 90,
    },
];

export const TAKE_PROFIT_MARKS: IMark[] = [
    {
        value: 25,
        text: "25%",
    },
    {
        value: 50,
        text: "50%",
    },
    {
        value: 75,
        text: "75%",
    },
    {
        value: 100,
        text: "100%",
    },
    {
        value: 500,
        text: "500%",
    },
    {
        value: 900,
        text: "900%",
    },
];

export const STOP_LOSS_MARKS: IMark[] = [
    {
        value: 0,
        text: "None",
    },
    {
        value: -5,
        text: "-5%",
    },
    {
        value: -10,
        text: "-10%",
    },
    {
        value: -25,
        text: "-25%",
    },
    {
        value: -50,
        text: "-50%",
    },
    {
        value: -85,
        text: "-85%",
    },
];

export function getAssetClassDecimals(assetClass: string) {
    switch (assetClass) {
        case AssetClass.Commodity:
            return 2;
        case AssetClass.Crypto:
            return 4;
        case AssetClass.Forex:
            return 4;
        default:
            return 2;
    }
}

export const LEVERAGE_MARKS: { [key: string]: IMark[] } = {
    tinyiest: [
        {
            text: "1x",
            value: 1,
        },
        {
            text: "2.5x",
            value: 2.5,
        },
        {
            text: "5x",
            value: 5,
        },
        {
            text: "10x",
            value: 10,
        },
        {
            text: "15x",
            value: 15,
        },
        {
            text: "20x",
            value: 20,
        },
    ],
    tiny: [
        {
            text: "1x",
            value: 1,
        },
        {
            text: "10x",
            value: 10,
        },
        {
            text: "20x",
            value: 20,
        },
        {
            text: "30x",
            value: 30,
        },
        {
            text: "40x",
            value: 40,
        },
        {
            text: "50x",
            value: 50,
        },
    ],
    small: [
        {
            text: "1x",
            value: 1,
        },
        {
            text: "10x",
            value: 10,
        },
        {
            text: "25x",
            value: 25,
        },
        {
            text: "50x",
            value: 50,
        },
        {
            text: "75x",
            value: 75,
        },
        {
            text: "100x",
            value: 100,
        },
    ],
    medium: [
        {
            text: "1x",
            value: 1,
        },
        {
            text: "25x",
            value: 25,
        },
        {
            text: "50x",
            value: 50,
        },
        {
            text: "100x",
            value: 100,
        },
        {
            text: "125x",
            value: 125,
        },
        {
            text: "150x",
            value: 150,
        },
    ],
    big: [
        {
            text: "1x",
            value: 1,
        },
        {
            text: "50x",
            value: 50,
        },
        {
            text: "100x",
            value: 100,
        },
        {
            text: "150x",
            value: 150,
        },
        {
            text: "200x",
            value: 200,
        },
        {
            text: "250x",
            value: 250,
        },
    ],
    biggest: [
        {
            text: "1x",
            value: 1,
        },
        {
            text: "50x",
            value: 50,
        },
        {
            text: "100x",
            value: 100,
        },
        {
            text: "150x",
            value: 150,
        },
        {
            text: "250x",
            value: 250,
        },
        {
            text: "500x",
            value: 500,
        },
    ],
    huge: [
        {
            text: "1x",
            value: 1,
        },
        {
            text: "100x",
            value: 100,
        },
        {
            text: "250x",
            value: 250,
        },
        {
            text: "500x",
            value: 500,
        },
        {
            text: "750x",
            value: 750,
        },
        {
            text: "1000x",
            value: 1000,
        },
    ],
};

type FeeType = {
    label: string;
    value: number;
};

export type Fees = {
    value: number;
    label: string;
    description: string;
    breakdown: {
        label: string;
        description?: string;
        value: number;
        breakdown?: FeeType[];
        render?: JSX.Element;
    }[];
};

export const getFees = ({
    collateral,
    leverage,
    pair,
    price,
    type,
}: {
    collateral: number;
    leverage: number;
    price: number;
    type: PositionTypes;
    pair?: Pair;
}) => {
    if (!!!pair)
        return {
            label: "Total Fees",
            description: `Total fees at open, including protocol opening fees and oracle price retrieval fee`,
            value: 0,
            breakdown: [
                {
                    label: "Oracle Fee",
                    description: `Fees to cover cost of keeper automations and oracle price retrieval`,
                    value: 0,
                },
                {
                    label: "Opening Fee",
                    render: (
                        <OpeningFeeTooltipContent
                            takerFee={0}
                            makerFee={0}
                            usageFee={0}
                            makerFeePercent={0}
                            takerFeePercent={0}
                            usageFeePercent={0}
                        />
                    ),
                    value: 0,
                    breakdown: [],
                },
            ],
        };

    const oracleFee = pair?.fee?.oracleFee
        ? Number(formatUnits(pair?.fee?.oracleFee, 6))
        : 0;

    const positionSizeNotional = BigNumber.from(
        parseUnits(collateral?.toString(), 6)
    )
        .mul(parseUnits(leverage?.toString(), 2))
        .div(PRECISION_2)
        .toString();

    const oiLong = BigNumber.from(pair?.longOI)
        .mul(BigNumber.from(parseUnits(price.toFixed(18), 18)))
        .div(BigNumber.from(PRECISION_18))
        .div(PRECISION_12)
        .toString();

    const oiShort = BigNumber.from(pair?.shortOI)
        .mul(BigNumber.from(parseUnits(price.toFixed(18), 18)))
        .div(BigNumber.from(PRECISION_18))
        .div(PRECISION_12)
        .toString();

    const openingFee =
        oiLong && oiShort && Number(pair?.maxOI)
            ? GetOpeningFee(
                  oiLong,
                  oiShort,
                  pair?.maxOI,
                  pair?.makerFeeP,
                  pair?.takerFeeP,
                  pair?.usageFeeP,
                  pair?.makerMaxLeverage,
                  type === PositionTypes.Long
                      ? positionSizeNotional
                      : BigNumber.from(positionSizeNotional)
                            .mul(-1)
                            ?.toString(),
                  parseUnits(leverage?.toString(), 2)?.toString(),
                  pair?.utilizationThresholdP,
                  type === PositionTypes.Long
              )
            : {
                  baseFee: "0",
                  makerAmount: "0",
                  takerAmount: "0",
                  utilizationFee: "0",
              };

    const takerFeePercent = pair?.takerFeeP
        ? Number(formatUnits(pair?.takerFeeP, 6))
        : 0;
    const makerFeePercent = pair?.makerFeeP
        ? Number(formatUnits(pair?.makerFeeP, 6))
        : 0;
    const usageFeePercent = pair?.usageFeeP
        ? Number(formatUnits(pair?.usageFeeP, 6))
        : 0;

    const takerFee =
        (Number(formatUnits(BigInt(openingFee?.takerAmount), 6)) *
            takerFeePercent) /
        100;

    const makerFee =
        (Number(formatUnits(BigInt(openingFee.makerAmount), 6)) *
            makerFeePercent) /
        100;

    const usageFee =
        openingFee && openingFee?.utilizationFee
            ? Number(formatUnits(BigInt(openingFee.utilizationFee), 6))
            : 0;

    const openFee =
        Number(formatUnits(BigInt(openingFee?.baseFee), 6)) + usageFee;

    // console.warn(openingFee);

    const totalFees = oracleFee + openFee;

    let openingFeesBreakdown: FeeType[] = [];

    if (makerFee) {
        openingFeesBreakdown = [
            {
                label: "Maker Fee",
                value: makerFee,
            },
            ...openingFeesBreakdown,
        ];
    }
    if (takerFee) {
        openingFeesBreakdown = [
            {
                label: "Taker Fee",
                value: takerFee,
            },
            ...openingFeesBreakdown,
        ];
    }
    if (usageFee) {
        openingFeesBreakdown = [
            {
                label: "Usage Fee",
                value: usageFee,
            },
            ...openingFeesBreakdown,
        ];
    }

    return {
        label: "Total Fees",
        description: `Total fees at open, including protocol opening fees and oracle price retrieval fee`,
        value: totalFees,
        breakdown: [
            {
                label: "Oracle Fee",
                description: `Fees to cover cost of keeper automations and oracle price retrieval`,
                value: oracleFee,
            },
            {
                label: "Opening Fee",
                render: (
                    <OpeningFeeTooltipContent
                        takerFee={takerFee}
                        makerFee={makerFee}
                        usageFee={usageFee}
                        makerFeePercent={makerFeePercent}
                        takerFeePercent={takerFeePercent}
                        usageFeePercent={usageFeePercent}
                    />
                ),
                value: openFee,
                breakdown: openingFeesBreakdown,
            },
        ],
    };
};

const OpeningFeeTooltipContent = ({
    makerFee,
    takerFee,
    usageFee,
    takerFeePercent,
    makerFeePercent,
    usageFeePercent,
}: {
    takerFeePercent: number;
    makerFeePercent: number;
    usageFeePercent: number;
    takerFee: number;
    makerFee: number;
    usageFee: number;
}) => {
    const theme = useTheme();

    const list = [
        {
            label: "Maker Fee",
            value: makerFee,
            percent: makerFeePercent,
            description:
                "Applies to orders that decrease skew and are smaller than 20x leverage.",
        },
        {
            label: "Taker Fee",
            value: takerFee,
            percent: takerFeePercent,
            description: `Varies as a function of asset volatility.\nApplies to orders that increase skew and/or are bigger than 20x leverage.`,
        },
        {
            label: "Usage Fee",
            value: usageFee,
            percent: usageFeePercent,
            description:
                "Applies to taker orders above utilization threshold (currently set at 80% of available Open Interest).",
        },
    ];

    return (
        <Box gap={theme.spacing.bigger}>
            <Box
                style={{
                    paddingVertical: theme.spacing.big,
                    borderBottomWidth: 1,
                    borderColor: theme.color.rgba(theme.color.white, 0.1),
                }}
            >
                <Text
                    smaller
                    color={theme.color.rgba(theme.color.white, 0.6)}
                    lineHeight={theme.text.medium}
                >
                    Protocol fees to reflect trade impact on pool skew and
                    utilization.
                </Text>
            </Box>

            <Box gap={theme.spacing.bigger}>
                {list.map((item) => {
                    return (
                        <Box
                            gap={theme.spacing.tiny}
                            key={`fees-list-${item.label}`}
                        >
                            <Flex justify="space-between" align="flex-end">
                                <Text smallest uppercase semiBold>
                                    {item.label} ({`${item.percent}%`})
                                </Text>
                                <Text
                                    smallest
                                    mono
                                    bold
                                    color={theme.color.rgba(
                                        theme.color.white,
                                        item.value ? 1 : 0.6
                                    )}
                                >
                                    {item.value
                                        ? `${formatPrice(item.value, {
                                              currency: true,
                                          })}`
                                        : "None"}
                                </Text>
                            </Flex>

                            <Text
                                smaller
                                color={theme.color.rgba(theme.color.white, 0.6)}
                                lineHeight={theme.text.medium}
                            >
                                {item.description}
                            </Text>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

export function getTradePNL(
    trade: Trade,
    marketPrice?: {
        mid: number;
        bid: number;
        ask: number;
    },
    blockNumber?: bigint
) {
    if (
        !!!trade ||
        !marketPrice?.mid ||
        !marketPrice?.bid ||
        !marketPrice?.ask ||
        !blockNumber
    )
        return {
            pnl: 0,
            pnlRaw: "0",
            pnlPercent: 0,
            rollover: 0,
            funding: 0,
            totalProfit: 0,
            netPNL: 0,
            netValue: 0,
            liquidationPrice: 0,
        };

    // Raw values
    const currentRolloverRaw = GetCurrentRolloverFee(
        trade?.pair?.accRollover,
        trade?.pair?.lastRolloverBlock,
        trade?.pair?.rolloverFeePerBlock,
        blockNumber?.toString()
    );

    const rolloverRaw = BigInt(
        GetTradeRolloverFee(
            trade.rollover,
            currentRolloverRaw,
            trade.collateral,
            trade.leverage
        )
    );

    const fundingRateRaw = GetFundingRate(
        trade.pair?.accFundingLong,
        trade.pair?.accFundingShort,
        trade.pair?.lastFundingRate,
        trade.pair?.lastFundingVelocity,
        trade.pair?.maxFundingFeePerBlock,
        trade.pair?.lastFundingBlock,
        blockNumber as unknown as string,
        trade.pair?.longOI,
        trade.pair?.shortOI
    );

    const fundingRaw = BigInt(
        GetTradeFundingFee(
            trade.funding,
            trade.isBuy
                ? fundingRateRaw.accFundingLong
                : fundingRateRaw.accFundingShort,
            trade.collateral,
            trade.leverage
        )
    );

    const liquidationPrice = Number(
        formatUnits(
            BigInt(
                GetTradeLiquidationPrice(
                    trade?.openPrice,
                    trade?.isBuy,
                    trade?.collateral,
                    trade?.leverage,
                    rolloverRaw?.toString(),
                    fundingRaw?.toString()
                )
            ),
            18
        )
    );

    const priceImpactRaw =
        marketPrice?.mid && marketPrice?.bid && marketPrice?.ask
            ? GetPriceImpact(
                  parseUnits(
                      marketPrice?.mid?.toString() || "1",
                      18
                  ).toString(),
                  parseUnits(
                      marketPrice?.bid?.toString() || "1",
                      18
                  ).toString(),
                  parseUnits(
                      marketPrice?.ask?.toString() || "1",
                      18
                  ).toString(),
                  trade?.pair?.spreadP,
                  false,
                  trade.isBuy,
                  true,
                  BigNumber.from(trade.collateral)
                      .mul(trade.leverage)
                      .div(PRECISION_2)
                      .toString(),
                  trade?.pair?.tradeSizeRef
              ).priceAfterImpact
            : parseUnits("1", 6)?.toString();

    const pnlRaw = BigInt(
        CurrentTradeProfitRaw(
            trade?.openPrice,
            priceImpactRaw,
            trade?.isBuy,
            trade?.leverage,
            trade?.collateral
        )
    );

    const totalProfitRaw = BigInt(
        CurrentTotalProfitRaw(
            trade?.openPrice,
            priceImpactRaw,
            trade?.isBuy,
            trade?.leverage,
            trade?.collateral,
            rolloverRaw?.toString(),
            fundingRaw?.toString()
        )
    );

    const pnlPercentRaw = BigInt(
        CurrentTotalProfitP(totalProfitRaw?.toString(), trade.collateral)
    );

    // const pnl = Number(formatUnits(pnlRaw, 6));
    /// const pnlPercent = Number(formatUnits(pnlPercentRaw, 6));
    // const netPNL = Number(formatUnits(totalProfitRaw, 6));

    const netValue =
        Number(formatUnits(totalProfitRaw, 6)) +
        Number(formatUnits(trade.collateral, 6));

    // Parsed values
    const pnl = Number(formatUnits(pnlRaw, 6));
    const pnlPercent = Number(formatUnits(pnlPercentRaw, 6));
    const netPNL = Number(formatUnits(totalProfitRaw, 6));
    const totalProfit = Number(formatUnits(totalProfitRaw, 6));
    const funding = Number(formatUnits(fundingRaw, 6));
    const rollover = Number(formatUnits(rolloverRaw, 6));

    return {
        pnl,
        pnlRaw,
        pnlPercent,
        rollover,
        funding,
        fundingRaw,
        rolloverRaw,
        totalProfit,
        netPNL,
        netValue,
        liquidationPrice,
        priceImpact: Number(formatUnits(BigInt(priceImpactRaw), 18)),
    };
}

export function getUserCollateralMetadata(
    trades: Trade[],
    userGroupStats: UserGroupStat[],
    type: string
) {
    let results = new Map(
        [
            {
                id: "commodities",
                color: "#D69A00",
                label: AssetClass.Commodity,
                total: 0,
            },
            {
                id: "crypto",
                color: "#005BDB",
                label: AssetClass.Crypto,
                total: 0,
            },
            {
                id: "forex",
                color: "#5CBAB4",
                label: AssetClass.Forex,
                total: 0,
            },
        ].map((item) => [item.id, item])
    );

    switch (type) {
        case "open":
            trades?.map((trade: Trade) => {
                const foundResult = results.get(trade.pair.group.name);
                if (foundResult?.id === trade.pair.group.name) {
                    results.set(trade.pair.group.name, {
                        ...foundResult,
                        total:
                            foundResult?.total +
                            Number(formatUnits(trade.collateral, 6)),
                    });
                }
            });
            results.forEach((value, key) => {
                if (!results?.get(key)?.total) {
                    results.delete(key);
                }
            });
            break;
        case "closed":
            userGroupStats.map((item: UserGroupStat) => {
                const foundResult = results.get(item.group.name);

                if (foundResult) {
                    results.set(item.group.name, {
                        ...foundResult,
                        total: Number(
                            formatUnits(
                                item.totalClosedCollateral?.toString(),
                                6
                            )
                        ),
                    });
                }
            });
            break;
        default: {
            trades?.map((trade: Trade) => {
                const foundResult = results.get(trade.pair.group.name);
                if (foundResult?.id === trade.pair.group.name) {
                    results.set(trade.pair.group.name, {
                        ...foundResult,
                        total:
                            foundResult?.total +
                            Number(formatUnits(trade.collateral, 6)),
                    });
                }
            });

            userGroupStats?.map((item: UserGroupStat) => {
                const foundResult = results.get(item.group.name);

                if (foundResult) {
                    results.set(item.group.name, {
                        ...foundResult,
                        total:
                            foundResult.total +
                            Number(
                                formatUnits(
                                    item.totalClosedCollateral?.toString(),
                                    6
                                )
                            ),
                    });
                }
            });

            break;
        }
    }

    return [...results.values()];
}
