import { Limit, LpAction, Order, Trade } from "gql/graphql";
import { IListColumn } from "../../../../theme/components/organisms/List/Column";
import { formatUnits, parseUnits } from "viem";
import { Sort } from "./Template/context";
import { getTradePNL } from "@screens/Trade/utils";
import { BigNumber } from "@utils";

export const SMALL_MIN_WIDTH = 70;
export const SMALL_MAX_WIDTH = 100;
export const MIN_WIDTH = 120;
export const MAX_WIDTH = 130;
export const BIG_MIN_WIDTH = 150;
export const BIG_MAX_WIDTH = 200;
export const LIST_ITEM_HEIGHT = 56;

export enum Column {
    RANK = "RANK",
    TIME = "TIME",
    TRADER = "TRADER",
    MARKET = "MARKET",
    COLLATERAL = "COLLATERAL",
    SIZE = "SIZE",
    OPERATION = "OPERATION",
    NET = "NET",
    FEE = "FEE",
    PRICE = "PRICE",
    MARKET_PRICE = "MARKET_PRICE",
    CLOSE_PRICE = "CLOSE_PRICE",
    PNL = "PNL",
    ACTION = "ACTION",
    EXPLORE = "EXPLORE",
    VOLUME = "VOLUME",
    TRADES = "TRADES",
    LIQUIDATIONS = "LIQUIDATIONS",
    GAIN_VS_LOSS = "GAIN_VS_LOSS",
    TOTAL_OPEN = "TOTAL_OPEN",
    SHARE = "SHARE",
    CREATED_ON = "CREATED_ON",
    DISCOUNT = "DISCOUNT",
    REDEEMABLE_ON = "REDEEMABLE_ON",
    AVAILABLE_FROM = "AVAILABLE_FROM",
    PREDICTION = "PREDICTION",
    PROBABILITY = "PROBABILITY",
    SIZENCOLLATERAL = "SIZENCOLLATERAL",
    STATE = "STATE",
    SHAREI = "SHAREI",
    BETACTION = "BETACTION",
}

export const COLUMNS: { [key in Column]: IListColumn } = {
    TIME: {
        text: "Time",
        value: Column.TIME,
        style: {
            maxWidth: SMALL_MIN_WIDTH,
            minWidth: SMALL_MIN_WIDTH,
        },
        hasSort: true,
        hasHide: true,
    },

    MARKET: {
        text: "Market & Side",
        value: Column.MARKET,
        hasSort: true,
        hasHide: true,
    },
    COLLATERAL: {
        text: "Collateral",
        value: Column.COLLATERAL,
        hasSort: true,
        hasHide: true,
    },
    SIZE: {
        text: "Size",
        value: Column.SIZE,
        hasHide: true,
        hasSort: true,
    },
    OPERATION: {
        text: "Operation & Type",
        value: Column.OPERATION,
        hasHide: true,
    },
    NET: {
        text: "Net Value",
        value: Column.NET,
        hasSort: true,
        hasHide: true,
    },
    FEE: {
        text: "Entry Fee",
        value: Column.FEE,
        hasHide: true,
    },
    PRICE: {
        text: "Price",
        value: Column.PRICE,
        hasSort: true,
        hasHide: true,
    },
    MARKET_PRICE: {
        text: "Market Price",
        value: Column.MARKET_PRICE,
        hasHide: true,
    },
    CLOSE_PRICE: {
        text: "Close Price",
        value: Column.CLOSE_PRICE,
        hasSort: false,
        hasHide: true,
    },
    PNL: {
        text: "Realised PNL",
        value: Column.PNL,
        hasSort: true,
        style: {
            flex: 1,
            maxWidth: undefined,
            alignItems: "flex-end",
        },
    },
    ACTION: {
        text: "Action",
        value: Column.ACTION,
        style: {
            alignItems: "flex-end",
            maxWidth: 90,
            minWidth: 90,
        },
    },
    EXPLORE: {
        text: "Explore",
        value: Column.EXPLORE,
    },
    // TRADER COLUMNS
    RANK: {
        text: "Rank",
        value: Column.RANK,
        style: {
            maxWidth: SMALL_MIN_WIDTH / 1.2,
            minWidth: SMALL_MIN_WIDTH / 1.2,
        },
    },
    TRADER: {
        text: "Trader",
        value: Column.TRADER,
        style: {
            minWidth: 130,
        },
    },
    TRADES: {
        text: "Trades",
        value: Column.TRADES,
    },
    VOLUME: {
        text: "Volume",
        value: Column.VOLUME,
    },
    LIQUIDATIONS: {
        text: "Liquidations",
        value: Column.LIQUIDATIONS,
    },
    GAIN_VS_LOSS: {
        text: "Gain vs Loss",
        value: Column.GAIN_VS_LOSS,
    },
    TOTAL_OPEN: {
        text: "Open Trades",
        value: Column.TOTAL_OPEN,
    },
    // LP COLUMNS
    SHARE: {
        text: "Shares",
        value: Column.SHARE,
    },
    CREATED_ON: {
        text: "Created on",
        value: Column.CREATED_ON,
    },
    DISCOUNT: {
        text: "Discount",
        value: Column.DISCOUNT,
    },
    REDEEMABLE_ON: {
        text: "Redeemable on",
        value: Column.REDEEMABLE_ON,
    },
    AVAILABLE_FROM: {
        text: "Available from",
        value: Column.AVAILABLE_FROM,
    },
    PREDICTION: {
        text: "Prediction",
        value: Column.PREDICTION,
    },
    PROBABILITY: {
        text: "Probability",
        value: Column.PROBABILITY,
    },
    STATE: {
        text: "State",
        value: Column.STATE,
    },
    SIZENCOLLATERAL: {
        text: "Size & Collateral",
        value: Column.SIZENCOLLATERAL,
    },
    SHAREI: {
        text: "Share",
        value: Column.SHAREI,
    },
    BETACTION: {
        text: "Action",
        value: Column.BETACTION,
        style: { maxWidth: 90, minWidth: 90 },
    },
};

export const HISTORY_COLUMNS: IListColumn[] = [
    COLUMNS.TIME,
    COLUMNS.MARKET,
    COLUMNS.SIZE,
    COLUMNS.COLLATERAL,
    COLUMNS.OPERATION,
    COLUMNS.PRICE,
    COLUMNS.PNL,
];

export const ORDER_COLUMNS: IListColumn[] = [
    COLUMNS.TIME,
    COLUMNS.MARKET,
    COLUMNS.SIZE,
    COLUMNS.COLLATERAL,
    COLUMNS.OPERATION,
    { ...COLUMNS.PRICE, text: "Entry Price" },
    COLUMNS.MARKET_PRICE,
    COLUMNS.FEE,
    COLUMNS.CLOSE_PRICE,
    {
        ...COLUMNS.ACTION,
        style: {
            ...COLUMNS.ACTION.style,
            flex: 1,
            maxWidth: undefined,
            alignItems: "flex-end",
        },
    },
];

export const TRADE_COLUMNS: IListColumn[] = [
    { ...COLUMNS.TIME, hasSort: true, hasHide: true },
    COLUMNS.MARKET,
    COLUMNS.SIZE,
    COLUMNS.COLLATERAL,
    COLUMNS.NET,
    { ...COLUMNS.PRICE, text: "Entry Price" },
    COLUMNS.MARKET_PRICE,
    COLUMNS.CLOSE_PRICE,
    {
        ...COLUMNS.PNL,
        text: "Unrealised PNL",
        style: COLUMNS.PNL.style,
    },
    COLUMNS.ACTION,
];

export const USER_RULES_COLUMNS = (isMobile = false): IListColumn[] => (isMobile ?
    [
        {
            text: "Market & Side",
            value: Column.MARKET,
        },
        COLUMNS.STATE,
        COLUMNS.SHAREI,
    ] : [
        COLUMNS.TIME,
        COLUMNS.PREDICTION,
        COLUMNS.PROBABILITY,
        {
            text: "Market & Side",
            value: Column.MARKET,
        },
        COLUMNS.COLLATERAL,
        COLUMNS.STATE,
        COLUMNS.BETACTION,
        COLUMNS.SHAREI,
    ]);

export const DEFAULT_COLUMNS = {
    TRADES: TRADE_COLUMNS,
    ORDERS: ORDER_COLUMNS,
    HISTORY: HISTORY_COLUMNS,
};

export const sortTrades = (
    trades: Trade[],
    sort: Sort,
    prices: Map<
        string,
        {
            mid: number;
            bid: number;
            ask: number;
        }
    >,
    blockNumber?: bigint
) => {
    if (!sort?.by || !!!prices) return trades;

    try {
        return trades.sort((a, b) => {
            const priceA = parseUnits(
                prices?.get(a?.pair.from)?.mid?.toString() || "1",
                18
            ).toString();
            const priceB = parseUnits(
                prices?.get(b?.pair?.from)?.mid?.toString() || "1",
                18
            ).toString();

            const tradeA = getTradePNL(
                a,
                prices?.get(a?.pair.from),
                blockNumber
            );
            const tradeB = getTradePNL(
                b,
                prices?.get(b?.pair.from),
                blockNumber
            );

            switch (sort.by) {
                case Column.TIME: {
                    if (sort.direction === "desc")
                        return a.timestamp < b.timestamp ? 1 : -1;
                    return a.timestamp > b.timestamp ? 1 : -1;
                }
                case Column.MARKET:
                    if (sort.direction === "desc")
                        return Number(a.leverage) < Number(b.leverage) ? 1 : -1;
                    return Number(a.leverage) > Number(b.leverage) ? 1 : -1;

                case Column.NET:
                    if (sort.direction === "desc")
                        return tradeA.netValue < tradeB.netValue ? 1 : -1;
                    return tradeA.netValue > tradeB.netValue ? 1 : -1;

                case Column.PNL: {
                    if (sort.direction === "desc")
                        return tradeA.netPNL < tradeB.netPNL ? 1 : -1;
                    return tradeA.netPNL > tradeB.netPNL ? 1 : -1;
                }

                case Column.PRICE: {
                    const openPriceA = Number(formatUnits(a.openPrice, 18));
                    const openPriceB = Number(formatUnits(b.openPrice, 18));
                    if (sort.direction === "desc")
                        return openPriceA < openPriceB ? 1 : -1;
                    return openPriceA > openPriceB ? 1 : -1;
                }

                case Column.SIZE:
                    const sizeA = priceA
                        ? Number(formatUnits(a?.tradeNotional, 18)) *
                          Number(formatUnits(BigInt(priceA), 18))
                        : 0;
                    const sizeB = priceB
                        ? Number(formatUnits(b?.tradeNotional, 18)) *
                          Number(formatUnits(BigInt(priceB), 18))
                        : 0;
                    if (sort.direction === "desc")
                        return sizeA < sizeB ? 1 : -1;
                    return sizeA > sizeB ? 1 : -1;
                case Column.COLLATERAL:
                    const aCollateral = Number(formatUnits(a.collateral, 6));
                    const bCollateral = Number(formatUnits(b.collateral, 6));
                    if (sort.direction === "desc")
                        return aCollateral < bCollateral ? 1 : -1;
                    return aCollateral > bCollateral ? 1 : -1;
                default:
                    return 0;
            }
        });
    } catch (error) {
        console.warn(error);
        return trades;
    }
};

export const sortDeposits = (deposits: LpAction[], sort: Sort) => {
    if (!sort?.by) return deposits;

    try {
        return deposits.sort((a, b) => {
            switch (sort.by) {
                case Column.TIME: {
                    if (sort.direction === "desc")
                        return a.timestamp < b.timestamp ? 1 : -1;
                    return a.timestamp > b.timestamp ? 1 : -1;
                }
                case Column.SHARE: {
                    if (sort.direction === "desc")
                        return a.shares < b.shares ? 1 : -1;
                    return a.shares > b.shares ? 1 : -1;
                }
                case Column.CREATED_ON:
                    if (sort.direction === "desc")
                        return a.epoch < b.epoch ? 1 : -1;
                    return a.epoch > b.epoch ? 1 : -1;

                default:
                    return 0;
            }
        });
    } catch (error) {
        console.warn(error);
        return deposits;
    }
};

export const sortOrders = (orders: Order[], sort: Sort) => {
    if (!sort?.by) return orders;
    try {
        return orders.sort((a, b) => {
            switch (sort.by) {
                case Column.COLLATERAL: {
                    const aCollateral = Number(formatUnits(a.collateral, 6));
                    const bCollateral = Number(formatUnits(b.collateral, 6));
                    if (sort.direction === "desc")
                        return aCollateral < bCollateral ? 1 : -1;
                    return aCollateral > bCollateral ? 1 : -1;
                }
                case Column.SIZE:
                    const sizeA = Number(formatUnits(a.notional, 6));
                    const sizeB = Number(formatUnits(b.notional, 6));

                    if (sort.direction === "desc")
                        return sizeA < sizeB ? 1 : -1;
                    return sizeA > sizeB ? 1 : -1;
                case Column.MARKET: {
                    if (sort.direction === "desc")
                        return Number(a.leverage) < Number(b.leverage) ? 1 : -1;
                    return Number(a.leverage) > Number(b.leverage) ? 1 : -1;
                }
                case Column.PRICE: {
                    const openPriceA = Number(formatUnits(a.price, 18));
                    const openPriceB = Number(formatUnits(b.price, 18));
                    if (sort.direction === "desc")
                        return openPriceA < openPriceB ? 1 : -1;
                    return openPriceA > openPriceB ? 1 : -1;
                }

                case Column.PNL: {
                    const profitA = a.amountSentToTrader
                        ? Number(
                              formatUnits(
                                  BigNumber.from(a.amountSentToTrader)
                                      .sub(BigNumber.from(a.collateral))
                                      .toBigInt(),
                                  18
                              )
                          )
                        : 0;

                    const profitB = b.amountSentToTrader
                        ? Number(
                              formatUnits(
                                  BigNumber.from(b.amountSentToTrader)
                                      .sub(BigNumber.from(b.collateral))
                                      .toBigInt(),
                                  18
                              )
                          )
                        : 0;
                    if (sort.direction === "desc")
                        return profitA < profitB ? 1 : -1;
                    return profitA > profitB ? 1 : -1;
                }
                case Column.TIME: {
                    if (sort.direction === "desc")
                        return a.initiatedAt < b.initiatedAt ? 1 : -1;
                    return a.initiatedAt > b.initiatedAt ? 1 : -1;
                }
                default:
                    return 0;
            }
        });
    } catch (error) {
        console.warn(error);
        return orders;
    }
};

export const sortLimits = (orders: (Limit | Order)[], sort: Sort) => {
    if (!sort?.by) return orders;
    try {
        return orders.sort((a, b) => {
            switch (sort.by) {
                case Column.COLLATERAL: {
                    const aCollateral = Number(formatUnits(a.collateral, 6));
                    const bCollateral = Number(formatUnits(b.collateral, 6));
                    if (sort.direction === "desc")
                        return aCollateral < bCollateral ? 1 : -1;
                    return aCollateral > bCollateral ? 1 : -1;
                }
                case Column.MARKET: {
                    if (sort.direction === "desc")
                        return Number(a.leverage) < Number(b.leverage) ? 1 : -1;
                    return Number(a.leverage) > Number(b.leverage) ? 1 : -1;
                }

                case Column.TIME: {
                    if (sort.direction === "desc")
                        return a.initiatedAt < b.initiatedAt ? 1 : -1;
                    return a.initiatedAt > b.initiatedAt ? 1 : -1;
                }
                case Column.SIZE:
                    // TODO: make this work with market orders that are stuck in the Orders list,
                    // const notionalA = Number(formatUnits(a.notional, 6));
                    // const priceA = Number(formatUnits(a.price, 6));
                    // const sizeA = notionalA && priceA ? notionalA / priceA : 0;

                    // const notionalB = Number(formatUnits(b.notional, 6));
                    // const priceB = Number(formatUnits(b.price, 6));
                    // const sizeB = notionalB && priceB ? notionalB / priceB : 0;

                    // TODO: this currently works for limits but not for market orders
                    const sizeA = Number(formatUnits(a.notional, 6));
                    const sizeB = Number(formatUnits(b.notional, 6));

                    if (sort.direction === "desc")
                        return sizeA < sizeB ? 1 : -1;
                    return sizeA > sizeB ? 1 : -1;
                default:
                    return 0;
            }
        });
    } catch (error) {
        console.warn(error);
        return orders;
    }
};
