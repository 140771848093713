import { Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { useWeb3Context } from "@contexts/web3";
import { Button } from "@molecules/Button";
import { useRouter } from 'next/router';
import { memo, useMemo } from "react";
import { useTheme } from "styled-components/native";

export const AppAlert = memo(() => {
    const { isOtherNetwork, switchChain } = useWeb3Context();
    const theme = useTheme();

    const router = useRouter();
    const isRestricted = router?.query?.restricted;

    const bannerText =  useMemo(() => {
        if (isOtherNetwork && isRestricted) {
            return `You cannot access our products from a restricted jurisdiction. Also, you need to switch to Arbitrum to use the app.`;
        } else if (isOtherNetwork) {
            return `Please switch to Arbitrum to use the app.`
        } else if (isRestricted) {
            return `You cannot access our products from a restricted jurisdiction.`
        } else return null;
    }, [isOtherNetwork, isRestricted]);

    if (bannerText) {
        return (
            <Flex
                align="center"
                justify="center"
                gap={theme.spacing.smallest}
                style={{
                    height: 25,
                    backgroundColor: theme.color.rgba(theme.color.red, 0.7),
                }}
            >
                <Text
                    smallest
                    semiBold
                    style={{ textAlign: "center" }}
                >
                    {bannerText}
                </Text>
                {isOtherNetwork && <Button
                    tiny
                    onPress={switchChain}
                    text="Switch Network"
                    white
                    style={{ height: 20 }}
                />}
            </Flex>
        );
    }
    return null;
});

