import React from "react";
import { Box } from "./Flex";
import { Button } from "@molecules/Button";
import { Text } from "./Text";
import { ViewStyle } from "react-native";

class ErrorBoundary extends React.Component {
    constructor(props: {
        children: JSX.Element;
        style: ViewStyle;
        onError: ({ error, errorInfo }: { error: any; errorInfo: any }) => void;
    }) {
        super(props);

        // Define a state variable to track whether is an error or not
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI

        return { hasError: true };
    }
    componentDidCatch(error: any, errorInfo: any) {
        // You can use your own error logging service here
        console.log({ error, errorInfo });
        // if (this?.props?.onError) {
        //     this?.props?.onError({ error, errorInfo });
        // }
    }
    render() {
        // @ts-ignore
        if (this?.state?.hasError) {
            // You can render any custom fallback UI
            return (
                <Box
                    align="center"
                    justify="center"
                    gap={10}
                    style={{
                        flex: 1,
                        // @ts-ignore
                        ...this.props.style,
                    }}
                >
                    <Text small>Oops, there is an error!</Text>
                    <Button
                        onPress={() => this.setState({ hasError: false })}
                        text="Try again?"
                        small
                        primary
                    />
                </Box>
            );
        }

        // @ts-ignore
        return this.props.children;
    }
}

export default ErrorBoundary;
