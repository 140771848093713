"use client";
import { Icon, IconInterface } from "@atoms/Icon";
import { Lottie } from "@atoms/Lottie/Lottie";
import { IText, Text } from "@atoms/Text";
import React, { memo, useState } from "react";
import { Pressable, View, ViewStyle } from "react-native";
import styled, { useTheme } from "styled-components/native";
import LOTTIE_SPINNER from "../../../../../assets/animations/infinity-white.json";

export interface IButton {
    text?: string;
    stroke?: boolean;
    primary?: boolean;
    white?: boolean;
    red?: boolean;
    black?: boolean;
    tiny?: boolean;
    small?: boolean;
    smallest?: boolean;
    big?: boolean;
    biggest?: boolean;
    round?: boolean;
    shadow?: boolean;
    disabled?: boolean;
    loading?: boolean;
    green?: boolean;
    style?: ViewStyle;
    isActive?: boolean;
    noBorder?: boolean;
    textProps?: IText;
    iconProps?: IconInterface;
    iconRight?: boolean;
    onPress?: () => void;
}
export const Button = memo(
    ({
        text = "",
        stroke,
        primary,
        white,
        black,
        green,
        small,
        smallest,
        big,
        biggest,
        red,
        shadow,
        disabled,
        loading,
        tiny,
        style,
        isActive,
        textProps,
        noBorder,
        iconProps,
        iconRight,
        onPress,
    }: IButton) => {
        const theme = useTheme();
        let backgroundColor = theme.color.rgba(theme.color.white, 0);
        let strokeColor = theme.color.rgba(red? theme.color.red : green ? theme.color.green : theme.color.white, 0.2);
        let fontColor = theme.color.white;
        let fontSize = theme.text.smallest;
        const [isHovering, setIsHovering] = useState(false);

        if (tiny) fontSize = theme.text.tiny;
        if (smallest) fontSize = theme.text.smallest;
        if (small) fontSize = theme.text.smaller;
        if (big) fontSize = theme.text.smaller;
        if (biggest) fontSize = theme.text.small;
        if (stroke) {
            backgroundColor = theme.color.rgba(theme.color.white, 0.1);
            fontColor = theme.color.rgba(theme.color.white, 0.6);
        }

        if (black) {
            backgroundColor = theme.color.black;
        }

        if (primary) {
            backgroundColor = theme.color.primary;
            fontColor = theme.color.black;
            strokeColor = theme.color.primary;
            if (stroke) {
                backgroundColor = theme.color.rgba(backgroundColor, 0.2);
                fontColor = theme.color.primary;
            }
        }

        if (red) {
            backgroundColor = theme.color.red;
            fontColor = theme.color.white;
            strokeColor = theme.color.red;

            if (stroke) {
                backgroundColor = theme.color.rgba(theme.color.red, 0.2);
                fontColor = theme.color.red;
            }
        }

        if (green) {
            backgroundColor = theme.color.green;
            fontColor = theme.color.white;
            strokeColor = theme.color.green;
            if (stroke) {
                backgroundColor = theme.color.rgba(theme.color.green, 0.2);
                fontColor = theme.color.green;
            }
        }

        if (white) {
            if (stroke) {
                backgroundColor = theme.color.rgba(theme.color.white, 0);
                strokeColor = theme.color.white;
            } else {
                fontColor = theme.color.black;
                backgroundColor = theme.color.white;
            }
        }

        if (isHovering) {
            if (primary || red || green) {
                fontColor = theme.color.white;
                // if (stroke)
                //     backgroundColor = theme.color.rgba(
                //         backgroundColor,
                //         stroke ? 1 : 0.2
                //     );
            } else if (white) {
                fontColor = theme.color.rgba(theme.color.black, 1);
            } else {
                backgroundColor = theme.color.rgba(
                    backgroundColor,
                    stroke ? 0.2 : 0.2
                );
                fontColor = theme.color.rgba(fontColor, 1);
            }
        }
        if (isActive) {
            backgroundColor = theme.color.rgba(backgroundColor, 1);
            fontColor = theme.color.rgba(theme.color.black, 1);
            if (primary || red || green) {
                fontColor = theme.color.rgba(theme.color.white, 1);
            }
            if (white) {
                fontColor = theme.color.rgba(theme.color.black, 1);
            }
        }

        const onPressButton = () => {
            if (disabled || loading) return;
            if (onPress) onPress();
        };

        return (
            <StyledButton
                smallest={smallest}
                small={small}
                tiny={tiny}
                big={big}
                biggest={biggest}
                onPress={onPressButton}
                // @ts-ignore
                onMouseEnter={() => {
                    if (disabled || loading) return;
                    setIsHovering(true);
                }}
                onMouseLeave={() => {
                    if (disabled) return;
                    setIsHovering(false);
                }}
                style={{
                    backgroundColor,
                    borderColor: strokeColor,
                    borderWidth: noBorder
                        ? 0
                        : stroke
                            ? big || biggest
                                ? 2
                                : 1
                            : 1,
                    flexDirection: iconRight ? "row-reverse" : "row",
                    opacity: disabled || loading ? 0.7 : 1,
                    gap: theme.spacing.smallest,
                    ...(shadow
                        ? {
                            shadowColor: strokeColor,
                            shadowOffset: { width: 0, height: 0 },
                            shadowOpacity: isHovering ? 0.5 : 0,
                            shadowRadius:
                                tiny || small
                                    ? theme.radius.small
                                    : theme.radius.medium,
                        }
                        : {}),
                    ...style,
                }}
            >
                {loading ? (
                    <Lottie
                        source={LOTTIE_SPINNER}
                        width={tiny || small ? 50 : 100}
                        height={tiny || small ? 26 : 50}
                    />
                ) : (
                    <>
                        {iconProps && (
                            <Icon
                                color={fontColor}
                                size={fontSize}
                                {...iconProps}
                            />
                        )}
                        {text && (
                            <Text
                                semiBold
                                selectable={false}
                                size={fontSize}
                                color={fontColor}
                                lineHeight={"120%"}
                                {...textProps}
                            >
                                {text}
                            </Text>
                        )}
                    </>
                )}
            </StyledButton>
        );
    }
);

export const StyledButton = styled(Pressable) <{
    small?: boolean;
    smallest?: boolean;
    big?: boolean;
    round?: boolean;
    tiny?: boolean;
}>`
    border-radius: ${({ round }: { round: boolean }) => (round ? 100 : 8)}px;
    justify-content: center;
    align-items: center;
    transition: 0.15s background-color linear;
    will-change: background-color;
    ${({
    big,
    small,
    smallest,
    tiny,
    biggest,
}: {
    big: boolean;
    biggest: boolean;
    small: boolean;
    smallest: boolean;
    tiny: boolean;
}) => {
        if (biggest) {
            return `
                height: 44px;
                min-width: 44px;
                padding: 0 20px;
            `;
        }
        if (big) {
            return `
				height: 40px;
				min-width: 40px;
				padding: 0 16px;
			`;
        }
        if (small) {
            return `
				height: 32px;
				min-width: 32px;
				padding: 0 12px;
			`;
        }
        if (smallest) {
            return `
				height: 28px;
				min-width: 28px;
				padding: 0 8px;
			`;
        }
        if (tiny) {
            return `
				height: 24px;
				min-width: 24px;
				padding: 0 4px;
			`;
        }

        return `
			height: 36px;
			min-width: 36px;
			padding: 0 20px;
		`;
    }}
`;
