import { Flex } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { Tooltip } from "@molecules/Tooltip";
import { capitalizeFirstLetter } from "@utils";
import { useState } from "react";
import { Linking, Pressable } from "react-native";
import Animated from "react-native-reanimated";
import { useTheme } from "styled-components/native";

export const Socials = ({ big }: { big?: boolean }) => {
    const onTwitter = () => {
        Linking.openURL("https://twitter.com/ostiumlabs");
    };
    const onDiscord = () => {
        Linking.openURL("https://discord.gg/44hxSkfy9a");
    };
    const onMedium = () => {
        Linking.openURL("https://www.ostium.io/blog");
    };

    return (
        <Flex>
            <SocialItem big={big} icon="discord" onPress={onDiscord} />
            <SocialItem big={big} icon="twitter" onPress={onTwitter} />
            <SocialItem big={big} icon="medium" onPress={onMedium} />
        </Flex>
    );
};

const SocialItem = ({
    icon,
    big,
    onPress,
}: {
    icon: string;
    big?: boolean;
    onPress: () => void;
}) => {
    const theme = useTheme();

    const [isHovering, setIsHovering] = useState(false);

    return (
        <Tooltip
            tiny
            position="top-right"
            content={capitalizeFirstLetter(icon)}
        >
            <Pressable onPress={onPress}>
                <Animated.View
                    // @ts-ignore
                    onMouseEnter={() => {
                        setIsHovering(true);
                    }}
                    onMouseLeave={() => {
                        setIsHovering(false);
                    }}
                    style={{ padding: theme.spacing.small }}
                >
                    <Icon
                        name={icon}
                        size={big ? 24 : 20}
                        primary={isHovering}
                        color={theme.color.rgba(
                            isHovering
                                ? theme.color.primary
                                : theme.color.white,
                            isHovering ? 1 : 0.6
                        )}
                    />
                </Animated.View>
            </Pressable>
        </Tooltip>
    );
};
