import { BlurView } from "@atoms/BlurView";
import { Box } from "@atoms/Flex";
import { Portal } from "@atoms/Portal";
import { Spacer } from "@atoms/Spacer";
import { IText, Text } from "@atoms/Text";
import { SIDEBAR_WIDTH } from "@constants";
import { useDimensions } from "@hooks/useDimensions";
import { useOutsideAlerter } from "@hooks/useOutsidePress";

import { memo, useEffect, useMemo, useRef, useState } from "react";
import { TextProps, ViewStyle } from "react-native";
import Animated, { useAnimatedStyle } from "react-native-reanimated";
import { useTheme } from "styled-components/native";

export interface ITooltip {
    title?: string;
    content?: string;
    position?:
        | "top-left"
        | "top-right"
        | "bottom-left"
        | "bottom-right"
        | "left-top"
        | "top-center"
        | "bottom-center"
        | "right-top";
    titleProps?: IText;
    top?: number;
    left?: number;
    bottom?: number;
    right?: number;
    contentProps?: IText;
    renderContent?: JSX.Element;
    children?: JSX.Element | JSX.Element[];
    textProps?: TextProps;
    minWidth?: boolean | number;
    pointerEvents?: "auto" | "none";
    tiny?: boolean;
    style?: ViewStyle;
    tooltipStyle?: ViewStyle;
    onToggleActive?: () => void;
    isActive?: boolean;
    disabled?: boolean;
    customPosition?: {
        top: number;
        left: number;
    };
}

export const Tooltip = memo(
    ({
        title,
        content,
        children,
        position,
        renderContent,
        contentProps,
        titleProps,
        top = 0,
        left = 0,
        right = 0,
        bottom = 0,
        isActive,
        tiny,
        style,
        tooltipStyle,
        minWidth,
        pointerEvents,
        disabled,
        onToggleActive,
        customPosition,
    }: ITooltip) => {
        const theme = useTheme();
        const [isHovered, setIsHovered] = useState(false);

        const wrapperRef = useRef<Element>(null);
        const tooltipRef = useRef<Element>(null);

        const onClose = () => {
            onToggleActive?.();
        };

        useOutsideAlerter(tooltipRef, onClose);

        let positionRef = useRef({
            wrapper: {
                left: 0,
                top: 0,
                width: 0,
                height: 0,
            },
            tooltip: {
                width: 0,
                height: 0,
            },
        });

        const onHover = () => {
            if (disabled) return;
            if (isActive != null) return;
            showTooltip();
        };

        const onUnhover = () => {
            if (disabled) return;
            if (isActive != null) return;
            hideTooltip();
        };

        const showTooltip = () => {
            positionRef.current = {
                tooltip: positionRef.current.tooltip,
                wrapper: {
                    left:
                        (wrapperRef?.current?.getBoundingClientRect().left ||
                            0) + window.scrollX,
                    top:
                        (wrapperRef?.current?.getBoundingClientRect().top ||
                            0) + window.scrollY,
                    width: wrapperRef?.current?.clientWidth || 0,
                    height: wrapperRef?.current?.clientHeight || 0,
                },
            };
            setIsHovered(true);
        };

        const hideTooltip = () => {
            setIsHovered(false);
            positionRef.current = {
                wrapper: {
                    left: 0,
                    top: 0,
                    width: 0,
                    height: 0,
                },
                tooltip: {
                    width: 0,
                    height: 0,
                },
            };
        };

        useEffect(() => {
            if (isActive) {
                showTooltip();
                return;
            }
            hideTooltip();
        }, [isActive]);

        const dimensions = useDimensions();

        const tooltipPosition = useMemo(() => {
            if (customPosition) {
                return {
                    left: customPosition.left,
                    top: customPosition.top,
                };
            }
            if (isHovered) {
                const leftPosition = positionRef.current.wrapper.left;
                const topPosition = positionRef.current.wrapper.top;

                const positions = {
                    top: {
                        left: {
                            top:
                                positionRef.current.wrapper.top -
                                positionRef.current.tooltip.height,
                            left: leftPosition,
                            alignItems: "flex-start",
                            justifyContent: "flex-end",
                        },
                        center: {
                            top:
                                positionRef.current.wrapper.top -
                                positionRef.current.tooltip.height,
                            left:
                                leftPosition +
                                positionRef.current.wrapper.width / 2,
                            alignItems: "center",
                            justifyContent: "flex-end",
                        },
                        right: {
                            top:
                                positionRef.current.wrapper.top -
                                positionRef.current.tooltip.height,
                            left:
                                leftPosition +
                                positionRef.current.wrapper.width,
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                        },
                    },
                    left: {
                        top: {
                            top: positionRef.current.wrapper.top,
                            left: leftPosition,
                            alignItems: "flex-end",
                        },
                        bottom: {
                            top:
                                positionRef.current.wrapper.top +
                                positionRef.current.wrapper.height,
                            left: leftPosition,
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                        },
                    },
                    right: {
                        top: {
                            top: positionRef.current.wrapper.top,
                            left:
                                leftPosition +
                                positionRef.current.wrapper.width,
                            alignItems: "flex-start",
                        },
                        bottom: {
                            top:
                                positionRef.current.wrapper.top +
                                positionRef.current.wrapper.height,
                            left:
                                leftPosition +
                                positionRef.current.wrapper.width,
                            alignItems: "flex-start",
                            justifyContent: "flex-end",
                        },
                    },
                    bottom: {
                        center: {
                            top:
                                positionRef.current.wrapper.top +
                                positionRef.current.wrapper.height,
                            left:
                                leftPosition +
                                positionRef.current.wrapper.width / 2,
                            alignItems: "center",
                        },
                        right: {
                            top:
                                positionRef.current.wrapper.top +
                                positionRef.current.wrapper.height,
                            left:
                                leftPosition +
                                positionRef.current.wrapper.width,
                            alignItems: "flex-end",
                            justifyContent: "flex-start",
                        },
                        left: {
                            top:
                                positionRef.current.wrapper.top +
                                positionRef.current.wrapper.height,
                            left: leftPosition,
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                        },
                    },
                };

                switch (position) {
                    case "left-top":
                        if (
                            topPosition +
                                positionRef.current.tooltip.height +
                                200 >
                            dimensions.height
                        )
                            return positions.left.bottom;
                        return positions.left.top;
                    case "bottom-left": {
                        if (
                            leftPosition +
                                (typeof minWidth === "number"
                                    ? minWidth
                                    : 290) >
                            dimensions.width
                        )
                            return positions.bottom.left;

                        return {
                            top:
                                positionRef.current.wrapper.top +
                                positionRef.current.wrapper.height,

                            left: leftPosition,
                        };
                    }

                    case "top-center": {
                        return positions.top.center;
                    }
                    case "bottom-center": {
                        return positions.bottom.center;
                    }
                    case "top-right":
                        return positions.top.right;
                    case "top-left":
                        return positions.top.left;
                    case "right-top": {
                        if (
                            topPosition +
                                positionRef.current.tooltip.height +
                                204 >
                            dimensions.height
                        )
                            return {
                                ...positions.right.top,
                                top: undefined,
                                bottom:
                                    positionRef.current.tooltip.height + 204,
                            };
                        return positions.right.top;
                    }

                    case "bottom-right":
                        return positions.bottom.right;
                    default:
                        return positions.top.left;
                }
            }
            return {
                top: 0,
                left: 0,
            };
        }, [
            customPosition,
            isHovered,
            position,
            dimensions.height,
            dimensions.width,
            minWidth,
        ]);

        const scaleAnimation = useAnimatedStyle(() => {
            return {
                // transform: [
                //     {
                //         scale: withTiming(isHovered ? 1 : 0.98, {
                //             duration: 200,
                //             easing: Easing.bezier(0.25, 0.1, 0.25, 1),
                //         }),
                //     },
                // ],
            };
        }, [isHovered]);

        return (
            <Box
                ref={wrapperRef}
                onMouseEnter={onHover}
                onMouseLeave={onUnhover}
                // @ts-ignore
                style={[
                    {
                        position: "relative",
                        zIndex: isHovered ? 11 : 1,
                    },
                    style,
                ]}
            >
                {isHovered ? (
                    <Portal>
                        <Box
                            ref={tooltipRef}
                            // @ts-ignore
                            style={[
                                {
                                    zIndex: 0,
                                    shadowColor: theme.color.rgba(
                                        theme.color.black,
                                        0.5
                                    ),
                                    shadowOpacity: 1,
                                    shadowRadius: 10,
                                    position: "absolute",
                                    pointerEvents: isHovered
                                        ? pointerEvents
                                            ? pointerEvents
                                            : "auto"
                                        : "none",
                                    ...tooltipPosition,
                                },
                            ]}
                        >
                            <Animated.View
                                style={[
                                    scaleAnimation,
                                    {
                                        position: "absolute",
                                        borderRadius: theme.radius.medium,
                                        maxWidth: SIDEBAR_WIDTH,
                                        minWidth:
                                            typeof minWidth === "boolean"
                                                ? 290
                                                : minWidth || "auto",
                                        overflow: "hidden",
                                        marginTop: top,
                                        marginLeft: left,
                                        marginRight: right,
                                        marginBottom: bottom,
                                        // transform: [
                                        //     { scale: scaleAnimation.value },
                                        // ],
                                        // opacity: opacityAnimation.value,

                                        ...tooltipStyle,
                                    },
                                ]}
                            >
                                <BlurView
                                    style={{
                                        flex: 1,
                                    }}
                                >
                                    {renderContent && (
                                        <Box
                                            style={{
                                                backgroundColor:
                                                    theme.color.rgba(
                                                        theme.color.white,
                                                        0.1
                                                    ),
                                                borderColor: theme.color.rgba(
                                                    theme.color.white,
                                                    0.1
                                                ),
                                                borderWidth: 1,
                                                borderRadius:
                                                    theme.radius.medium,
                                                paddingHorizontal: tiny
                                                    ? theme.spacing.small
                                                    : theme.spacing.big,
                                                paddingVertical: tiny
                                                    ? theme.spacing.small
                                                    : theme.spacing.big,
                                                ...tooltipStyle,
                                            }}
                                        >
                                            {title ? (
                                                <>
                                                    <Text
                                                        smallest
                                                        uppercase
                                                        bold
                                                        style={{
                                                            whiteSpace:
                                                                "nowrap",
                                                            letterSpacing: 0.1,
                                                        }}
                                                        {...titleProps}
                                                    >
                                                        {title}
                                                    </Text>
                                                </>
                                            ) : null}

                                            {content ? (
                                                <>
                                                    {title ? (
                                                        <Spacer small />
                                                    ) : null}

                                                    <Text
                                                        smaller
                                                        lineHeight={
                                                            theme.text.medium
                                                        }
                                                        color={theme.color.rgba(
                                                            theme.color.white,
                                                            0.6
                                                        )}
                                                        {...contentProps}
                                                    >
                                                        {content}
                                                    </Text>
                                                    {renderContent ? (
                                                        <Spacer />
                                                    ) : null}
                                                </>
                                            ) : null}

                                            {renderContent}
                                        </Box>
                                    )}
                                </BlurView>
                            </Animated.View>
                        </Box>
                    </Portal>
                ) : null}

                {isActive ? (
                    <Box
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            zIndex: 10,
                        }}
                    />
                ) : null}
                {children}
            </Box>
        );
    }
);
