import { Box } from "@atoms/Flex";
import { memo } from "react";
// @ts-ignore
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import { useTheme } from "styled-components/native";

export const Avatar = memo(
    ({ string, size = 24 }: { string: any; size?: number }) => {
        const theme = useTheme();
        return (
            <Box
                style={{
                    borderRadius: theme.spacing.huge,
                    overflow: "hidden",
                    width: size,
                    height: size,
                    backgroundColor: theme.color.rgba(theme.color.white, 0.1),
                }}
            >
                {string ? (
                    <Jazzicon
                        diameter={size}
                        seed={jsNumberForAddress(string)}
                    />
                ) : null}
            </Box>
        );
    }
);
