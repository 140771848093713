export enum LanguageCode {
    EN = "EN",
}

const English = {
    app: {},
    screens: {
        trade: {
            disabled: {
                title: "Trading is disabled",
                description: "Trading is disabled for this pair",
            },
        },
    },
};
export type LanguageType = typeof English;

export const Languages = new Map([[LanguageCode.EN, English]]);

export function useLanguage() {
    return {
        language: Languages.get(LanguageCode.EN),
    };
}
