import { BoxContainer } from "@atoms/BoxContainer";
import { Box, Flex } from "@atoms/Flex";
import { HeadTitle } from "@atoms/HeadTitle";
import { SIDEBAR_WIDTH } from "@constants";
import { useAppContext } from "@contexts/app";
import { PriceProvider, usePriceContext } from "@contexts/price";
import { useMedia } from "@hooks/useMedia";
import { COLUMN_HEADER_HEIGHT } from "@organisms/List/Column";
import { TradeFooter } from "@screens/components/Footer";
import { formatPrice } from "@utils";
import dynamic from "next/dynamic";
import { ITradeScreen } from "pages/invest";
import { Suspense, memo, useEffect, useRef, useState } from "react";
import {
    ImperativePanelHandle,
    MixedSizes,
    Panel,
    PanelGroup,
    PanelResizeHandle,
} from "react-resizable-panels";
import { useTheme } from "styled-components/native";
import { useAccount } from "wagmi";
import { GetStarted } from "./components/GetStarted";
import { TradeHeader, TradeHeaderMobile } from "./components/Header";
import { LIST_ITEM_HEIGHT } from "./components/List/utils";
import { TradeProvider, useTradeContext } from "./context";
import { AVAILABLE_CHAINS, useWeb3Context } from "@contexts/web3";
import { Text } from "@atoms/Text";
import { Button } from "@molecules/Button";
import { AccessCodeModal } from "@screens/Authentication/AccessCode";

const TradeList = dynamic(() => import("./components/List"), {
    ssr: true,
});
const PairStatistics = dynamic(
    () => import("./components/Header/PairStatistics"),
    {
        ssr: true,
    }
);
const RecentTrades = dynamic(() => import("./components/RecentTrades"), {
    ssr: true,
});
const TradingViewWidget = dynamic(() => import("./components/TradingView"), {
    ssr: false,
});
const TradeForm = dynamic(() => import("./components/Form"), {
    ssr: true,
});

export const TRADE_HEADER_HEIGHT = 60;

export const TradeScreen = (props: ITradeScreen) => {
    const media = useMedia();
    const theme = useTheme();
    const { currentChain, switchChain } = useWeb3Context();
    const { address, isConnecting, isDisconnected, isConnected } = useAccount();
    const {
        initial: { pairs },
        userFE,
        userFELoading,
    } = useAppContext();

    if (!pairs.length) return null;

    // if (currentChain.type === "mainnet") {
    //     return (
    //         <Box
    //             flex={1}
    //             gap={theme.spacing.huge}
    //             justify="center"
    //             align="center"
    //         >
    //             <Box align="center" gap={theme.spacing.bigger}>
    //                 <Text huge bold>
    //                     Switch to testnet
    //                 </Text>
    //                 <Text>
    //                     Please switch to the testnet to use the trading
    //                     platform.
    //                 </Text>
    //             </Box>
    //             <Button
    //                 onPress={() =>
    //                     switchChain(AVAILABLE_CHAINS.arbitrumSepolia.id)
    //                 }
    //                 primary
    //                 red
    //                 text="Switch To Testnet"
    //             />
    //         </Box>
    //     );
    // }
    return (
        <TradeProvider>
            {/* Remove access codes */}
            {/* {(!(userFE && userFE.accessCode && userFE.accessCode.length) &&
                !userFELoading) ||
            isDisconnected ? (
                <AccessCodeModal />
            ) : (
                <Box></Box>
            )} */}

            <TradeHead />
            {media.isMobile ? <TradeScreenMobile /> : <TradeScreenDesktop />}
        </TradeProvider>
    );
};

const TradeHead = memo(() => {
    const { pair } = useTradeContext();

    return (
        <PriceProvider pair={pair}>
            <TradeScreenTitle />
        </PriceProvider>
    );
});

const TradeScreenTitle = memo(() => {
    const { price } = usePriceContext();
    const { pair } = useTradeContext();

    return (
        <HeadTitle
            text={
                pair
                    ? `${
                          price?.mid
                              ? `${formatPrice(price?.mid, {
                                    significant: 6,
                                    currency: false,
                                })} | `
                              : ""
                      }${pair.from}/${pair.to} | Ostium`
                    : "Trade | Ostium"
            }
        />
    );
});

const TradeScreenDesktop = memo(() => {
    const theme = useTheme();
    const { settings } = useAppContext();

    return (
        <Box
            flex={1}
            style={{
                paddingHorizontal: settings?.theme?.boxed
                    ? theme.spacing.smaller
                    : 0,
            }}
        >
            <Box
                flex={1}
                gap={settings?.theme?.boxed ? theme.spacing.smaller : 0}
            >
                <TradeHeader />
                <Content />
            </Box>

            <TradeFooter />
        </Box>
    );
});

const TradeScreenMobile = memo(() => {
    const theme = useTheme();
    const { settings } = useAppContext();

    const wrapperStyle = settings?.theme?.boxed
        ? {
              paddingHorizontal: theme.spacing.smaller,
              gap: theme.spacing.smaller,
          }
        : {};

    return (
        <Box style={wrapperStyle}>
            <Box
                style={{
                    height: TRADE_HEADER_HEIGHT,
                }}
            >
                <TradeHeaderMobile />
            </Box>

            <ContentMobile />
        </Box>
    );
});

const Content = memo(() => {
    const theme = useTheme();
    const { settings } = useAppContext();

    return (
        <Flex
            style={{
                position: "relative",
                zIndex: 10,
                paddingTop: 0,
                flex: 1,
                overflow: "hidden",
            }}
        >
            <Flex
                style={{
                    flex: 1,
                    flexDirection: settings?.trade?.panelLeft
                        ? "row-reverse"
                        : "row",
                }}
                gap={settings?.theme?.boxed ? theme.spacing.smaller : 0}
            >
                <Box flex={1}>
                    <PanelVertical />
                </Box>

                <Box
                    style={{
                        height: "100%",
                        width: SIDEBAR_WIDTH,
                    }}
                >
                    <BoxContainer
                        style={{
                            height: "100%",
                            backgroundColor: theme.color.rgba(
                                theme.color.white,
                                0.05
                            ),
                            borderWidth: 0,
                        }}
                    >
                        <TradeForm />
                    </BoxContainer>
                </Box>
            </Flex>
        </Flex>
    );
});

const ContentMobile = memo(() => {
    const theme = useTheme();

    const { pair } = useTradeContext();
    const { address } = useAccount();

    const { settings, pairs } = useAppContext();

    return (
        <Box gap={settings?.theme?.boxed ? theme.spacing.smaller : 0}>
            <Box
                style={{
                    height: 360,
                    // marginTop: settings.trade.boxed ? -theme.spacing.tiny : 0,
                    // marginBottom: settings.trade.boxed
                    //     ? theme.spacing.small
                    //     : 0,
                    // marginHorizontal: settings.trade.boxed
                    //     ? -theme.spacing.small
                    //     : 0,
                }}
            >
                <Suspense>
                    <TradingViewWidget />
                </Suspense>
            </Box>
            <BoxContainer
                style={{
                    borderWidth: 0,
                }}
            >
                <PriceProvider pair={pair}>
                    <PairStatistics pair={pair} />
                </PriceProvider>
            </BoxContainer>
            <Box
                style={{
                    gap: settings?.theme?.boxed ? theme.spacing.big : 0,
                }}
            >
                <BoxContainer
                    style={{
                        backgroundColor: theme.color.rgba(
                            theme.color.white,
                            0.05
                        ),
                    }}
                >
                    <TradeForm />
                </BoxContainer>
                <BoxContainer>
                    <TradeList
                        address={address}
                        storageKey={"TRADE"}
                        pair={pair}
                        pairs={pairs}
                        hasLogin
                    />
                </BoxContainer>
            </Box>
            <TradeFooter />
        </Box>
    );
});

export const COLLAPSED_HEIGHT = 52;
const EXPANDED_HEIGHT =
    LIST_ITEM_HEIGHT * 4 + COLLAPSED_HEIGHT + COLUMN_HEADER_HEIGHT - 5;
const DEFAULT_HEIGHT =
    LIST_ITEM_HEIGHT * 3 + COLLAPSED_HEIGHT + COLUMN_HEADER_HEIGHT - 5;

const PanelVertical = () => {
    const theme = useTheme();
    const { settings, pairs } = useAppContext();
    const tradeListPanelRef = useRef<ImperativePanelHandle>(null);
    const { pair } = useTradeContext();
    const { address } = useAccount();

    const [isCollapsed, setIsCollapsed] = useState(false);

    const onResize = ({ sizePixels }: MixedSizes) => {
        if (Math.trunc(sizePixels) > COLLAPSED_HEIGHT) {
            if (isCollapsed) setIsCollapsed(false);
            return;
        }
        if (!isCollapsed) setIsCollapsed(true);
    };

    const toggleCollapsed = () => {
        if (isCollapsed) {
            setIsCollapsed(false);
            tradeListPanelRef?.current?.resize({
                sizePixels: EXPANDED_HEIGHT,
            });
        } else {
            setIsCollapsed(true);
            tradeListPanelRef?.current?.resize({
                sizePixels: COLLAPSED_HEIGHT,
            });
        }
    };

    return (
        <PanelGroup
            autoSaveId="PANEL_GROUP"
            direction="vertical"
            keyboardResizeByPixels={1}
            style={{ height: "100%" }}
        >
            <Panel minSizePixels={200}>
                <Flex
                    style={{
                        height: "100%",
                    }}
                    flex={1}
                    gap={theme.spacing.smaller}
                >
                    <Box
                        style={{
                            flex: 1,
                            marginBottom: settings.theme.boxed
                                ? -theme.spacing.smallest
                                : 0,
                        }}
                    >
                        <Suspense>
                            <TradingViewWidget />
                        </Suspense>
                    </Box>
                    {settings?.trade?.showHistory ? (
                        <BoxContainer
                            style={{
                                borderLeftWidth: settings.theme.boxed ? 0 : 1,
                                borderColor: theme.color.rgba(
                                    theme.color.white,
                                    0.1
                                ),
                            }}
                        >
                            <RecentTrades />
                        </BoxContainer>
                    ) : null}
                </Flex>
            </Panel>
            <PanelResizeHandle
                style={{
                    zIndex: 10,
                }}
            >
                <PanelResize hideLine={settings.theme.boxed} />
            </PanelResizeHandle>
            <Panel
                ref={tradeListPanelRef}
                defaultSizePixels={DEFAULT_HEIGHT}
                minSizePixels={COLLAPSED_HEIGHT}
                maxSizePixels={EXPANDED_HEIGHT}
                collapsedSizePixels={COLLAPSED_HEIGHT}
                onResize={onResize}
                collapsible
            >
                <BoxContainer
                    style={{
                        height: "100%",
                        borderWidth: 0,
                    }}
                >
                    <TradeList
                        storageKey={"TRADE"}
                        address={address}
                        pairs={pairs}
                        pair={pair}
                        isCollapsed={isCollapsed}
                        toggleCollapsed={toggleCollapsed}
                        hasLogin
                    />
                </BoxContainer>
            </Panel>
        </PanelGroup>
    );
};

const PanelResize = ({
    vertical,
    hideLine,
    style,
}: {
    vertical?: boolean;
    hideLine?: boolean;
    style?: any;
}) => {
    const theme = useTheme();
    const spacing = 8;

    const [isHovered, setIsHovered] = useState(false);

    const size = hideLine ? (isHovered ? 2 : 0) : isHovered ? 4 : 2;

    return (
        <Box
            style={
                hideLine
                    ? {
                          marginTop: theme.spacing.tiny,
                          marginBottom: theme.spacing.tiny,
                          paddingLeft: theme.spacing.small,
                          paddingRight: theme.spacing.small,
                      }
                    : null
            }
        >
            <Box
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={[
                    {
                        justifyContent: "center",
                        alignItems: "center",
                        height: vertical ? "100%" : 4,
                        width: vertical ? 4 : "100%",
                        paddingVertical: vertical ? 0 : spacing,
                        paddingHorizontal: vertical ? spacing : 0,
                        marginHorizontal: vertical ? -spacing : 0,
                        marginVertical: vertical ? 0 : -spacing,
                        zIndex: 10,
                    },
                ]}
            >
                <Box
                    style={{
                        backgroundColor: theme.color.rgba(
                            theme.color.white,
                            isHovered ? 0.2 : 0.1
                        ),
                        width: vertical ? size : "100%",
                        height: vertical ? "100%" : size,
                    }}
                />
            </Box>
        </Box>
    );
};
