import { Icon } from "@atoms/Icon";
import { Text } from "@atoms/Text";
import { useMedia } from "@hooks/useMedia";
import { ReactNode, memo, useCallback, useEffect } from "react";
import { Pressable, ViewStyle } from "react-native";
import Animated, {
    Easing,
    interpolateColor,
    useAnimatedStyle,
    useDerivedValue,
    withTiming,
} from "react-native-reanimated";
import { Portal } from "@atoms/Portal";
import { useTheme } from "styled-components/native";
import { BlurView } from "@atoms/BlurView";
import { Box, Flex } from "../../atoms/Flex";

type Props = {
    isVisible: boolean;
    title?: string;
    description?: string;
    contentStyle?: ViewStyle;
    style?: ViewStyle;
    children?: ReactNode | null;
    renderTitle?: ReactNode;
    renderTop?: ReactNode;
    renderSidebar?: ReactNode;
    onClose?: (isVisible: boolean) => void;
    strict?: boolean; // If true, the modal will not close on overlay click
};

const Modal = memo(
    ({
        isVisible,
        title,
        renderTitle,
        contentStyle,
        children,
        renderTop,
        renderSidebar,
        style,
        strict, // If true, the modal will not close on overlay click
        onClose,
    }: Props) => {
        const theme = useTheme();
        const { isMobile } = useMedia();

        const backgroundAnimation = useDerivedValue(() => {
            return withTiming(isVisible ? 1 : 0, {
                duration: 200,
            });
        }, [isVisible]);

        const overlayStyle = useAnimatedStyle(() => {
            return {
                backgroundColor: interpolateColor(
                    backgroundAnimation.value,
                    [0, 1],
                    [
                        theme.color.rgba(theme.color.black, 0),
                        theme.color.rgba(theme.color.black, strict ? 0.8 : 0.6),
                    ]
                ),
                zIndex: strict ? 9999 : undefined,
                pointerEvents: strict ? "none" : undefined, // Prevent interaction with the overlay
            };
        }, [backgroundAnimation, theme.color.black]);

        const wrapperStyle = useAnimatedStyle(() => {
            const config = {
                duration: 200,
            };

            return {
                transform: [
                    {
                        scale: withTiming(isVisible ? 1 : 0.95, config),
                    },
                ],
                zIndex: strict ? 10000 : undefined,
            };
        }, [isVisible]);

        const onClosePress = useCallback(() => {
            onClose?.(!isVisible);
        }, [isVisible, onClose]);

        const onKeyboardEvent = useCallback(
            (e: KeyboardEvent) => {
                if (e.key === "Escape") {
                    //Do whatever when esc is pressed
                    onClosePress();
                }
            },
            [onClosePress]
        );

        useEffect(() => {
            if (isVisible) {
                document.addEventListener("keydown", onKeyboardEvent, false);
            }
            return () => {
                document.removeEventListener("keydown", onKeyboardEvent, false);
            };
        }, [isVisible, onKeyboardEvent]);

        if (!isVisible) return null;

        return (
            <Portal>
                <Box
                    align="center"
                    justify="center"
                    // @ts-ignore
                    style={{
                        position: "fixed",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        pointerEvents: strict ? "auto" : undefined,
                        zIndex: strict ? 10000 : undefined,
                    }}
                >
                    <Pressable
                        onPress={onClosePress}
                        disabled={!onClose}
                        style={{
                            position: "absolute",
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0,
                            // @ts-ignore
                            backdropFilter: strict ? "blur(4px)" : undefined,
                            zIndex: strict ? 10001 : undefined,
                        }}
                    >
                        <Animated.View
                            style={[
                                {
                                    position: "absolute",
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                },
                                overlayStyle,
                            ]}
                        />
                    </Pressable>

                    <Box
                        justify="center"
                        style={{
                            width: "100%",
                            maxWidth: 440,
                            height: isMobile ? "100%" : "auto",
                            opacity: 1,
                            zIndex: strict ? 10002 : undefined,
                            ...style,
                        }}
                    >
                        <Animated.View
                            style={[
                                {
                                    overflow: "hidden",
                                    transform: [
                                        {
                                            scale: 1,
                                        },
                                    ],
                                    borderWidth: 2,
                                    borderRadius: theme.radius.big,
                                    borderColor: theme.color.rgba(
                                        theme.color.white,
                                        0.1
                                    ),
                                    backgroundColor: theme.color.rgba(
                                        theme.color.black,
                                        0.7
                                    ),
                                    zIndex: strict ? 10003 : undefined,
                                },
                                wrapperStyle,
                            ]}
                        >
                            <BlurView intensity={24}>
                                <Flex>
                                    <Box>{renderSidebar}</Box>
                                    <Box
                                        style={{
                                            backgroundColor: theme.color.rgba(
                                                theme.color.white,
                                                0.05
                                            ),
                                        }}
                                        flex={1}
                                    >
                                        <Flex
                                            justify="space-between"
                                            // style={{
                                            //     backgroundColor: rgba(
                                            //         theme.color.white,
                                            //         0.05
                                            //     ),
                                            // }}
                                        >
                                            <Box
                                                justify="center"
                                                gap={theme.spacing.smallest}
                                                flex={1}
                                                style={{
                                                    paddingLeft:
                                                        theme.spacing.bigger,
                                                    minHeight: 48,
                                                }}
                                            >
                                                {renderTitle || (
                                                    <Text white bold bigger>
                                                        {title}
                                                    </Text>
                                                )}
                                            </Box>

                                            {onClose ? (
                                                <Pressable
                                                    onPress={onClosePress}
                                                    style={{
                                                        width: 48,
                                                        height: 48,
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Box>
                                                        <Icon
                                                            name="close"
                                                            size={12}
                                                        />
                                                    </Box>
                                                </Pressable>
                                            ) : null}
                                        </Flex>
                                        {renderTop}

                                        <Box
                                            justify="space-between"
                                            style={[contentStyle]}
                                        >
                                            {children}
                                        </Box>
                                    </Box>
                                </Flex>
                            </BlurView>
                        </Animated.View>
                    </Box>
                </Box>
            </Portal>
        );
    }
);

export default Modal;
