import Head from "next/head";
import { ReactNode } from "react";
import { useTheme } from "styled-components/native";

export const Platform = ({ children }: { children: ReactNode }) => {
    const theme = useTheme();

    return (
        <>
            <Head>
                <meta
                    name="msapplication-TileColor"
                    content={theme.color.black}
                />
                <meta name="theme-color" content={theme.color.black} />
            </Head>
            {children}
        </>
    );
};
