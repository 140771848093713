import { Box } from "@atoms/Flex";
import { Portal, PortalProvider } from "@gorhom/portal";
import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
import { useTheme } from "styled-components/native";
import { IStep, Step } from ".";

const TutorialContext = createContext(
    {} as {
        isActive: boolean;
        currentStepIndex: number;
        steps: Map<number, IStep>;
        currentStep?: IStep;
        addStep: (step: IStep) => void;
        removeStep: (index: number) => void;
        goToStep: (index: number) => void;
        toggleActive: () => void;
        onNext: () => void;
        onPrevious: () => void;
        onCloseStep: () => void;
    }
);

const useTutorial = () => {
    const [steps, setSteps] = useState<Map<number, IStep>>(new Map());
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const [isActive, setIsActive] = useState(false);

    const currentStep = useMemo(() => {
        if (steps.has(currentStepIndex)) return steps.get(currentStepIndex);
    }, [steps, currentStepIndex]);

    const toggleActive = useCallback(() => {
        setIsActive(!isActive);
    }, [isActive]);

    const addStep = useCallback((step: IStep) => {
        setSteps((steps) => {
            const newSteps = new Map(steps);
            if ([...newSteps.values()].some((item) => item.id === step.id))
                return steps;

            newSteps.set(steps.size + 1, step);
            return newSteps;
        });
    }, []);

    const removeStep = useCallback(
        (index: number) => {
            const newSteps = new Map(steps);
            newSteps.delete(index);
            setSteps(newSteps);
        },
        [steps]
    );

    const goToStep = useCallback((index: number) => {
        setCurrentStepIndex(index);
    }, []);

    const onCloseStep = useCallback(() => {
        setCurrentStepIndex(-1);
    }, []);

    const onNext = useCallback(() => {
        setCurrentStepIndex((step) => step + 1);
    }, []);

    const onPrevious = useCallback(() => {
        setCurrentStepIndex((step) => step - 1);
    }, []);

    return {
        steps,
        currentStepIndex,
        isActive,
        currentStep,
        addStep,
        removeStep,
        onNext,
        onPrevious,
        goToStep,
        toggleActive,
        onCloseStep,
    };
};

const TutorialProvider = ({ children }: { children: ReactNode }) => {
    const value = useTutorial();
    const theme = useTheme();

    return (
        <TutorialContext.Provider value={value}>
            <PortalProvider>
                {value?.isActive ? (
                    <Portal>
                        <Box
                            style={{
                                zIndex: 1001,
                            }}
                        >
                            {[...value.steps.values()].map((item, index) => {
                                return (
                                    <Step
                                        key={`step-${index}`}
                                        index={index}
                                        step={item}
                                    />
                                );
                            })}
                        </Box>

                        <Box
                            style={{
                                background: theme.color.rgba(
                                    theme.color.black,
                                    0.4
                                ),
                                position: "fixed",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 1000,
                            }}
                        />
                    </Portal>
                ) : null}
            </PortalProvider>
            {children}
        </TutorialContext.Provider>
    );
};

const useTutorialContext = () => useContext(TutorialContext);

export { TutorialProvider, useTutorialContext };
