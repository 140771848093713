import { gql } from "@apollo/client/core";
import { useQuery } from "@apollo/client/react/hooks";
import { BlurView } from "@atoms/BlurView";
import { Box } from "@atoms/Flex";
import { HeadTitle } from "@atoms/HeadTitle";
import { Text } from "@atoms/Text";
import { useWeb3Context } from "@contexts/web3";

import { useMedia } from "@hooks/useMedia";
import { Button } from "@molecules/Button";
import { PairItem } from "@organisms/List/Item";

import { SplineBackground } from "@screens/Trade/Preview/SplineBackground";
import { CTA_HEIGHT, HEADER_HEIGHT } from "@screens/components/Header";
import { getPairName } from "@utils";
import { useRouting } from "expo-next-react-navigation";
import { Pair } from "gql/graphql";
import dynamic from "next/dynamic";
import { useCallback, useMemo } from "react";
import { FlatList, ListRenderItem } from "react-native";
import { useTheme } from "styled-components/native";
import { useAccount } from "wagmi";

const Header = dynamic(() => import("@screens/components/Header"));

export const WaitlistLayout = ({ children }: { children: JSX.Element }) => {
    const { navigate } = useRouting();
    const theme = useTheme();
    const media = useMedia();

    const { address } = useAccount();

    const openTrade = useCallback(() => {
        navigate({
            routeName: "Trade",
            web: {
                path: "/",
            },
        });
    }, [navigate]);

    const renderContent = useMemo(() => {
        return (
            <Box
                style={{
                    paddingTop: media.isMobile
                        ? HEADER_HEIGHT * 2 + CTA_HEIGHT + theme.spacing.biggest
                        : HEADER_HEIGHT + CTA_HEIGHT,
                    flex: 1,
                }}
            >
                {address ? (
                    <Box
                        style={{
                            position: "fixed",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            zIndex: 10,
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <BlurView
                            intensity={15}
                            style={{
                                padding: theme.spacing.biggest,
                                // maxWidth: 400,
                                // width: "100%",
                                borderRadius: theme.radius.big,
                            }}
                        >
                            <Box gap={theme.spacing.huge} align="center">
                                <Box gap={theme.spacing.bigger} align="center">
                                    <Text bold bigger>
                                        {`You've been whitelisted!`}
                                    </Text>
                                </Box>

                                <Box>
                                    <Button
                                        primary
                                        text="Start Trading"
                                        onPress={openTrade}
                                    />
                                </Box>
                            </Box>
                        </BlurView>
                    </Box>
                ) : null}

                <Box
                    direction={media.isMobile ? "column" : "row"}
                    style={{
                        flex: 1,
                    }}
                >
                    {children}
                </Box>
            </Box>
        );
    }, [
        address,
        children,
        media.isMobile,
        openTrade,
        theme.radius.big,
        theme.spacing.bigger,
        theme.spacing.biggest,
        theme.spacing.huge,
    ]);

    return (
        <>
            <HeadTitle text="Waitlist | Ostium" />
            <Header simple />
            {!media.isMobile ? (
                <Box
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        pointerEvents: "none",
                        overflow: "hidden",
                    }}
                    justify="center"
                    align="center"
                >
                    <Box>
                        <SplineBackground delay={2000} />
                    </Box>
                </Box>
            ) : null}

            {renderContent}
        </>
    );
};

const MarketsMini = () => {
    const { data } = useQuery(GET_PAIRS, {
        variables: {
            first: 1000,
        },
    });
    const pairs: Pair[] = useMemo(() => {
        return data?.pairs || [];
    }, [data?.pairs]);

    const theme = useTheme();

    const renderItem: ListRenderItem<Pair> = useMemo(() => {
        return ({ item, index }) => {
            return (
                <Box
                    key={`${item.feed}`}
                    style={[
                        {
                            flex: 1,
                        },
                    ]}
                >
                    <Box
                        style={{
                            padding: theme.spacing.smaller,
                            borderRadius: theme.radius.big,
                            backgroundColor: theme.color.rgba(
                                theme.color.white,
                                0.1
                            ),
                        }}
                    >
                        <PairItem
                            pair={item}
                            value={getPairName(item.from, item.to)}
                        />
                    </Box>
                </Box>
            );
        };
    }, [theme.color, theme.radius.big, theme.spacing.smaller]);

    return (
        <Box
            style={{
                width: "100%",
            }}
        >
            <FlatList
                data={pairs}
                contentContainerStyle={{
                    rowGap: theme.spacing.smaller,
                    justifyContent: "space-around",
                }}
                renderItem={renderItem}
                numColumns={2}
            />
        </Box>
    );
};

const GET_PAIRS = gql`
    {
        pairs(first: 1000) {
            id
            from
            to
            feed
            spreadP
            volume
            accRollover
            accFundingLong
            accFundingShort
            curRollover
            curFundingLong
            curFundingShort
            makerMaxLeverage
            utilizationThresholdP
            lastFundingRate
            lastFundingVelocity
            maxFundingFeePerBlock
            lastFundingBlock
            lastFundingTime
            longOI
            shortOI
            maxOI
            rolloverFeePerBlock
            lastRolloverBlock
            maxLeverage
            tradeSizeRef
            group {
                id
                name
                maxLeverage
                minLeverage
                maxCollateralP
                longCollateral
                shortCollateral
            }
            makerFeeP
            takerFeeP
            usageFeeP
            fee {
                id
                minLevPos
                liqFeeP
                oracleFee
            }
        }
    }
`;
