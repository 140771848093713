import { ViewStyle } from "react-native";
import { Box } from "./Flex";
import { memo } from "react";

export const BlurView = memo(
    ({
        children,
        intensity = 24,
        ...props
    }: {
        children: JSX.Element;
        intensity: number;
        style: ViewStyle;
    }) => {
        return (
            <Box
                {...props}
                style={{
                    ...props.style,
                    backdropFilter: `blur(${intensity}px)`,
                    WebkitBackdropFilter: `blur(${intensity}px)`,
                }}
            >
                {children}
            </Box>
        );
    }
);
